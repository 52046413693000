(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('userController', userController);

  userController.$inject = [
    '$log',
    '$scope',
    'Restangular',
    '$state',
    'userFactory',
    'UserData',
    '$rootScope',
    '$confirm',
  ];

  function userController($log, $scope, Restangular, $state, userFactory, UserData, $rootScope, $confirm) {
    var vm = this;
    vm.user;
    vm.devicesArray;
    vm.deleteUser;
    vm.changedLevel;
    vm.showPass;
    vm.regEmail =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    vm.regLogin = /^[a-zA-Z0-9]*$/;
    vm.regName = /^[a-zA-Z0-9ñÑ\-_,. \s]*$/;

    vm.unitsType = [
      { id: 2, name: 'A-4000' },
      { id: 3, name: 'A-2500' },
      { id: 4, name: 'A-BIT' },
      { id: 5, name: 'A-7000' },
      { id: 6, name: 'A-5500' },
    ];

    activate();

    function activate() {
      vm.deleteUser = deleteUser;
      vm.updateUser = updateUser;
      vm.checkMasterKey = checkMasterKey;
      vm.master = null;
      vm.subusers = null;
      vm.linkUserAndMaster = linkUserAndMaster;
      vm.unlinkUserAndMaster = unlinkUserAndMaster;
      vm.unlinkUser = unlinkUser;
      vm.returnType = returnType;
      vm.changedLevel = changedLevel;
      vm.updatedQuadern = updatedQuadern;
      vm.showUserUnits = showUserUnits;
      vm.getConfigDevice = getConfigDevice;
      vm.sendConfigDevice = sendConfigDevice;

      vm.devicesArray = null;
      vm.regPass = /^[a-zA-Z0-9\-_!.?@:]*$/;
      if ($state.params.user) {
        vm.user = $state.params.user;
        vm.showPass = false;
        loadUserDevices();
        if (vm.user.master) {
          loadMasterUsers();
        }
        vm.quadern = _.includes(UserData.authorities, 'CCUSER');
        //if(vm.user.role === 'SUBUSER'){
        if (vm.user.authorities) {
          vm.user.quadern = _.includes(vm.user.authorities, 'CCUSER');
        }
        //}
      } else {
        $state.go('manager');
      }
    }
    function updatedQuadern() {
      if (vm.user.authorities === undefined) {
        vm.user.authorities = [];
      }
      if (vm.user.quadern) {
        if (!_.includes(vm.user.authorities, 'CCUSER')) {
          vm.user.authorities.push('CCUSER');
          vm.user.ccLvl = 1;
        }
      } else {
        if (_.includes(vm.user.authorities, 'CCUSER')) {
          _.remove(vm.user.authorities, function (n) {
            return n == 'CCUSER';
          });
        }
      }
    }
    function loadUserDevices() {
      userFactory.units(vm.user.id).then(function (response) {
        vm.devicesArray = response.plain();
        if (vm.user.role !== 'SUBUSER') {
          $rootScope.rootDevices = {};
          $rootScope.rootDevices = angular.copy(vm.devicesArray);
        }
        _.forEach($rootScope.rootDevices, function (obj) {
          obj.device.lvl = null;
        });
        _.forEach(vm.devicesArray, function (obj) {
          var id = Number(obj.device.id);
          if (vm.user.devices === undefined || vm.user.devices === null) {
            obj.device.lvl = obj.level;
          } else if (vm.user.devices[id] !== undefined) {
            obj.device.lvl = vm.user.devices[id];
          }
        });
        if (vm.user.role === 'SUBUSER') {
          let temp_array = _.xorBy(vm.devicesArray, $rootScope.rootDevices, 'device.id');
          vm.devicesArray = _.orderBy(_.union(vm.devicesArray, temp_array), 'device.id');
        }
      });
    }

    function deleteUser() {
      Restangular.one('users')
        .customDELETE(vm.user.id)
        .then(function (response) {
          $scope.$emit('refresh-users', { load: true });
        });
    }

    function checkMasterKey() {
      userFactory.checkMaster(vm.masterKey).then((m) => {
        if (m !== undefined) {
          vm.master = m.plain();
        } else {
          vm.masterError = true;
          vm.master = null;
        }
      });
    }

    function changedLevel(form) {
      if (form.level !== undefined) {
        form.level.$dirty = true;
      } else form.$dirty = true;
    }

    function updateUser(form) {
      var req = {
        id: vm.user.id,
        password: vm.user.password,
        name: vm.user.name,
        email: vm.user.email,
        authorities: vm.user.authorities,
        devices: {},
      };
      if ((form.level !== undefined && form.level.$dirty) || form.$dirty) {
        var devices = _.filter(_.map(vm.devicesArray, 'device'), 'lvl');
        if (devices.length > 0 && form.$valid) {
          _.forEach(devices, function (obj) {
            req.devices[obj.id] = obj.lvl;
          });
          Restangular.one('users', vm.user.id)
            .one('units')
            .customPUT(req)
            .then(function (response) {
              $rootScope.toast({
                type: 'success',
                title: 'Guardado correcto',
              });
            });
        } else {
        }
      } else {
        Restangular.one('users', vm.user.id)
          .customPUT(req)
          .then(function (response) {
            $rootScope.toast({
              type: 'success',
              title: 'Guardado correcto',
            });
          });
      }
    }

    function linkUserAndMaster() {
      userFactory.link(vm.user.id, vm.master.id).then((result) => {
        if (result) {
          $rootScope.toast({
            type: 'success',
            title: 'OK',
          });
          vm.finalization = true;
        } else {
          vm.finalization = false;
        }
      });
    }

    function showUserUnits(user) {
      if (!user.remark) {
        user.remark = true;
        _.forEach(user.devices, (o, k) => {
          let tmp = _.find(vm.devicesArray, (d) => {
            return Number(k) === d.device.id;
          });
          if (tmp !== undefined) tmp.remark = true;
        });
      } else {
        user.remark = false;
        _.forEach(user.devices, (o, k) => {
          let tmp = _.find(vm.devicesArray, (d) => {
            return Number(k) === d.device.id;
          });
          if (tmp !== undefined) tmp.remark = false;
        });
      }
    }

    function loadMasterUsers() {
      userFactory.masterUsers(UserData.id).then((result) => {
        vm.subusers = result.plain();
      });
    }

    function unlinkUserAndMaster(user, master) {
      if (user === undefined) {
        user = vm.user.id;
        master = vm.user.masterUser.id;
      }
      userFactory.unlink(user, master).then((result) => {
        if (result) {
          $rootScope.toast({
            type: 'success',
            title: 'OK',
          });
          vm.finalization = true;
          vm.user.masterUser = null;
        } else {
          vm.finalization = false;
        }
      });
    }

    function unlinkUser(user) {
      userFactory.unlinkUser(user, UserData.id).then((result) => {
        $rootScope.toast({
          type: 'success',
          title: 'OK',
        });
        loadMasterUsers();
        loadUserDevices();
      });
    }

    function returnType(type) {
      switch (type) {
        case 2:
          return 'A-4000';
          break;
        case 3:
          return 'A-2500';
          break;
        case 4:
          return 'A-BIT';
          break;
        case 5:
          return 'A-7000';
          break;
        case 6:
          return 'A-5500';
          break;
      }
    }

    function getConfigDevice(unit) {
      $confirm({ text: 'Enviar orden ?', title: 'Datos AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        var send = 0;
        unit.sync.unittype = unit.type;
        userFactory.configDevice(unit.id, send).then((result) => {
          unit.sync = result.plain();
          $rootScope.toast({
            type: 'success',
            title: 'El proceso de recibir datos se ha iniciado',
          });
          loadUserDevices();
        });
      });
    }

    function sendConfigDevice(unit) {
      $confirm({ text: 'Enviar orden ?', title: 'Datos AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        var send = 1;
        unit.sync.unittype = unit.type;
        userFactory.configDevice(unit.id, send).then((result) => {
          unit.sync = result.plain();
          $rootScope.toast({
            type: 'success',
            title: 'El proceso de enviar datos se ha iniciado',
          });
          loadUserDevices();
        });
      });
    }
  }
})();
