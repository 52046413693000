import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UNITS } from '../../utils/units.enum';
import moment from 'moment/moment';
import { exportToDocument } from '@vegga/front-utils';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)

    .controller('historySectorsController', historySectorsController);

  historySectorsController.$inject = [
    '$scope',
    '$state',
    'UserData',
    'historyFactory',
    'sectorFactory',
    '$filter',
    'unitFactory',
    'historyUtilsFactory',
    '$rootScope',
  ];

  function historySectorsController(
    $scope,
    $state,
    UserData,
    historyFactory,
    sectorFactory,
    $filter,
    unitFactory,
    historyUtilsFactory,
    $rootScope
  ) {
    var vm = this;
    vm.activeList;
    vm.filter = {};

    vm.currentPage = 1;
    vm.itemsPerPage = 15;
    vm.totalData;

    vm.destroy$ = new Subject();
    vm.UNITS = UNITS;

    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();
    vm.view = 1;

    function activate() {
      vm.currentState = $state.includes;

      vm.from = historyUtilsFactory.from;
      vm.to = historyUtilsFactory.to;
      vm.minDateValue = historyUtilsFactory.getMinDate(UserData.profesional);
      vm.maxDateValue = historyUtilsFactory.maxDateValue;

      vm.loadHistory = loadHistory;
      vm.exportXLS = exportXLS;
      vm.getHistory = getHistory;
      vm.pageChangeHandler = pageChangeHandler;
      vm.pageSizeChangeHandler = pageSizeChangeHandler;
      vm.showHideFert = showHideFert;
      vm.sortGrid = sortGrid;

      vm.hideFertColumns = true;

      vm.sectorListDTO = [{ text: $filter('translate')('sensors.all'), value: '0' }];
      vm.updateRangeDates = updateRangeDates;
      vm.filter.groupBy = '2';
      vm.filter.sector = '0';
      vm.sortDirection = 'DESC';
      vm.sortProperty = 'from';

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        if (!vm.currentUnit) {
          getColumnDefinition(currentUnit.type);
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.currentUnit = currentUnit;
      vm.groupBy =
        vm.currentUnit.type !== UNITS.A_4000
          ? historyUtilsFactory.getGroupBy()
          : historyUtilsFactory.getGroupBy(true, true);

      loadHistory();
      loadSectors();
    }

    function checkDates(i) {
      if (vm.currentUnit.type === UNITS.A_4500) {
        return i[3] + ' - ' + i[4];
      } else if (vm.currentUnit.type === UNITS.A_4000) {
        return i.from + ' - ' + i.to;
      } else {
        return i[2] + ' - ' + i[3];
      }
    }

    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
      loadHistory();
    }

    function loadHistory(exporting) {
      var params = {
        //TODO: We have to add 1 day maybe becaue in the backend the check is "lower than".
        from: vm.from,
        to: moment(vm.to, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY'),
        type: vm.currentUnit.type,
        filter: vm.filter,
        page: vm.currentPage,
        limit: vm.itemsPerPage,
        'sort.direction': vm.sortDirection,
        'sort.property': vm.sortProperty,
      };

      if (exporting) {
        $rootScope.$emit('preventSkeleton', true);
        params = { ...params, limit: vm.totalData ? vm.totalData : 1 };
      }

      return historyFactory.sectors(vm.currentUnit.id, params).then((data) => {
        var sector = data;
        vm.totalData = data.total;
        if (sector.content !== undefined && sector.content.length > 0) {
          //Had to force the code below at the end of the stack queue
          setTimeout(() => {
            vm.hstySectorsData = sector.content.map((i) => {
              if (Array.isArray(i)) {
                const hoursAndMinutes =
                  vm.currentUnit.type === vm.UNITS.A_4500
                    ? moment.duration(i[7], 'seconds')
                    : moment.duration(i[6], 'seconds');
                return {
                  from: checkDates(i),
                  time:
                    vm.currentUnit.type === vm.UNITS.A_4500
                      ? `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min ${hoursAndMinutes.seconds()}s`
                      : `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`,
                  sector: vm.currentUnit.type === vm.UNITS.A_4500 ? i[5] : i[4],
                  name: vm.currentUnit.type === vm.UNITS.A_4500 ? i[6] : i[5],
                  volume:
                    vm.currentUnit.type === vm.UNITS.A_4500
                      ? i[8] === 0
                        ? '-'
                        : i[8]
                      : i[7] === 0
                      ? '-'
                      : (Number(i[7]) / 1000).toFixed(2) + ' m3',
                  escape: i[9], //4500
                  //avgPH: i.phAvg, no apply
                  //avgCE: i.ceAvg, no apply
                  realFlow:
                    vm.currentUnit.type === vm.UNITS.A_2500 || vm.currentUnit.type === vm.UNITS.A_BIT
                      ? parseRealFlow(i)
                      : vm.currentUnit.type === vm.UNITS.A_7000
                      ? i[24]
                      : vm.currentUnit.type === vm.UNITS.A_5500
                      ? i[18]
                      : i[11], //4500
                  prevFlow:
                    vm.currentUnit.type === vm.UNITS.A_2500 || vm.currentUnit.type === vm.UNITS.A_BIT
                      ? parsePrevFlow(i)
                      : vm.currentUnit.type === vm.UNITS.A_7000
                      ? i[25]
                      : vm.currentUnit.type === vm.UNITS.A_5500
                      ? i[19]
                      : i[12], //4500
                  desvFlow:
                    vm.currentUnit.type === vm.UNITS.A_2500
                      ? i[24]
                      : vm.currentUnit.type === vm.UNITS.A_BIT
                      ? i[16]
                      : vm.currentUnit.type === vm.UNITS.A_5500
                      ? i[22]
                      : i[13], //4500
                  area:
                    vm.currentUnit.type === vm.UNITS.A_2500
                      ? i[23]
                      : vm.currentUnit.type === vm.UNITS.A_BIT
                      ? i[17]
                      : vm.currentUnit.type === vm.UNITS.A_7000
                      ? i[26]
                      : vm.currentUnit.type === vm.UNITS.A_5500
                      ? i[20]
                      : i[14], //4500
                  //kc: i.kc.toFixed(2), no apply
                  mm: vm.currentUnit.type === vm.UNITS.A_4500 ? i[15] : getMilimeters(i),
                  fert1: vm.currentUnit.type === vm.UNITS.A_4500 ? i[16] : i[8],
                  fert2: vm.currentUnit.type === vm.UNITS.A_4500 ? i[18] : i[10],
                  fert3: vm.currentUnit.type === vm.UNITS.A_4500 ? i[20] : i[12],
                  fert4: vm.currentUnit.type === vm.UNITS.A_4500 ? i[22] : i[14],
                  fert5:
                    vm.currentUnit.type === vm.UNITS.A_7000 || vm.currentUnit.type === vm.UNITS.A_5500 ? i[16] : i[28], //4500
                  fert6: vm.currentUnit.type === vm.UNITS.A_7000 ? i[18] : i[24], //4500
                  fert7: vm.currentUnit.type === vm.UNITS.A_7000 ? i[20] : i[26], //4500
                  fert8: vm.currentUnit.type === vm.UNITS.A_7000 ? i[22] : i[28], //4500
                  fert1L: vm.currentUnit.type === vm.UNITS.A_4500 ? i[17] : i[9],
                  fert2L: vm.currentUnit.type === vm.UNITS.A_4500 ? i[19] : i[11],
                  fert3L: vm.currentUnit.type === vm.UNITS.A_4500 ? i[21] : i[13],
                  fert4L: vm.currentUnit.type === vm.UNITS.A_4500 ? i[23] : i[15],
                  fert5L:
                    vm.currentUnit.type === vm.UNITS.A_7000 || vm.currentUnit.type === vm.UNITS.A_5500 ? i[17] : i[29], //4500
                  fert6L: vm.currentUnit.type === vm.UNITS.A_7000 ? i[19] : i[31], //4500
                  fert7L: vm.currentUnit.type === vm.UNITS.A_7000 ? i[21] : i[33], //4500
                  fert8L: vm.currentUnit.type === vm.UNITS.A_7000 ? i[23] : i[35], //4500
                };
              } else {
                //4000
                const hoursAndMinutes = moment.duration(i.time, 'seconds');
                return {
                  from: checkDates(i),
                  time: `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`,
                  sector: i.sector,
                  name: i.name,
                  volume: i.volume === 0 ? '-' : (Number(i.volume) / 1000).toFixed(2) + ' m3',
                  avgPH: i.phAvg,
                  avgCE: i.ceAvg,
                  realFlow: i.realFlow ? i.realFlow.toFixed(3) + ' m3/h' : '',
                  prevFlow: (i.prevFlow / 100).toFixed(2) + ' m3/h',
                  desvFlow: i.desvFlow,
                  area: i.area,
                  kc: i.kc.toFixed(2),
                  mm: getMilimeters(i),
                  fert1: i.fert1,
                  fert2: i.fert2,
                  fert3: i.fert3,
                  fert4: i.fert4,
                  fert5: i.fert5,
                  fert6: i.fert6,
                  fert7: i.fert7,
                  fert8: i.fert8,
                };
              }
            });

            if (exporting) {
              parseExportedData();
            }
          }, 1);
        } else {
          //Had to force the code below at the end of the stack queue
          setTimeout(() => {
            vm.hstySectorsData = [];
            if (exporting) {
              parseExportedData();
            }
          }, 1);
        }
        $rootScope.$emit('preventSkeleton', false);
      });
    }

    function parseExportedData() {
      if (vm.currentUnit.type === UNITS.A_4500) {
        const data = vm.hstySectorsData.map((item) => {
          Object.keys(item).forEach((key) => {
            if (key.includes('fert') && !key.includes('L')) {
              const fertTime = moment.duration(item[key], 'seconds');
              item[key] = `${fertTime.hours()}h ${fertTime.minutes()}min ${fertTime.seconds()}s`;
            }
          });
          return item;
        });

        exportToDocument('xls', vm.gridColumnsDef, data, {
          fileName: $filter('translate')('history.sectorHistory'),
        });
      } else {
        exportToDocument('xls', vm.gridColumnsDef, vm.hstySectorsData, {
          fileName: $filter('translate')('history.sectorHistory'),
        });
      }
    }

    function parseRealFlow(i) {
      let format = i[i.length - 1];
      let unit = '';
      switch (format) {
        case 0:
          unit = ' m3/h';
          break;
        case 1:
          unit = ' L/h';
          break;
        case 2:
          unit = ' L/s';
          break;
      }
      switch (vm.currentUnit.type) {
        case vm.UNITS.A_2500:
          return i[21] + unit;
          break;
        case vm.UNITS.A_BIT:
          return i[13] + unit;
          break;
      }
    }
    function parsePrevFlow(i) {
      let format = i[i.length - 1];
      let unit = '';
      let input;
      switch (format) {
        case 0:
          unit = ' m3/h';
          break;
        case 1:
          unit = ' L/h';
          break;
        case 2:
          unit = ' L/s';
          break;
      }
      switch (vm.currentUnit.type) {
        case vm.UNITS.A_2500:
          input = i[22];
          return input === 0 ? '-' : (Number(input) / 100).toFixed(2) + unit;
          break;
        case vm.UNITS.A_BIT:
          input = i[14];
          return input === 0 ? '-' : (Number(input) / 100).toFixed(2) + unit;
          break;
      }
    }

    function getMilimeters(i) {
      let liters, meters;
      switch (vm.currentUnit.type) {
        case vm.UNITS.A_4000:
          liters = i.volume;
          meters = i.area * 10000;
          break;
        case vm.UNITS.A_2500:
          liters = i[7];
          meters = i[23] * 10000;
          break;
      }
      if (meters > 0) {
        return (liters / meters).toFixed(2);
      } else {
        return 0;
      }
    }

    function getColumnDefinition(type) {
      switch (type) {
        case vm.UNITS.A_4000:
          return (vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef(
            [
              historyUtilsFactory.getDateColumnDef(),
              {
                field: 'sector',
              },
              {
                field: 'name',
                width: 150,
              },
              {
                field: 'time',
                width: 120,
              },
              { field: 'volume' },
              {
                field: 'fert1',
                cellTemplate: (historyItem) => {
                  if (vm.currentUnit.flow.fertilizationUnit === 0 || vm.currentUnit.flow.fertilizationUnit === 1) {
                    const hoursAndMinutes = moment.duration(historyItem.fert1, 'seconds');
                    return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
                  } else {
                    return historyItem.fert1 === 0 ? '-' : (Number(historyItem.fert1) / 10).toFixed(2) + ' l';
                  }
                },
                hide: vm.hideFertColumns,
              },
              {
                field: 'fert2',
                cellTemplate: (historyItem) => {
                  if (vm.currentUnit.flow.fertilizationUnit === 0 || vm.currentUnit.flow.fertilizationUnit === 1) {
                    const hoursAndMinutes = moment.duration(historyItem.fert2, 'seconds');
                    return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
                  } else {
                    return historyItem.fert2 === 0 ? '-' : (Number(historyItem.fert2) / 10).toFixed(2) + ' l';
                  }
                },
                hide: vm.hideFertColumns,
              },
              {
                field: 'fert3',
                cellTemplate: (historyItem) => {
                  if (vm.currentUnit.flow.fertilizationUnit === 0 || vm.currentUnit.flow.fertilizationUnit === 1) {
                    const hoursAndMinutes = moment.duration(historyItem.fert3, 'seconds');
                    return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
                  } else {
                    return historyItem.fert3 === 0 ? '-' : (Number(historyItem.fert3) / 10).toFixed(2) + ' l';
                  }
                },
                hide: vm.hideFertColumns,
              },
              {
                field: 'fert4',
                cellTemplate: (historyItem) => {
                  if (vm.currentUnit.flow.fertilizationUnit === 0 || vm.currentUnit.flow.fertilizationUnit === 1) {
                    const hoursAndMinutes = moment.duration(historyItem.fert4, 'seconds');
                    return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
                  } else {
                    return historyItem.fert4 === 0 ? '-' : (Number(historyItem.fert4) / 10).toFixed(2) + ' l';
                  }
                },
                hide: vm.hideFertColumns,
              },
              {
                field: 'fert5',
                cellTemplate: (historyItem) => {
                  if (vm.currentUnit.flow.fertilizationUnit === 0 || vm.currentUnit.flow.fertilizationUnit === 1) {
                    const hoursAndMinutes = moment.duration(historyItem.fert5, 'seconds');
                    return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
                  } else {
                    return historyItem.fert5 === 0 ? '-' : (Number(historyItem.fert5) / 10).toFixed(2) + ' l';
                  }
                },
                hide: vm.hideFertColumns,
              },
              {
                field: 'fert6',
                cellTemplate: (historyItem) => {
                  if (vm.currentUnit.flow.fertilizationUnit === 0 || vm.currentUnit.flow.fertilizationUnit === 1) {
                    const hoursAndMinutes = moment.duration(historyItem.fert6, 'seconds');
                    return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
                  } else {
                    return historyItem.fert6 === 0 ? '-' : (Number(historyItem.fert6) / 10).toFixed(2) + ' l';
                  }
                },
                hide: vm.hideFertColumns,
              },
              {
                field: 'fert7',
                cellTemplate: (historyItem) => {
                  if (vm.currentUnit.flow.fertilizationUnit === 0 || vm.currentUnit.flow.fertilizationUnit === 1) {
                    const hoursAndMinutes = moment.duration(historyItem.fert7, 'seconds');
                    return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
                  } else {
                    return historyItem.fert7 === 0 ? '-' : (Number(historyItem.fert7) / 10).toFixed(2) + ' l';
                  }
                },
                hide: vm.hideFertColumns,
              },
              {
                field: 'fert8',
                cellTemplate: (historyItem) => {
                  if (vm.currentUnit.flow.fertilizationUnit === 0 || vm.currentUnit.flow.fertilizationUnit === 1) {
                    const hoursAndMinutes = moment.duration(historyItem.fert8, 'seconds');
                    return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
                  } else {
                    return historyItem.fert8 === 0 ? '-' : (Number(historyItem.fert8) / 10).toFixed(2) + ' l';
                  }
                },
                hide: vm.hideFertColumns,
              },

              { field: 'avgPH' },
              { field: 'avgCE' },
              { field: 'realFlow' },
              {
                field: 'prevFlow',
                width: 120,
              },
              { field: 'desvFlow' },
              { field: 'area' },
              {
                field: 'kc',
              },
              {
                field: 'mm',
              },
            ],
            true
          ));

        case vm.UNITS.A_2500:
          return (vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            {
              field: 'sector',
            },
            {
              field: 'name',
              width: 150,
            },
            {
              field: 'time',
              width: 120,
            },
            { field: 'volume' },
            {
              field: 'fert1',
              cellTemplate: (historyItem) => {
                const hoursAndMinutes = moment.duration(historyItem.fert1, 'seconds');
                return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert1L',
              cellTemplate: (historyItem) => {
                return historyItem.fert1L === 0 ? '-' : (Number(historyItem.fert1L) / 100).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert2',
              cellTemplate: (historyItem) => {
                const hoursAndMinutes = moment.duration(historyItem.fert2, 'seconds');
                return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert2L',
              cellTemplate: (historyItem) => {
                return historyItem.fert2L === 0 ? '-' : (Number(historyItem.fert2L) / 100).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert3',
              cellTemplate: (historyItem) => {
                const hoursAndMinutes = moment.duration(historyItem.fert3, 'seconds');
                return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert3L',
              cellTemplate: (historyItem) => {
                return historyItem.fert3L === 0 ? '-' : (Number(historyItem.fert3L) / 100).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert4',
              cellTemplate: (historyItem) => {
                const hoursAndMinutes = moment.duration(historyItem.fert4, 'seconds');
                return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert4L',
              cellTemplate: (historyItem) => {
                return historyItem.fert4L === 0 ? '-' : (Number(historyItem.fert4L) / 100).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            { field: 'realFlow' },
            {
              field: 'prevFlow',
              width: 120,
            },
            { field: 'desvFlow' },
            { field: 'area' },
            {
              field: 'mm',
            },
          ]));

        case vm.UNITS.A_BIT:
          return (vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            {
              field: 'sector',
            },
            {
              field: 'name',
              width: 150,
            },
            {
              field: 'time',
              width: 120,
            },
            { field: 'volume' },
            { field: 'realFlow' },
            {
              field: 'prevFlow',
              width: 120,
            },
            { field: 'desvFlow' },
            { field: 'area' },
          ]));

        case vm.UNITS.A_7000:
          return (vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            {
              headerName: $filter('translate')('history.sector'),
              field: 'sector',
            },
            { headerName: $filter('translate')('history.sensorname'), field: 'name' },
            { headerName: $filter('translate')('history.time'), field: 'time' },
            { headerName: $filter('translate')('history.volume'), field: 'volume' },
            {
              headerName: 'F1/F8',
              field: 'fert1',
              cellTemplate: (historyItem) => {
                const hoursAndMinutes = moment.duration(historyItem.fert1, 'seconds');
                return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F1/F8 (L)',
              field: 'fert1L',
              cellTemplate: (historyItem) => {
                return historyItem.fert1L === 0 ? '-' : (Number(historyItem.fert1L) / 10).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F2/F7',
              field: 'fert2',
              cellTemplate: (historyItem) => {
                const hoursAndMinutes = moment.duration(historyItem.fert2, 'seconds');
                return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F2/F7 (L)',
              field: 'fert2L',
              cellTemplate: (historyItem) => {
                return historyItem.fert2L === 0 ? '-' : (Number(historyItem.fert2L) / 10).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F3/F6',
              field: 'fert3',
              cellTemplate: (historyItem) => {
                const hoursAndMinutes = moment.duration(historyItem.fert3, 'seconds');
                return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F3/F6 (L)',
              field: 'fert3L',
              cellTemplate: (historyItem) => {
                return historyItem.fert3L === 0 ? '-' : (Number(historyItem.fert3L) / 10).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F4/F5',
              field: 'fert4',
              cellTemplate: (historyItem) => {
                const hoursAndMinutes = moment.duration(historyItem.fert4, 'seconds');
                return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F4/F5 (L)',
              field: 'fert4L',
              cellTemplate: (historyItem) => {
                return historyItem.fert4L === 0 ? '-' : (Number(historyItem.fert4L) / 10).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F5/F4',
              field: 'fert5',
              cellTemplate: (historyItem) => {
                const hoursAndMinutes = moment.duration(historyItem.fert5, 'seconds');
                return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F5/F4 (L)',
              field: 'fert5L',
              cellTemplate: (historyItem) => {
                return historyItem.fert5L === 0 ? '-' : (Number(historyItem.fert5L) / 10).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F6/F3',
              field: 'fert6',
              cellTemplate: (historyItem) => {
                const hoursAndMinutes = moment.duration(historyItem.fert6, 'seconds');
                return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F6/F3 (L)',
              field: 'fert6L',
              cellTemplate: (historyItem) => {
                return historyItem.fert6L === 0 ? '-' : (Number(historyItem.fert6L) / 10).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F7/F2',
              field: 'fert7',
              cellTemplate: (historyItem) => {
                const hoursAndMinutes = moment.duration(historyItem.fert7, 'seconds');
                return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F7/F2 (L)',
              field: 'fert7L',
              cellTemplate: (historyItem) => {
                return historyItem.fert7L === 0 ? '-' : (Number(historyItem.fert7L) / 10).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F8/F1',
              field: 'fert8',
              cellTemplate: (historyItem) => {
                const hoursAndMinutes = moment.duration(historyItem.fert8, 'seconds');
                return `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`;
              },
              hide: vm.hideFertColumns,
            },
            {
              headerName: 'F8/F1 (L)',
              field: 'fert8L',
              cellTemplate: (historyItem) => {
                return historyItem.fert8L === 0 ? '-' : (Number(historyItem.fert8L) / 10).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            { headerName: $filter('translate')('history.realflow'), field: 'realFlow' },
            { headerName: $filter('translate')('history.prevflow'), field: 'prevFlow' },
          ]));

        case vm.UNITS.A_5500:
          return (vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),

            {
              field: 'sector',
            },
            {
              field: 'time',
              width: 120,
            },
            { field: 'volume' },
            {
              field: 'fert1',
              cellTemplate: (historyItem) => {
                const MinutesAndSeconds = moment.duration(historyItem.fert1, 'seconds');
                return `${MinutesAndSeconds.minutes()}"'" ${MinutesAndSeconds.seconds()}'"'`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert1L',
              cellTemplate: (historyItem) => {
                return historyItem.fert1L === 0 ? '-' : (Number(historyItem.fert1L) / 100).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert2',
              cellTemplate: (historyItem) => {
                const MinutesAndSeconds = moment.duration(historyItem.fert2, 'seconds');
                return `${MinutesAndSeconds.minutes()}"'" ${MinutesAndSeconds.seconds()}'"'`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert2L',
              cellTemplate: (historyItem) => {
                return historyItem.fert2L === 0 ? '-' : (Number(historyItem.fert2L) / 100).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert3',
              cellTemplate: (historyItem) => {
                const MinutesAndSeconds = moment.duration(historyItem.fert3, 'seconds');
                return `${MinutesAndSeconds.minutes()}"'" ${MinutesAndSeconds.seconds()}'"'`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert3L',
              cellTemplate: (historyItem) => {
                return historyItem.fert3L === 0 ? '-' : (Number(historyItem.fert3L) / 100).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert4',
              cellTemplate: (historyItem) => {
                const MinutesAndSeconds = moment.duration(historyItem.fert4, 'seconds');
                return `${MinutesAndSeconds.minutes()}"'" ${MinutesAndSeconds.seconds()}'"'`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert4L',
              cellTemplate: (historyItem) => {
                return historyItem.fert4L === 0 ? '-' : (Number(historyItem.fert4L) / 100).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert5',
              cellTemplate: (historyItem) => {
                const MinutesAndSeconds = moment.duration(historyItem.fert5, 'seconds');
                return `${MinutesAndSeconds.minutes()}"'" ${MinutesAndSeconds.seconds()}'"'`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert5L',
              cellTemplate: (historyItem) => {
                return historyItem.fert5L === 0 ? '-' : (Number(historyItem.fert5L) / 100).toFixed(2) + ' l';
              },
              hide: vm.hideFertColumns,
            },
            { field: 'realFlow' },
            {
              field: 'prevFlow',
              width: 120,
            },
            { field: 'desvFlow' },
            { field: 'area' },
          ]));

        case UNITS.A_4500:
          return (vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            {
              field: 'sector',
            },
            {
              field: 'name',
              width: 150,
            },
            {
              field: 'time',
              width: 120,
            },
            { field: 'volume' },
            { field: 'escape' },
            {
              field: 'fert1',
              cellTemplate: (historyItem) => {
                const fertTime = moment.duration(historyItem.fert1, 'seconds');
                return `<vegga-text>${fertTime.hours()}h ${fertTime.minutes()}min  ${fertTime.seconds()}s</vegga-text>`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert1L',
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert2',
              cellTemplate: (historyItem) => {
                const fertTime = moment.duration(historyItem.fert2, 'seconds');
                return `<vegga-text>${fertTime.hours()}h ${fertTime.minutes()}min  ${fertTime.seconds()}s</vegga-text>`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert2L',
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert3',
              cellTemplate: (historyItem) => {
                const fertTime = moment.duration(historyItem.fert3, 'seconds');
                return `<vegga-text>${fertTime.hours()}h ${fertTime.minutes()}min  ${fertTime.seconds()}s</vegga-text>`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert3L',
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert4',
              cellTemplate: (historyItem) => {
                const fertTime = moment.duration(historyItem.fert4, 'seconds');
                return `<vegga-text>${fertTime.hours()}h ${fertTime.minutes()}min  ${fertTime.seconds()}s</vegga-text>`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert4L',
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert5',
              cellTemplate: (historyItem) => {
                const fertTime = moment.duration(historyItem.fert5, 'seconds');
                return `<vegga-text>${fertTime.hours()}h ${fertTime.minutes()}min  ${fertTime.seconds()}s</vegga-text>`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert5L',
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert6',
              cellTemplate: (historyItem) => {
                const fertTime = moment.duration(historyItem.fert6, 'seconds');
                return `<vegga-text>${fertTime.hours()}h ${fertTime.minutes()}min  ${fertTime.seconds()}s</vegga-text>`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert6L',
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert7',
              cellTemplate: (historyItem) => {
                const fertTime = moment.duration(historyItem.fert7, 'seconds');
                return `<vegga-text>${fertTime.hours()}h ${fertTime.minutes()}min  ${fertTime.seconds()}s</vegga-text>`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert7L',
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert8',
              cellTemplate: (historyItem) => {
                const fertTime = moment.duration(historyItem.fert8, 'seconds');
                return `<vegga-text>${fertTime.hours()}h ${fertTime.minutes()}min  ${fertTime.seconds()}s</vegga-text>`;
              },
              hide: vm.hideFertColumns,
            },
            {
              field: 'fert8L',
              hide: vm.hideFertColumns,
            },
            { headerName: $filter('translate')('history.realflow'), field: 'realFlow' },
            {
              field: 'prevFlow',

              cellTemplate: ({ prevFlow }) => {
                return `<vegga-text>${!prevFlow ? '0.00' : prevFlow} m³/h</vegga-text>`;
              },
            },
            { field: 'desvFlow' },
            { field: 'area' },
            {
              field: 'mm',
            },
          ]));
      }
    }

    function pageChangeHandler(event) {
      vm.currentPage = event.detail;
      loadHistory();
    }

    function pageSizeChangeHandler(event) {
      vm.itemsPerPage = event.detail;
      vm.currentPage = 1;
      loadHistory();
    }

    function sortGrid({ detail }) {
      const { colField, dir } = detail;

      vm.sortDirection = dir;

      vm.sortProperty = colField;

      loadHistory();
    }

    function exportXLS() {
      loadHistory(true);
    }

    function showHideFert($event) {
      vm.hideFertColumns = !$event.detail.checked;

      getColumnDefinition(vm.currentUnit.type);
    }

    function getHistory() {
      historyFactory.getHistory(vm.currentUnit.type, vm.currentUnit.id);
    }

    function loadSectors() {
      sectorFactory.allSectors(vm.currentUnit.id).then(function (data) {
        vm.sectorList = data.plain();
        vm.sectorListDTO.push(
          ...vm.sectorList.map((sector) => {
            return {
              text: sector.pk.id + ' - ' + (sector.name ? sector.name : 'Sector ' + sector.pk.id),
              value: sector.pk.id,
            };
          })
        );
      });
    }
    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
