import '../components/a25/programs/programs-controller';
import a25ProgramsTemplate from '../components/a25/programs/programs.html';
import '../components/manual/manual-factory';

import '../components/a25/programs/programs.detail-controller';
import a25DetailProgramsTemplate from '../components/a25/programs/programs.detail.html';
import '../components/register/register-factory';
import '../components/fertilizers/fertilizers-factory';
import '../components/general_outputs/config-factory';
import '../components/utils/filter';

import '../components/a25/programs/programs.edit-controller';
import a25EditProgramsTemplate from '../components/a25/programs/programs.edit.html';

import '../components/a25/programs/programs.config-controller';
import a25ConfigProgramsTemplate from '../components/a25/programs/programs.config.html';

import '../components/pivots/pivots-controller';
import pivotsTemplate from '../components/pivots/pivots.html';
import '../components/pivots/pivots-factory';

import '../components/pivots/pivots.detail-controller';
import pivotsDetailTemplate from '../components/pivots/pivots.detail.html';

import '../components/pivots/pivots.edit-controller';
import pivotsEditTemplate from '../components/pivots/pivots.edit.html';

import '../components/pivots/pivots.config-controller';
import pivotsConfigTemplate from '../components/pivots/pivots.config.html';

import '../components/conditioner/conditioner-controller';
import conditionerTemplate from '../components/conditioner/conditioner.html';
import '../components/conditioner/conditioner-factory';

import '../components/conditioner/conditioner.config-controller';
import conditionerConfigTemplate from '../components/conditioner/conditioner.config.html';

import '../components/solar/solar-controller';
import solarTemplate from '../components/solar/solar.html';
import '../components/solar/solar-factory';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider) {
        console.log('app-state.a25.js');
        $stateProvider

          .state('a25programs', {
            parent: 'unit',
            url: '/a25/programs',
            params: {
              unit: null,
              config: null,
            },
            template: a25ProgramsTemplate,
            controller: 'programsControllerA25',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('a25programs.detail', {
            // parent: 'a25programs',
            // Use a url of '/' to set a states as the 'index'.
            url: '/detail/{id}',
            params: {
              program: null,
              id: null,
            },
            template: a25DetailProgramsTemplate,
            controller: 'programsDetailControllerA25',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('a25programs.edit', {
            // parent: 'a25programs',
            // Use a url of '/' to set a states as the 'index'.
            url: '/edit/{id}',
            params: {
              program: null,
              id: null,
            },
            template: a25EditProgramsTemplate,
            controller: 'programsEditControllerA25',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('a25programs.config', {
            // parent: 'a25programs',
            // Use a url of '/' to set a states as the 'index'.
            url: '/config/{id}',
            params: {
              program: null,
              id: null,
            },
            template: a25ConfigProgramsTemplate,
            controller: 'programsConfigControllerA25',
            authenticate: true,
            controllerAs: 'vm',
          })
          /***/

          .state('pivots', {
            parent: 'unit',
            url: '/pivots',
            params: {
              unit: null,
              config: null,
            },
            template: pivotsTemplate,
            controller: 'pivotsController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('pivots.detail', {
            // parent: 'pivots',
            // Use a url of '/' to set a states as the 'index'.
            url: '/detail/{id}',
            params: {
              pivot: null,
              id: null,
            },
            template: pivotsDetailTemplate,
            controller: 'pivotsDetailController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('pivots.edit', {
            // parent: 'pivots',
            // Use a url of '/' to set a states as the 'index'.
            url: '/edit/{id}',
            params: {
              pivot: null,
              id: null,
            },
            template: pivotsEditTemplate,
            controller: 'pivotsEditController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('pivots.config', {
            // parent: 'pivots',
            // Use a url of '/' to set a states as the 'index'.
            url: '/config/{id}',
            params: {
              pivot: null,
              id: null,
            },
            template: pivotsConfigTemplate,
            controller: 'pivotsConfigController',
            authenticate: true,
            controllerAs: 'vm',
          })
          /***/

          .state('conditioner', {
            parent: 'unit',
            url: '/conditioner',
            template: conditionerTemplate,
            controller: 'conditionerController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          // TODO - Check reason why unit is empty
          .state('conditioner.config', {
            // parent: 'conditioner',
            // Use a url of '/' to set a states as the 'index'.
            url: '/config',
            params: {
              unit: null,
              id: null,
              conditioners: null,
            },
            template: conditionerConfigTemplate,
            controller: 'conditionerConfigController',
            authenticate: true,
            controllerAs: 'vm',
          })

          /***/

          .state('solar', {
            parent: 'unit',
            params: {
              unit: null,
              id: null,
            },
            url: `/solar`,
            template: solarTemplate,
            controller: 'solarController',
            authenticate: true,
            controllerAs: 'vm',
          });
      },
    ]);
})();
