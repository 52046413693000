import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';
import { exportToDocument } from '@vegga/front-utils';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('historyTraysController', historyTraysController);

  historyTraysController.$inject = [
    '$scope',
    '$state',
    'UserData',
    'historyFactory',
    '$filter',
    'unitFactory',
    'historyUtilsFactory',
    '$rootScope',
  ];

  function historyTraysController(
    $scope,
    $state,
    UserData,
    historyFactory,
    $filter,
    unitFactory,
    historyUtilsFactory,
    $rootScope
  ) {
    var vm = this;
    vm.filter = {};
    var loaded;

    vm.UNITS = UNITS;

    vm.currentPage = 1;
    vm.itemsPerPage = 15;
    vm.totalData;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();

    activate();

    function activate() {
      vm.currentState = $state.includes;

      vm.from = historyUtilsFactory.from;
      vm.to = historyUtilsFactory.to;
      vm.minDateValue = historyUtilsFactory.getMinDate(UserData.profesional);
      vm.maxDateValue = historyUtilsFactory.maxDateValue;

      vm.updateRangeDates = updateRangeDates;
      vm.loadHistory = loadHistory;
      vm.exportXLS = exportXLS;

      vm.pageChangeHandler = pageChangeHandler;
      vm.pageSizeChangeHandler = pageSizeChangeHandler;

      vm.groupBy = historyUtilsFactory.getGroupBy(true);
      vm.filter.groupBy = '2';

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.currentUnit = currentUnit;
      getColumnDefinition(vm.currentUnit.type);
      loadHistory();
    }

    function getColumnDefinition(type) {
      switch (type) {
        case UNITS.A_7000:
          return historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            { field: 'tray' },
            { field: 'drainage' },
            { field: 'phavg' },
            { field: 'ceavg' },
          ]);
          break;
      }
    }

    function checkDates(i) {
      if (vm.currentUnit.type === vm.UNITS.A_7000) {
        return i[1] + ' - ' + i[2];
      } else {
        return i[2] + ' - ' + i[3];
      }
    }
    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
      loadHistory();
    }

    function loadHistory(exporting) {
      var params = {
        //TODO: We have to add 1 day maybe becaue in the backend the check is "lower than".
        from: vm.from,
        to: moment(vm.to, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY'),
        filter: vm.filter,
        page: vm.currentPage,
        limit: vm.itemsPerPage,
      };

      if (exporting) {
        $rootScope.$emit('preventSkeleton', true);
        params = { ...params, limit: vm.totalData ? vm.totalData : 1 };
      }

      return historyFactory.trays(vm.currentUnit.id, params).then((data) => {
        var tray = data;
        vm.totalData = data.total;
        if (tray.content !== undefined && tray.content.length > 0) {
          vm.hstyTraysData = tray.content.map((i) => {
            return {
              from: checkDates(i),
              tray: i[5],
              drainage: i[7],
              phavg: i[5],
              ceavg: i[6],
            };
          });
          if (exporting) {
            exportToDocument('xls', vm.gridColumnsDef, vm.hstyTraysData, {
              fileName: $filter('translate')('history.trayHistory'),
            });
          }
        } else {
          vm.hstyTraysData = [];
          if (exporting) {
            exportToDocument('xls', vm.gridColumnsDef, vm.hstyTraysData, {
              fileName: $filter('translate')('history.trayHistory'),
            });
          }
        }
      });
    }

    function pageChangeHandler(event) {
      vm.currentPage = event.detail;
      loadHistory();
    }

    function pageSizeChangeHandler(event) {
      vm.itemsPerPage = event.detail;
      vm.currentPage = 1;
      loadHistory();
    }

    function exportXLS() {
      loadHistory(true);
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
