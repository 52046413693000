import { UNITS, UNITS_V2 } from '../utils/units.enum';
import { SLOGICS_OPS } from '../utils/enums/sensors/slogics-operations-type-enum';
import { SLOGICS_SIMPLE_OPS_TYPE } from '../utils/enums/sensors/slogics-simple-ops-type-enum';
import { SLOGICS_AND_OR_OPS_TYPE } from '../utils/enums/sensors/slogics-and-or-ops-enum';
import {
  IOSelectorItemResponseElementTypeEnum,
  IOSelectorItemResponseOutputTypeEnum,
} from '../utils/enums/io-selector.enum';
import { from, of, Subject } from 'rxjs';
import { getContainer } from '@vegga/front-store';
import { switchMap } from 'rxjs/operators';

import { showLoader, hideLoader } from '@vegga/front-utils';

import { A4500_DEFAULT_CONFIG } from '../utils/device-config';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('sensorsConfigLogicController', sensorsConfigLogicController);

  sensorsConfigLogicController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'sensorsFactory',
    'unitFactory',
    'sectorFactory',
    '$confirm',
    '$filter',
  ];

  function sensorsConfigLogicController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    UserData,
    sensorsFactory,
    unitFactory,
    sectorFactory,
    $confirm,
    $filter
  ) {
    var vm = this;
    vm.activeList;
    vm.lastSensor;
    vm.nextSensor;
    vm.selectSensor;
    vm.cancel;
    vm.save;

    const meterFormats = [
      A4500_DEFAULT_CONFIG.formats.metersFlow.energy,
      A4500_DEFAULT_CONFIG.formats.metersFlow.unit,
      A4500_DEFAULT_CONFIG.formats.metersFlow.volume,
    ].flat();
    activate();

    function activate() {
      vm.overlayEl = document.querySelector('#sensor-config-overlay');
      vm.overlayEl.show();
      vm.UNITS = UNITS;
      vm.SLOGICS_OPS = SLOGICS_OPS;
      vm.SLOGICS_SIMPLE_OPS_TYPE = SLOGICS_SIMPLE_OPS_TYPE;
      vm.SLOGICS_AND_OR_OPS_TYPE = SLOGICS_AND_OR_OPS_TYPE;
      vm.IOSelectorItemResponseOutputTypeEnum = IOSelectorItemResponseOutputTypeEnum;
      vm.IOSelectorItemResponseElementTypeEnum = IOSelectorItemResponseElementTypeEnum;
      vm.destroy$ = new Subject();
      vm.ioFacade = getContainer().resolve('ioFacade');
      vm.currentState = $state.includes;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.currentHeader = $state.params.headerId || 0;
        vm.forms = [];
        vm.page = 1;
        loadLogics();
        vm.selectSensor = loadLogics;
        vm.nextSensor = nextSensor;
        vm.lastSensor = lastSensor;
        vm.cancel = cancel_edition;
        vm.save = save;
        vm.setColor = setColor;
        vm.captionRange = _.range(0, 5);
        vm.changeState = changeState;
        vm.changeSensor = changeSensor;
        vm.changingSensor = changingSensor;
        vm.nextOrPreviousItem = nextOrPreviousItem;
        vm.resetElement = resetElement;
        vm.changeElementType = changeElementType;
        vm.changeElementTypeAndOr = changeElementTypeAndOr;
        vm.filterMeasureTypeByFormat = filterMeasureTypeByFormat;
        vm.getReferenceUnit = getReferenceUnit;

        vm.sensorOperations = [
          $filter('translate')('sensors.operations.inactive'),
          $filter('translate')('sensors.operations.sum'),
          $filter('translate')('sensors.operations.difference'),
          $filter('translate')('sensors.operations.average'),
          $filter('translate')('sensors.operations.and'),
          $filter('translate')('sensors.operations.or'),
        ];
        vm.sensorTypes = [
          { value: 6, text: $filter('translate')('sensors.analog') },
          { value: 7, text: $filter('translate')('sensors.sMeterFlow') },
          { value: 8, text: $filter('translate')('sensors.slogicValue') },
          { value: 9, text: $filter('translate')('sensors.analogicOutput') },
        ];
        vm.logicElementsIndex = [
          { id: 1, nameType: 'vm.sensor.type1', nameNum: 'vm.sensor.num1', condition: 'vm.sensor.condition1' },
          { id: 2, nameType: 'vm.sensor.type2', nameNum: 'vm.sensor.num2', condition: 'vm.sensor.condition2' },
          { id: 3, nameType: 'vm.sensor.type3', nameNum: 'vm.sensor.num3', condition: 'vm.sensor.condition3' },
          { id: 4, nameType: 'vm.sensor.type4', nameNum: 'vm.sensor.num4', condition: 'vm.sensor.condition4' },
          { id: 5, nameType: 'vm.sensor.type5', nameNum: 'vm.sensor.num5', condition: 'vm.sensor.condition5' },
        ];
        vm.sensorTypesAndOr = [
          { value: 1, text: $filter('translate')('sensors.sdigital') },
          { value: 2, text: $filter('translate')('sensors.conditioner') },
          { value: 3, text: $filter('translate')('sensors.digitalOutput') },
          { value: 4, text: $filter('translate')('sensors.slogicDigital') },
          { value: 5, text: $filter('translate')('sensors.sector') },
          { value: 6, text: $filter('translate')('sensors.analog') },
          { value: 7, text: $filter('translate')('sensors.sMeterFlow') },
          { value: 8, text: $filter('translate')('sensors.slogicValue') },
          { value: 9, text: $filter('translate')('sensors.analogicOutput') },
        ];
        vm.analogCondition = [$filter('translate')('sensors.higher'), $filter('translate')('sensors.lower')];
        vm.condition = [$filter('translate')('sensors.state_true'), $filter('translate')('sensors.state_false')];

        if (vm.sensor !== null && vm.sensor !== undefined) backup();
        loadMeasureTypeData();
      } else {
        $state.go('units');
      }
    }

    function loadMeasureTypeData() {
      vm.isDataLoaded = false;

      // Simple operations data
      vm.simpleOperationsData = [[], [], [], []];
      // And and or Data
      vm.AndOrData = [[], [], [], [], [], [], []];

      Promise.all([
        sensorsFactory.analogsAll(vm.currentUnit.id).then((data) => {
          vm.simpleOperationsData[SLOGICS_SIMPLE_OPS_TYPE.ANALOG_SENSOR] = data.filter((analog) => analog.input > 0);
          vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.ANALOG_SENSOR] = data.filter((analog) => analog.input > 0);
          vm.analogs = data.filter((analog) => analog.input > 0);
        }),
        sensorsFactory.meters(vm.currentUnit.id).then((data) => {
          vm.simpleOperationsData[SLOGICS_SIMPLE_OPS_TYPE.FLOW_METER_SENSOR] = data.filter((meter) => meter.input > 0);
          vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.FLOW_METER_SENSOR] = data.filter((meter) => meter.input > 0);
          vm.meters = data.filter((meter) => meter.input > 0);
        }),
        sensorsFactory.digitalsAll(vm.currentUnit.id).then((data) => {
          vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.DIGITAL_SENSOR] = data.filter((digital) => digital.input > 0);
          vm.digitals = data.filter((digital) => digital.input > 0);
        }),
        unitFactory.conditioners(vm.currentUnit.id).then((data) => {
          vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.CONDITIONER] = data.filter((cond) => cond.function > 0);
          vm.conditioners = data.filter((cond) => cond.function > 0);
        }),
        sectorFactory.allSectors(vm.currentUnit.id).then((data) => {
          vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.SECTOR] = data.filter((sector) => sector.output > 0);
          vm.allSectors = data.filter((sector) => sector.output > 0);
        }),
      ]).then(() => {
        vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.VALUE_LOGIC_SENSOR] = vm.logicsValue;
        vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.DIGITAL_LOGIC_SENSOR] = vm.logicsDigital;
        // salida digital
        vm.simpleOperationsData[SLOGICS_SIMPLE_OPS_TYPE.VALUE_LOGIC_SENSOR] = vm.logicsValue;
        vm.simpleOperationsData[SLOGICS_SIMPLE_OPS_TYPE.ANALOG_OUTPUT] = [];
        // salida digital
        vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.DIGITAL_OUTPUT] = [];
        //salida analogica
        vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.ANALOG_OUTPUT] = [];
        vm.andOrDataByMeasureType = [...vm.AndOrData];
        vm.operationsByMeasureType = [...vm.simpleOperationsData];
        vm.isDataLoaded = true;

        changeState(vm.page);
        filterMeasureTypeByFormat();
      });
    }

    function getFilterSLogicAnalog() {
      let elementType = null;
      let elementId = null;
      let filterSLogicsDigital = [];

      vm.logicsValue.forEach((logic) => {
        if (logic.operation !== 0) {
          elementType = null;
          elementId = null;
          for (let element of logic.elements) {
            if (element.elementType !== null) {
              elementType = element.elementType;
              elementId = element.elementId;
              break;
            }
          }

          if (elementType == 0 && elementId !== 0 && elementId !== null) {
            filterSLogicsDigital.push(logic);
          }
        }
      });
      return filterSLogicsDigital;
    }

    function filterMeasureTypeByFormat($event) {
      if ($event) {
        vm.logics[vm.page - 1].elements.forEach((el, i) => {
          if (i !== 0) el.elementId = null;
        });
      }

      const elementType = vm.logics[vm.page - 1].elements[0].elementType;

      if (vm.logics[vm.page - 1].operation !== SLOGICS_OPS.AND && vm.logics[vm.page - 1].operation !== SLOGICS_OPS.OR) {
        if (elementType === SLOGICS_SIMPLE_OPS_TYPE.ANALOG_SENSOR) {
          vm.operationsByMeasureType[SLOGICS_SIMPLE_OPS_TYPE.ANALOG_SENSOR] = vm.analogs.filter(
            (as) =>
              as.formatId ===
              vm.analogs.find((analog) => +analog.pk.id === vm.logics[vm.page - 1].elements[0].elementId).formatId
          );
        }
        if (elementType === SLOGICS_SIMPLE_OPS_TYPE.FLOW_METER_SENSOR) {
          vm.operationsByMeasureType[SLOGICS_SIMPLE_OPS_TYPE.FLOW_METER_SENSOR] = vm.meters.filter(
            (ms) =>
              ms.flowFormat ===
              vm.meters.find((ms) => +ms.pk.id === vm.logics[vm.page - 1].elements[0].elementId).flowFormat
          );
        }
      }
    }

    //preparar totes les queries i ficar el html corresppnent.
    function loadLogics() {
      /*         const params = new HttpParams()
              .set("active", false); */
      const params = { active: 'false' };
      showLoader();
      vm.logicsDataLoaded = false;
      return sensorsFactory
        .logicSensorsPage(vm.currentUnit.id, params)
        .then((data) => {
          vm.logics = data.plain();
          /*             data[vm.page-1].elements.forEach(elem => {
                        return setAndLoadAndOrData(elem.elementType);
                    }); */
          vm.sensor = vm.logics[vm.page - 1];
          vm.logicsValue = data.filter(
            (logic) =>
              logic.operation != SLOGICS_OPS.AND &&
              logic.operation != SLOGICS_OPS.OR &&
              logic.operation &&
              logic.pk.id !== vm.sensor.pk.id
          );
          vm.logicsDigital = data.filter(
            (logic) =>
              (logic.operation == SLOGICS_OPS.AND || logic.operation == SLOGICS_OPS.OR) &&
              logic.operation &&
              logic.pk.id !== vm.sensor.pk.id
          );

          vm.ioFacade.loadConfiguredOutputs({
            deviceId: vm.currentUnit.id,
            deviceType: UNITS_V2[vm.currentUnit.type],
          });
          vm.sensor = vm.logics[vm.page - 1];
          vm.sensor.elements = vm.sensor.elements.map((el, i) => ({
            ...el,
            active: !!el.elementId || i === 0 || i === 1,
          }));
          SLOGICS_SIMPLE_OPS_TYPE;

          if (
            vm.sensor.operation === SLOGICS_OPS.AVG ||
            vm.sensor.operation === SLOGICS_OPS.SUM ||
            vm.sensor.operation === SLOGICS_OPS.DIFF
          ) {
            vm.sensor.elements[1].elementType = vm.sensor.elements[0].elementType;
          }
          // Init StagingList in order to check the current io selector.
          vm.ioFacade.initializationStagingOutputs(vm.currentUnit.id, UNITS_V2[vm.currentUnit.type]);
          hideLoader();
          vm.logicsDataLoaded = true;
          vm.sensor.elements.forEach((el) => {
            getReferenceUnit(el);
          });
        })
        .catch((e) => {
          vm.logicsDataLoaded = true;
          throw e;
        });
    }

    function resetElement(index, checked) {
      const element = vm.logics[vm.page - 1].elements[index];
      vm.logics[vm.page - 1].elements[index] = {
        ...element,
        elementType: checked
          ? vm.logics[vm.page - 1].elements[0].elementType
            ? vm.logics[vm.page - 1].elements[0].elementType
            : 0
          : null,
        elementId: 0,
        condition: 0,
        reference: 0,
        differential: 0,
      };
    }

    function changeElementTypeAndOr($event, $index) {
      const elementType = $event.detail.value;
      vm.logics[vm.page - 1].elements[$index].reference = 0;
      vm.logics[vm.page - 1].elements[$index].condition = null;
      vm.logics[vm.page - 1].elements[$index].differential = 0;
      vm.logics[vm.page - 1].elements[$index].elementType = elementType;
      vm.logics[vm.page - 1].elements[$index].elementId = null;
    }

    function changeElementType($event) {
      vm.logics[vm.page - 1].elements.forEach((el) => {
        el.elementType = $event.detail.value;
        el.elementId = null;
        el.condition = null;
      });

      const elementType = $event.detail.value;
      if (elementType === 2) {
        vm.simpleOperationsData[SLOGICS_SIMPLE_OPS_TYPE.VALUE_LOGIC_SENSOR] = getFilterSLogicAnalog();
      }
    }

    function getReferenceUnit(element) {
      if (!vm.andOrDataByMeasureType) return;
      const sensor = vm.andOrDataByMeasureType[element.elementType]?.find(
        (sensor) => +sensor.pk.id === element.elementId
      );

      if (!sensor) {
        element.referenceFormat = {
          integers: '',
          decimals: '',
          symbol: '',
          disabled: true,
        };
        return;
      }

      if (element.elementType === SLOGICS_AND_OR_OPS_TYPE.ANALOG_SENSOR) {
        element.referenceFormat = {
          integers: sensor.format.integers,
          decimals: sensor.format.decimals,
          symbol: sensor.format.units45.symbol,
          disabled: false,
        };
        return;
      }

      if (element.elementType === SLOGICS_AND_OR_OPS_TYPE.FLOW_METER_SENSOR) {
        const unit = meterFormats.find((format) => format.deviceFormatKey === sensor.flowFormat);

        // We get the symbol by splitting string ex: 000.0 m3/h -> m3/h
        const formatUnit = unit.format.split(' ')[1];
        // We get the number by splitting string ex: 000.0 m3/h -> 000.0 to get number of decimals and integers
        const { integers, decimals } = getIntegersAndDecimals(unit.format.split(' ')[0]);

        element.referenceFormat = {
          integers,
          decimals,
          symbol: formatUnit,
          disabled: false,
        };
        return;
      }
      if (element.elementType === SLOGICS_AND_OR_OPS_TYPE.VALUE_LOGIC_SENSOR) {
        const selectedSensor = vm.logicsValue.find((ls) => +ls.pk.id === element.elementId);
        const referenceElement = selectedSensor.elements[0];
        if (!referenceElement.elementId) {
          element.referenceFormat = {
            integers: 3,
            decimals: 0,
            symbol: 'uds',
            disabled: false,
          };
          return;
        }
        element.referenceFormat = getElementReferenceFormat(referenceElement);

        return;
      }
    }

    function getElementReferenceFormat(sensor) {
      switch (sensor.elementType) {
        case SLOGICS_AND_OR_OPS_TYPE.ANALOG_SENSOR: {
          const as = vm.analogs.find((as) => +as.pk.id === +sensor.elementId);
          return {
            integers: as.format.integers,
            decimals: as.format.decimals,
            symbol: as.format.units45.symbol,
            disabled: false,
          };
        }
        case SLOGICS_AND_OR_OPS_TYPE.FLOW_METER_SENSOR: {
          const ms = vm.meters.find((ms) => +ms.pk.id === sensor.elementId);
          const unit = meterFormats.find((format) => format.deviceFormatKey === ms.flowFormat);

          // We get the symbol by splitting string ex: 000.0 m3/h -> m3/h
          const formatUnit = unit.format.split(' ')[1];
          // We get the number by splitting string ex: 000.0 m3/h -> 000.0 to get number of decimals and integers
          const { integers, decimals } = getIntegersAndDecimals(unit.format.split(' ')[0]);

          return {
            integers,
            decimals,
            symbol: formatUnit,
            disabled: false,
          };
        }
        case SLOGICS_AND_OR_OPS_TYPE.VALUE_LOGIC_SENSOR: {
          const ls = vm.logicsValue.find((ls) => +ls.pk.id === +sensor.elementId);
          return getElementReferenceFormat(ls.elements[0]);
        }
        default: {
          return {
            integers: 3,
            decimals: 0,
            symbol: 'uds',
            disabled: false,
          };
        }
      }
    }

    function getIntegersAndDecimals(format) {
      const splittedFormatByDot = format.split('.');

      if (splittedFormatByDot.length === 1) {
        return { integers: 4, decimals: 0 };
      } else {
        return { integers: splittedFormatByDot[0].length, decimals: splittedFormatByDot[1].length };
      }
    }

    function changeState(params) {
      vm.active = null;
      vm.sensor = vm.logics[params - 1];
      vm.sensor.elements.forEach((el) => {
        getReferenceUnit(el);
      });
    }

    function changingSensor($event) {
      if (vm.form45 && vm.form45.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('sensors.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form45.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeSensor($event);
          break;
      }
    }

    function setColor() {
      vm.pickerActive = false;
      switch (vm.sensor.caption.numLvl) {
        case 3:
          vm.sensor.caption.color1 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 60);
          vm.sensor.caption.color2 = vm.sensor.color.hex;
          vm.sensor.caption.color3 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -60);
          break;
        case 5:
          vm.sensor.caption.color1 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 60);
          vm.sensor.caption.color2 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 30);
          vm.sensor.caption.color3 = vm.sensor.color.hex;
          vm.sensor.caption.color4 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -30);
          vm.sensor.caption.color5 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -60);
          break;
      }
    }

    function lastSensor() {
      --vm.page;
      if (vm.page === 0) {
        vm.page = vm.paginator.totalPages;
      }
      loadLogics();
    }

    function nextSensor() {
      ++vm.page;
      if (vm.page > vm.paginator.totalPages) {
        vm.page = 1;
      }
      loadLogics();
    }

    function changeSensor(e) {
      if (e.detail.value) {
        vm.page = +e.detail.value;
        loadLogics();
        filterMeasureTypeByFormat();
      }
    }

    function save() {
      vm.loading = true;
      vm.sensor.unittype = vm.currentUnit.type;
      vm.form45.$setPristine();

      vm.ioFacade
        .validateOutputsToTheCurrentView()
        .pipe(
          switchMap((result) => {
            if (result || (vm.sensor.operation !== vm.SLOGICS_OPS.AND && vm.sensor.operation !== vm.SLOGICS_OPS.OR)) {
              return from(sensorsFactory.updateLogics(vm.currentUnit.id, vm.sensor));
            } else {
              return of(null);
            }
          })
        )
        .subscribe((response) => {
          if (response) {
            vm.sensor = { ...response.plain(), elements: vm.sensor.elements.sort((a, b) => +a.id - +b.id) };
            vm.logics[vm.page - 1] = vm.sensor;
          }
        });
    }

    function backup() {
      vm.sensors_backup = {};
      angular.copy(vm.sensor, vm.sensors_backup);
      vm.sensor.backup = true;
    }

    function cancel_edition() {
      //backup
      angular.copy(vm.sensors_backup, vm.sensor);
    }

    $scope.$on('refresh', function () {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form45,
      () => {
        $scope.$emit('formUpdated', vm.form45);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e) => {
      save(e, vm.form45);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
