import moment from 'moment/moment';
import Highcharts from 'highcharts/highcharts';
import { UNITS, UNITS_V2 } from '../utils/units.enum';
import {
  IOSelectorItemResponseOutputTypeEnum,
  IOSelectorItemResponseElementTypeEnum,
} from '../utils/enums/io-selector.enum';
import { A2500_DEFAULT_CONFIG } from '../utils/device-config';
import { from, of, Subject } from 'rxjs';

const colors = require('../../resources/vegga.tokens.colors.json');
import { getContainer } from '@vegga/front-store';
import { switchMap } from 'rxjs/operators';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)

    .controller('sectorsConfigController', sectorsConfigController);

  sectorsConfigController.$inject = [
    '$scope',
    '$state',
    '$filter',
    'UserData',
    'sectorFactory',
    'calculatedsFactory',
    'sensorsFactory',
    '$translate',
  ];

  function sectorsConfigController(
    $scope,
    $state,
    $filter,
    UserData,
    sectorFactory,
    calculatedsFactory,
    sensorsFactory,
    $translate
  ) {
    var vm = this;
    var curveDay;
    vm.activeList;
    vm.getSubprogramStartTime;
    vm.getValueInUnit;
    vm.expand;
    var activeSectors;
    var sectorPrograms;
    vm.curveDayErrorMessage;
    vm.cancel = cancel_edition;
    vm.save = save;
    vm.loadCropClass = loadCropClass;
    vm.loadCropFinal = loadCropFinal;
    vm.prepareFlowUnits = prepareFlowUnits;
    vm.prepareSensorList = prepareSensorList;
    vm.prepareSensorRainList = prepareSensorRainList;
    //vm.prepareSensorEtoList = prepareSensorEtoList;
    vm.selectSensor = selectSensor;
    vm.selectSensorRain = selectSensorRain;
    vm.selectSensorEto = selectSensorEto;
    vm.updateCurveDay = updateCurveDay;
    vm.loadKc = loadKc;
    vm.setChart = setChart;
    vm.showHide = showHide;
    vm.initWatch = initWatch;
    vm.loadCropClassAndfinal = loadCropClassAndfinal;
    vm.selectWaterDisp = selectWaterDisp;
    vm.selectSensorRain45 = selectSensorRain45;
    vm.updateCropGroupSelected = updateCropGroupSelected;
    vm.updateCropClassSelected = updateCropClassSelected;
    vm.updateCropSelected = updateCropSelected;

    vm.destroy$ = new Subject();
    vm.ioFacade = getContainer().resolve('ioFacade');

    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.UNITS = UNITS;
      vm.IOSelectorItemResponseOutputTypeEnum = IOSelectorItemResponseOutputTypeEnum;
      vm.IOSelectorItemResponseElementTypeEnum = IOSelectorItemResponseElementTypeEnum;

      if ($state.params.sector !== null) {
        vm.sector = $state.params.sector;

        vm.sector = { ...vm.sector, outputAux: vm.sector.outputAux ?? null };
        vm.currentUnit = $state.params.unit;
        vm.profesional = UserData.profesional;
        activeSectors = $scope.$parent.vm.activeList;

        prepareSensorEtoList();
        prepareSensorList();
        prepareSensorRainList();
        vm.isCropGroupSet = false;
        vm.isCropClassSet = false;
        vm.isCropFinalSet = false;

        if (vm.currentUnit.type === vm.UNITS.A_2500 || vm.currentUnit.type === UNITS.A_BIT) {
          prepareFlowUnits();
        }
        if (vm.currentUnit.type !== vm.UNITS.A_7000 && vm.currentUnit.type !== vm.UNITS.A_4500) {
          curveDay = vm.sector.curveDay;

          checkUnitVersion();
          if (vm.sector.crop !== 0) {
            loadSetCrop();
          } else {
            loadCrops();
          }
          loadWaterDisp();
          loadEffectiveRain();
          loadEto();
        } else if (vm.currentUnit.type === vm.UNITS.A_4500) {
          vm.ioFacade.loadConfiguredOutputs({
            deviceId: vm.currentUnit.id,
            deviceType: UNITS_V2[vm.currentUnit.type],
          });

          // Init StagingList in order to check the current io selector.
          vm.ioFacade.initializationStagingOutputs(vm.currentUnit.id, UNITS_V2[vm.currentUnit.type]);
          if (vm.sector.crop !== 0) {
            loadSetCrop();
          } else {
            loadCrops();
          }
        }

        backup();

        if (vm.currentUnit.type === vm.UNITS.A_4500) {
          vm.digitalDetectorType = [
            {
              value: 0,
              text: $filter('translate')('sectors.digitalDetector'),
            },
            {
              value: 1,
              text: $filter('translate')('sectors.auxMeter'),
            },
          ];

          vm.headerList = [
            {
              value: 1,
              text: $filter('translate')('sectors.header') + ' ' + 1,
            },
            {
              value: 2,
              text: $filter('translate')('sectors.header') + ' ' + 2,
            },
            {
              value: 3,
              text: $filter('translate')('sectors.header') + ' ' + 3,
            },
            {
              value: 4,
              text: $filter('translate')('sectors.header') + ' ' + 4,
            },
          ];

          vm.formatList = [
            {
              value: 1,
              text: $filter('translate')('sectors.meterFormat') + ' ' + 1,
            },
            {
              value: 2,
              text: $filter('translate')('sectors.meterFormat') + ' ' + 2,
            },
            {
              value: 3,
              text: $filter('translate')('sectors.meterFormat') + ' ' + 3,
            },
            {
              value: 4,
              text: $filter('translate')('sectors.meterFormat') + ' ' + 4,
            },
          ];

          getAllMeters(vm.currentUnit.id);
        }
      } else {
        $state.go('units');
      }
    }

    function getAllMeters(deviceId) {
      return sensorsFactory.meters(deviceId).then((result) => (vm.meters = result.plain()));
    }

    function langConverter(lang) {
      switch (lang) {
        case 'es':
          return 1;
        case 'it':
          return 4;
        case 'en':
          return 2;
        case 'fr':
          return 3;
        case 'pt':
          return 5;
        default:
          return 1;
      }
    }

    function loadSetCrop() {
      var input = vm.sector.crop !== null ? vm.sector.crop.toString() : [];
      var group, classcrop, crop;
      vm.langFilter = {
        pk: {
          idIdioma: langConverter($translate.use()),
        },
      };

      switch (input.length) {
        case 5:
          group = input.slice(0, 1);
          classcrop = input.slice(1, 3);
          crop = input.slice(3, 5);
          break;
        case 6:
          group = input.slice(0, 2);
          classcrop = input.slice(2, 4);
          crop = input.slice(4, 6);
          break;
        default:
          group = 0;
          classcrop = 0;
          crop = 0;
          break;
      }

      sectorFactory.cropsGroupText().then((response) => {
        vm.cropGroup = response.plain();
        vm.cropGroupSelected = Number(group);
        vm.isCropGroupSet = true;
      });
      sectorFactory.cropsClassText(group).then((response) => {
        vm.cropClass = response.plain();
        vm.cropClassSelected = Number(classcrop);
        vm.isCropClassSet = true;
      });
      sectorFactory.cropsTextFinal(group, classcrop).then((response) => {
        vm.cropFinal = response.plain();
        vm.isCropFinalSet = true;

        loadKc();
    
      });
    }

    function checkUnitVersion() {
      switch (vm.currentUnit.type) {
        case UNITS.A_2500:
          vm.max = vm.currentUnit.version <= 215;
          break;
        case UNITS.A_BIT:
          vm.max = vm.currentUnit.version <= 171;
          break;
        case UNITS.A_5500:
          vm.max = vm.currentUnit.version <= 35;
          break;
      }
    }

    function prepareFlowUnits() {
      sensorsFactory.meters(vm.currentUnit.id).then((result) => {
        vm.currentUnit.meters = result.plain();

        var a25FlowUnits = A2500_DEFAULT_CONFIG.measurementUnits.flow;
        var flowFormat = 0;
        if (vm.sector.irrigationMeter !== 0) {
          flowFormat = vm.currentUnit.meters[vm.sector.irrigationMeter].flowFormat;
        }
        vm.flowFormat = a25FlowUnits[flowFormat].measurementUnit.symbol;
      });
    }

    function loadCrops() {
      vm.langFilter = {
        pk: {
          idIdioma: langConverter($translate.use()),
        },
      };

      sectorFactory.cropsGroupText().then((response) => {
        vm.cropGroup = response.plain();
        vm.cropClass = [];
        vm.cropFinal = [];

        vm.isCropGroupSet = true;
      });
    }

    function updateCropGroupSelected($event, form) {
      form.$setDirty();
      // After fixing dropdown behaviour on $event === undefined, this line will not be longer necessary
      if ($event) {
        vm.cropGroupSelected = $event.detail.value ?? null;
      }
      loadCropClass();
    }

    function updateCropClassSelected($event, form) {
      form.$setDirty();
      // After fixing dropdown behaviour on $event === undefined, this line will not be longer necessary
      vm.cropClassSelected = $event ? $event.detail.value : null;
      loadCropFinal();
    }

    function updateCropSelected($event, form) {
      form.$setDirty();
      // After fixing dropdown behaviour on $event === undefined, this line will not be longer necessary
      vm.sector.crop = $event ? $event.detail.value : null;
      loadKc();
    }

    function loadCropClass() {
      vm.sector.crop = null;
      vm.kcValue = null;
      vm.isCropClassSet = false;
      sectorFactory.cropsClassText(vm.cropGroupSelected).then((response) => {
        vm.cropClass = response.plain();
        vm.cropFinal = [];

        vm.cropClassSelected = undefined;
        vm.sector.crop = undefined;
        vm.isCropClassSet = true;
        loadCropFinal();
      });
    }

    function loadCropFinal() {
      vm.sector.crop = null;
      vm.kcValue = null;
      vm.isCropFinalSet = false;
      vm.showCurveInput = false;
      sectorFactory.cropsTextFinal(vm.cropGroupSelected, vm.cropClassSelected).then((response) => {
        vm.cropFinal = response.plain();
        if (vm.cropFinal.length >= 1) {
          vm.sector.crop = undefined;
          vm.isCropFinalSet = true;
        
          loadKc();
    
        }
      });
    }

    function loadCropClassAndfinal() {
      vm.kcValue = null;
      vm.isCropFinalSet = false;
      vm.isCropClassSet = false;
      sectorFactory.cropsClassText(vm.cropGroupSelected).then((response) => {
        vm.cropClass = response.plain();
        vm.cropFinal = [];
        vm.cropClassSelected = vm.cropClass[0].pk.id;
        vm.isCropClassSet = true;

        vm.loadCropFinal();
      });
    }

    function loadWaterDisp() {
      var user;
      var id;
      vm.selectedName = null;
      vm.otherUser = false;
      vm.otherUser = vm.sector.waterDispUser !== null && UserData.id !== vm.sector.waterDispUser;
      if (vm.sector.waterDisp !== null) {
        id = vm.sector.waterDisp;
        calculatedsFactory.waterDisp(vm.sector.waterDispUser, { page: id, limit: 1 }).then((sensors) => {
          if (!sensors) {
            return;
          }

          var content = sensors.plain();
          if (content.length > 0) {
            var sensor = _.find(content, (s) => {
              return s.pk.id === id;
            });
            if (sensor !== undefined) {
              vm.selectedName = sensor.name;
            } else {
              vm.selectedName = '-';
            }
          }
        });
      }
    }

    function loadEffectiveRain() {
      var user;
      var id;
      vm.selectedNameRain = null;
      vm.otherUserRain = false;
      if (vm.sector.effectiveRainUser !== null && UserData.id !== vm.sector.effectiveRainUser) {
        vm.otherUserRain = true;
        user = vm.sector.effectiveRainUser;
      } else {
        vm.otherUserRain = false;
        user = UserData.id;
      }
      if (vm.sector.effectiveRain !== null) {
        id = vm.sector.effectiveRain;
        calculatedsFactory.effectiveRainById(user, id).then((sensors) => {
          if (!sensors) {
            return;
          }

          var content = sensors.plain();
          if (content) {
            vm.selectedNameRain = content.name;
          }
        });
      }
    }

    function loadEto() {
      var user;
      var id;
      vm.selectedNameEto = null;
      vm.otherUserEto = false;
      if (vm.sector.idUserEto !== null && UserData.id !== vm.sector.idUserEto) {
        vm.otherUserEto = true;
      } else {
        vm.otherUserEto = false;
      }
      if (vm.sector.idEto !== null) {
        id = vm.sector.idEto;
        calculatedsFactory.getETO(vm.sector.idUserEto).then((sensors) => {
          var content = sensors.plain();
          if (content.length > 0) {
            var sensor = _.find(content, (s) => {
              return s.pk.id === id;
            });
            if (sensor !== undefined) {
              vm.selectedNameEto = sensor.name;
            } else {
              vm.selectedNameEto = '-';
            }
          }
        });
      }
    }

    function prepareSensorList() {
      vm.loading = true;
      calculatedsFactory.waterDisp(UserData.id).then((sensors) => {
        vm.waterSensors = sensors.plain();
        vm.loading = false;
      });
    }

    function prepareSensorRainList() {
      vm.loadingRain = true;
      calculatedsFactory.effectiveRain(UserData.id).then((sensors) => {
        vm.rainSensors = sensors.plain();
        vm.loadingRain = false;
      });
    }

    function prepareSensorEtoList() {
      vm.loading = true;
      calculatedsFactory.getETO(UserData.id).then((sensors) => {
        vm.etoSensors = sensors.plain();
        vm.loading = false;
      });
    }

    function selectSensor() {
      vm.otherUser = false;
      if (vm.sector.waterDisp === null) {
        vm.selectedName = ' - ';
        vm.sector.waterDisp = null;
        vm.sector.waterDispUser = null;
      } else {
        const sensorWater = vm.waterSensors.find((water) => water.pk.id === vm.sector.waterDisp);
        if (sensorWater) {
          vm.selectedName = sensorWater.name;
          vm.sector.waterDisp = sensorWater.pk.id;
          vm.sector.waterDispUser = sensorWater.pk.userId;
        }
      }

      if (vm.form25 !== undefined) vm.form25.$setDirty();
      if (vm.form !== undefined) vm.form.$setDirty();
      if (vm.form4 !== undefined) vm.form4.$setDirty();
      if (vm.form55 !== undefined) vm.form55.$setDirty();
    }

    function selectSensorRain() {
      vm.otherUserRain = false;
      if (vm.sector.effectiveRain === null) {
        vm.selectedNameRain = ' - ';
        vm.sector.effectiveRain = null;
        vm.sector.effectiveRainUser = null;
      } else {
        const sensorRain = vm.rainSensors.find((eto) => eto.pk.id === vm.sector.effectiveRain);
        if (sensorRain) {
          vm.selectedNameRain = sensorRain.name;
          vm.sector.effectiveRain = sensorRain.pk.id;
          vm.sector.effectiveRainUser = sensorRain.pk.userId;
        }
      }
      if (vm.form25 !== undefined) vm.form25.$setDirty();
      if (vm.form !== undefined) vm.form.$setDirty();
      if (vm.form4 !== undefined) vm.form4.$setDirty();
      if (vm.form55 !== undefined) vm.form55.$setDirty();
    }

    function selectSensorEto() {
      vm.otherUserEto = false;
      if (vm.sector.idEto === null) {
        vm.selectedNameEto = ' - ';
        vm.sector.idEto = null;
        vm.sector.idUserEto = null;
      } else {
        const etoSensor = vm.etoSensors.find((eto) => eto.pk.id === vm.sector.idEto);
        if (etoSensor) {
          vm.selectedNameEto = etoSensor.name;
          vm.sector.idEto = etoSensor.pk.id;
          vm.sector.idUserEto = etoSensor.pk.userId;
        }
      }
      if (vm.form25 !== undefined) vm.form25.$setDirty();
      if (vm.form !== undefined) vm.form.$setDirty();
      if (vm.form4 !== undefined) vm.form4.$setDirty();
      if (vm.form55 !== undefined) vm.form55.$setDirty();
      if (vm.form45 !== undefined) vm.form45.$setDirty();
    }

    function loadKc() {
      let crop = _.find(vm.cropFinal, (data) => {
        if (data.pk.id == vm.sector.crop) {
          return data;
        }
      });

      if (crop !== undefined && crop.kc.length > 0) {
        if (UserData.usermanager !== null) {
          vm.sector.idUserCrop = UserData.usermanager.id;
        } else {
          vm.sector.idUserCrop = UserData.id;
        }
        let kc = crop.kc[0];
        if (kc.type === 0) {
          //Kc fija
          vm.kcValue = kc.fixedKc;
          vm.kcCurve = null;
          vm.showcurve = false;
        } else {
          let curve = [];
          let tmp1 = kc.curveKc.split(';');
          _.forEach(tmp1, (a) => {
            curve.push(_.zipObject(['x', 'y'], a.split(',').map(Number)));
          });
          vm.kcCurve = curve;

          vm.showCurveInput = true;

          updateKc();
        }
        vm.setChart();
      } else {
        vm.showcurve = false;
      }
    }

    function updateCurveDay() {
      if (vm.sector.curveDay < 0 || vm.sector.curveDay > vm.maxDayInCurveValue) {
        vm.curveDayErrorMessage = $filter('translate')('sectors.error.curve_day_invalid_value');
        vm.showcurve = false;
      } else {
        vm.curveDayErrorMessage = '';
      }
      vm.sector.curveDayUpdate = moment().utc()._d;
      updateKc();
      setChart();
    }

    function updateKc() {
      let x = vm.sector.curveDay;
      if (x <= _.first(vm.kcCurve).x) {
        vm.kcValue = _.first(vm.kcCurve).y;
      } else if (x >= _.last(vm.kcCurve).x) {
        vm.kcValue = _.last(vm.kcCurve).y;
      } else {
        let x1, y1;
        _.forEach(vm.kcCurve, (r) => {
          if (x <= r.x) {
            if (x === r.x) {
              vm.kcValue = r.y;
            } else {
              let m = (r.y - y1) / (r.x - x1);
              vm.kcValue = (m * (x - x1) + y1).toFixed(2);
              return false;
            }
          } else {
            x1 = r.x;
            y1 = r.y;
          }
        });
      }
    }

    function setChart() {
      if (vm.kcCurve) {
        const lastDayInCurve = vm.kcCurve[vm.kcCurve.length - 1].x;
        vm.maxDayInCurveValue = lastDayInCurve;
      }

      let id = 'kc-curve';
      if (vm.kcCurve !== null) {
        let chart = new Highcharts.Chart({
          chart: {
            renderTo: id,
            //margin: [-25, 0, 40, 0],
            reflow: false,
            style: {
              overflow: 'visible',
            },
          },
          title: {
            text: '',
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: false,
          },
          xAxis: {
            plotLines: [
              {
                color: colors['vegga-color-error'], // Color value
                value: vm.sector.curveDay, // Value of where the line will appear
                width: 1, // Width of the line
                label: {
                  text: vm.kcValue, // Content of the label.
                  verticalAlign: 'middle',
                  rotation: 0,
                },
              },
            ],
          },
          yAxis: {
            title: [
              {
                text: undefined,
              },
            ],
          },
          exporting: {
            enabled: false,
          },
          series: [
            {
              type: 'line',
              name: 'Kc',
              data: vm.kcCurve,
              color: colors['vegga-color-primary-default'],
            },
          ],
        });
      }
    }

    function showHide() {
      if (vm.expand === true) vm.expand = false;
      else vm.expand = true;
    }

    function backup() {
      vm.sector_backup = {};
      angular.copy(vm.sector, vm.sector_backup);
      vm.sector.backup = true;
    }

    function cancel_edition() {
      angular.copy(vm.sector_backup, vm.sector);
      //$state.go('sectors.detail',{unit:vm.currentUnit,sector:null});
    }

    function save() {
      if (vm.form25 !== undefined) vm.form25.$setPristine();
      if (vm.form4 !== undefined) vm.form4.$setPristine();
      if (vm.form55 !== undefined) vm.form55.$setPristine();
      if (vm.form7 !== undefined) vm.form7.$setPristine();
      if (vm.form !== undefined) vm.form.$setPristine();
      if (vm.form45 !== undefined) vm.form45.$setPristine();
      vm.sector.unittype = vm.currentUnit.type;

      switch (vm.currentUnit.type) {
        case UNITS.A_4500:
          vm.ioFacade
            .validateOutputsToTheCurrentView()
            .pipe(
              switchMap((result) => {
                if (result) {
                  return from(sectorFactory.update(vm.sector));
                } else {
                  return of(null);
                }
              })
            )
            .subscribe((response) => {
              if (response) {
                vm.sector = response;
                angular.copy(vm.sector, vm.sector_backup);
                vm.sector.backup = true;
              }
            });
          break;
        default:
          sectorFactory.update(vm.sector).then((response) => {
            vm.sector = response;

            angular.copy(vm.sector, vm.sector_backup);
            vm.sector.backup = true;
          });
          break;
      }
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    function initWatch() {
      if (vm.form25 !== undefined) {
        $scope.$watch(
          () => vm.form25,
          () => {
            $scope.$emit('formUpdated', vm.form25);
          }
        );
      }
      if (vm.form4 !== undefined) {
        $scope.$watch(
          () => vm.form4,
          () => {
            $scope.$emit('formUpdated', vm.form4);
          }
        );
      }
      if (vm.form55 !== undefined) {
        $scope.$watch(
          () => vm.form55,
          () => {
            $scope.$emit('formUpdated', vm.form55);
          }
        );
      }
      if (vm.form7 !== undefined) {
        $scope.$watch(
          () => vm.form7,
          () => {
            $scope.$emit('formUpdated', vm.form7);
          }
        );
      }
      if (vm.form !== undefined) {
        $scope.$watch(
          () => vm.form,
          () => {
            $scope.$emit('formUpdated', vm.form);
          }
        );
      }
      if (vm.form45 !== undefined) {
        $scope.$watch(
          () => vm.form45,
          () => {
            $scope.$emit('formUpdated', vm.form45);
          }
        );
      }
    }

    function selectWaterDisp() {
      vm.otherUser = false;
      if (vm.sector.waterDisp === null) {
        vm.selectedName = ' - ';
        vm.sector.idAvailableWater = null;
        vm.sector.iidKUserAvailableWater = null;
      } else {
        const sensorWater = vm.waterSensors.find((water) => water.pk.id === vm.sector.idAvailableWater);
        if (sensorWater) {
          vm.selectedName = sensorWater.name;
          vm.sector.idAvailableWater = sensorWater.pk.id;
          vm.sector.iidKUserAvailableWater = sensorWater.pk.userId;
        }
      }
      if (vm.form45 !== undefined) vm.form45.$setDirty();
    }

    function selectSensorRain45() {
      vm.otherUserRain = false;
      if (vm.sector.idEffectiveRain === null) {
        vm.selectedNameRain = ' - ';
        vm.sector.idEffectiveRain = null;
        vm.sector.idUserEffectiveRain = null;
      } else {
        const sensorRain = vm.rainSensors.find((eto) => eto.pk.id === vm.sector.idEffectiveRain);
        if (sensorRain) {
          vm.selectedNameRain = sensorRain.name;
          vm.sector.idEffectiveRain = sensorRain.pk.id;
          vm.sector.idUserEffectiveRain = sensorRain.pk.userId;
        }
      }
      if (vm.form45 !== undefined) vm.form45.$setDirty();
    }

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('lang-update', (ev, args) => {
      vm.langFilter.pk.idIdioma = langConverter(args.lang);
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();