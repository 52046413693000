import moment from 'moment/moment';
import { VeggaOverlayTab } from '../../utils/vegga-overlay-tab.enum';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getContainer } from '@vegga/front-store';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('calcsWaterHistoryController', calcsWaterHistoryController);

  calcsWaterHistoryController.$inject = [
    '$scope',
    '$state',
    'UserData',
    'calculatedsFactory',
    'sensorsFactory',
    '$q',
    '$filter',
    '$confirm',
    'unitFactory',
  ];

  function calcsWaterHistoryController(
    $scope,
    $state,
    UserData,
    calculatedsFactory,
    sensorsFactory,
    $q,
    $filter,
    $confirm,
    unitFactory
  ) {
    var vm = this;
    vm.activeList;
    var unitsLoaded;
    var globalIndex;
    var from;
    var to;
    var typeStoredId;
    vm.from;
    vm.to;
    vm.slider;
    vm.VeggaOverlayTab = VeggaOverlayTab;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();
    activate();

    function activate() {
      vm.currentState = $state.includes;

      vm.devicesFacade.devicesICMResponse.clear();

      vm.devicesFacade.loadICMDevices(UserData.id, true);
      vm.devicesFacade.devicesICMResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((units) => {
        vm.units = units;
        vm.updateTypeChart = updateTypeChart;
        vm.selectSensor = selectSensor;
        vm.validateDates = validateDates;
        vm.typeChart = '0';
        vm.from = moment().subtract(29, 'day').format('DD-MM-yyyy');
        vm.to = moment().add(1, 'day').format('DD-MM-yyyy');
        typeStoredId = UserData.id + 'typeStored';
        validateDates();
        vm.changeWater = changeWater;
        vm.changingWater = changingWater;
        vm.nextOrPreviousItem = nextOrPreviousItem;
        vm.openDetail = openDetail;
        vm.changeTab = changeTab;
        vm.addNewSensor = addNewSensor;
        vm.deleteWater = deleteWater;
        vm.cancel = cancel;
        vm.saveWater = saveWater;
        vm.updateRangeDates = updateRangeDates;
        if ($state.$current.name === 'agroclimatic.water.config') {
          $state.go('agroclimatic.water');
        }

        if ($state.params.sensor) {
          vm.awList = $state.params.list;
          loadAW($state.params.sensor);
        } else {
          loadAWList();
        }
      });
      $scope.$emit('stateChanged');
      Chart.Tooltip.positioners.custom = function (elements, eventPosition) {
        /** @type {Chart.Tooltip} */
        var tooltip = this;

        /* ... */

        return {
          x: eventPosition.x,
          y: eventPosition.y,
        };
      };
    }

    function initSlider() {
      if (!vm.currentWaterSelected) return;
      var id = vm.currentWaterSelected.pk.userId + '-' + vm.currentWaterSelected.pk.id;
      var stored_slider = calculatedsFactory.getFilterStorage(id);

      vm.slider = {
        minValue: 0,
        maxValue: 140,
        options: {
          hideLimitLabels: true,
          floor: 0,
          ceil: 140,
          vertical: true,
          translate: function (value) {
            return value + '%';
          },
          onChange: function () {
            vm.lineChart.config.options.scales.yAxes[0].ticks.min = vm.slider.minValue;
            vm.lineChart.config.options.scales.yAxes[0].ticks.max = vm.slider.maxValue;
            stored_slider = {
              min: vm.slider.minValue,
              max: vm.slider.maxValue,
            };
            calculatedsFactory.setFilterStorage(stored_slider, id);
            vm.lineChart.update();
          },
        },
      };
      if (stored_slider !== null) {
        vm.slider.minValue = stored_slider.min;
        vm.slider.maxValue = stored_slider.max;
      }
    }

    function loadAWList() {
      calculatedsFactory.waterDisp(UserData.id).then((sensors) => {
        vm.awList = sensors.plain();
        //loadAW(vm.awList[0]);
      });
    }

    function loadAW(sensor) {
      initSlider();
      var type = calculatedsFactory.getFilterStorage(typeStoredId);
      if (type !== null) {
        vm.typeChart = type;
        switch (vm.typeChart) {
          case '0':
            loadHistory();
            break;
          case '1':
            loadHistoryAll();
            break;
        }
      } else {
        loadHistory();
      }
    }

    function updateTypeChart() {
      from = moment(vm.from);
      to = moment(vm.to);
      initSlider();
      calculatedsFactory.setFilterStorage(vm.typeChart, typeStoredId);
      switch (vm.typeChart) {
        case '0':
          loadHistory();
          break;
        case '1':
          loadHistoryAll();
          break;
      }
    }

    function loadHistoryAll() {
      vm.showslider = false;
      var querys = [];
      if (!vm.currentWaterSelected) return;
      _.forEach(_.uniqBy(vm.currentWaterSelected.sensors, 'pk.id'), (sensor) => {
        if (sensor.showGrafic !== null && sensor.showGrafic) {
          querys.push(
            calculatedsFactory.waterHistory(
              vm.currentWaterSelected.pk.userId,
              vm.currentWaterSelected.pk.id,
              sensor.pk.id,
              { from: vm.from, to: vm.to }
            )
          );
        }
      });
      if (querys.length > 0) {
        $q.all(querys).then((data) => {
          prepareSensor(data, true);
        });
      } else {
        prepareSensor([], false);
      }
    }

    function loadHistory() {
      if (!vm.currentWaterSelected) return;
      calculatedsFactory
        .waterHistory(vm.currentWaterSelected.pk.userId, vm.currentWaterSelected.pk.id, 0, {
          from: vm.from,
          to: vm.to,
        })
        .then((data) => {
          prepareSensor(data);
        });
    }

    function getChartData(array, multiple) {
      var dataset = [];
      var colors = ['#0e3f39', '#00a99d', '#a3efe2', '#00ff00', '#e6e600', '#ffcc00', '#ff8080'];
      if (multiple) {
        var first_element = [];
        _.forEach(array, (data) => {
          first_element.push(data[0]);
        });

        first_element = _.sortBy(first_element, (o) => {
          if (o !== undefined) return o[1];
        });
        if (first_element[0] != null) {
          var date_init = first_element[0][1];
          _.forEach(array, (data, k) => {
            var temp = [];
            temp.push({ x: moment(date_init, 'DD-MM-YYYY HH:mm').subtract(10, 'minutes'), y: null });
            _.forEach(data, (final) => {
              temp.push({ x: moment(final[1], 'DD-MM-YYYY HH:mm'), y: final[0] });
            });
            dataset[k] = {
              label: vm.currentWaterSelected.sensors[k].name + ': ',
              data: temp,
              borderColor: colors[k],
              backgroundColor: colors[k],
              borderWidth: 2,
            };
          });
          return {
            datasets: dataset,
          };
        }
      } else {
        vm.labels = _.map(array, (o) => {
          if (o[1] !== undefined) {
            return moment(o[1], 'DD-MM-YYYY HH:mm');
          }
        });
        vm.data = _.map(array, (o) => {
          if (o[0] !== undefined) {
            return o[0];
          }
        });
        return {
          labels: vm.labels,
          datasets: [
            {
              label: $filter('translate')('calcs.avg') + ': ',
              data: vm.data,
              borderColor: '#00A89B',
              backgroundColor: '#00A89B',
              borderWidth: 2,
            },
          ],
        };
      }
    }

    function selectSensor(sensor) {
      if (vm.currentTab === VeggaOverlayTab.NEW) return;
      switch (Number(vm.typeChart)) {
        case 0:
          loadAW(sensor);
          break;
        case 1:
          initSlider();
          loadHistoryAll();
          break;
      }
    }

    function updateRangeDates($event) {
      [vm.from, vm.to] = $event.detail;
      validateDates();
    }

    function validateDates() {
      var min = moment(vm.from);
      var max = moment(vm.to);
      var diff = min.diff(max, 'days');

      switch (Number(vm.typeChart)) {
        case 0:
          //vm.max_date = min.add(14,'day')._d;
          if (diff < -366) {
            vm.to = min.add(365, 'day').format('DD-MM-yyyy');
          }
          break;
        case 1:
          //vm.min_date = max.subtract(14,'day')._d;
          if (diff < -30) {
            vm.from = max.subtract(29, 'day').format('DD-MM-yyyy');
          }
          break;
      }
    }

    function prepareSensor(data, multiple) {
      var chartData = getChartData(data, multiple);
      var cicle = vm.currentWaterSelected.cicles[0];

      var ctx = document.getElementById('base').getContext('2d');
      if (vm.lineChart) {
        vm.lineChart.destroy();
      }

      var annotations = [
        {
          type: 'box',
          yScaleID: 'y-axis-0',
          yMin: 100,
          yMax: 140,
          borderColor: 'rgb(0,187,222,0.75)',
          borderWidth: 1,
          backgroundColor: 'rgb(0,187,222,0.1)',
        },
        {
          type: 'box',
          yScaleID: 'y-axis-0',
          yMin: cicle?.recargaPoint || 50,
          yMax: 100,
          borderColor: 'rgb(0,169,159,0.75)',
          borderWidth: 1,
          backgroundColor: 'rgb(0,169,159,0.1)',
        },
        {
          type: 'box',
          yScaleID: 'y-axis-0',
          yMin: cicle?.estresPoint || 15,
          yMax: cicle?.recargaPoint || 50,
          borderColor: 'rgb(255,146,100,0.75)',
          borderWidth: 1,
          backgroundColor: 'rgb(255,146,100,0.1)',
        },
        {
          type: 'box',
          yScaleID: 'y-axis-0',
          yMin: 0,
          yMax: cicle?.estresPoint || 15,
          borderColor: 'rgb(255,72,75,0.75)',
          borderWidth: 1,
          backgroundColor: 'rgb(255,72,75,0.1)',
        },
      ];

      if (cicle && cicle.line1) {
        annotations.push({
          drawTime: 'afterDatasetsDraw',
          id: 'hline',
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: cicle.line1,
          borderColor: 'black',
          borderWidth: 2,
          borderDash: [2, 4],
          borderDashOffset: 5,
          label: {
            backgroundColor: '#FFC72C',
            content: 'M1',
            enabled: true,
          },
        });
      }
      if (cicle && cicle.line2) {
        annotations.push({
          drawTime: 'afterDatasetsDraw',
          id: 'hline2',
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: cicle.line2,
          borderColor: 'black',
          borderWidth: 2,
          borderDash: [2, 4],
          borderDashOffset: 5,
          label: {
            backgroundColor: '#FFC72C',
            content: 'M2',
            enabled: true,
          },
        });
      }
      vm.lineChart = new Chart(ctx, {
        type: 'line',
        data: chartData,
        options: {
          spanGaps: false,
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  beginAtZero: true,
                  min: vm.slider.minValue,
                  max: vm.slider.maxValue,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: true,
                },
                scaleLabel: {
                  display: false,
                  labelString: '',
                },
                type: 'time',
                time: {
                  isoWeekday: true,
                  unit: 'hour',
                  tooltipFormat: 'DD/MM HH:mm',
                  displayFormats: {
                    day: 'DD/MM',
                    hour: 'DD/MM HH:mm',
                  },
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 40,
                  source: 'auto',
                },
              },
            ],
          },
          zoom: {
            enabled: true,
            drag: true,
            mode: 'x',
            limits: {
              //max: 10,
              //min: 0.5
            },
          },
          hover: {
            animationDuration: 0,
          },
          elements: {
            point: {
              pointStyle: 'circle',
              radius: 0,
              hitRadius: 5,
            },
            line: {
              fill: false,
              tension: 0.5,
            },
          },
          annotation: {
            annotations: annotations,
          },
          tooltips: {
            mode: 'x',
            intersect: false,
            //position: 'custom',
            filter: function (tooltipItem, data) {
              return tooltipItem.x === globalIndex;
            },
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                label += Math.round(tooltipItem.yLabel * 100) / 100;
                return tooltipItem.xLabel + ' - ' + label + '%';
              },
              title: function (tooltipItem, data) {},
            },
          },
          customLine: {
            color: 'black',
          },
        },
        plugins: [
          {
            beforeEvent: function (chart, e) {
              if (e.type === 'mousemove' && e.x >= e.chart.chartArea.left && e.x <= e.chart.chartArea.right) {
                chart.options.customLine.x = e.x;
                chart.chart_element = chart.getElementsAtXAxis(e)[0];
                if (chart.getElementsAtXAxis(e)[0]) {
                  globalIndex = chart.getElementsAtXAxis(e)[0]._model.x;
                }
              }
            },
            afterDraw: function (chart, easing) {
              var ctx = chart.chart.ctx;
              var chartArea = chart.chartArea;
              var x = chart.options.customLine.x;
              if (!isNaN(x)) {
                ctx.save();
                ctx.beginPath();
                ctx.strokeStyle = chart.options.customLine.color;
                ctx.moveTo(chart.options.customLine.x, chartArea.bottom);
                ctx.lineTo(chart.options.customLine.x, chartArea.top);
                ctx.stroke();
                ctx.restore();
              }
            },
          },
        ],
      });

      vm.showslider = true;
    }

    function changeWater(e) {
      if (e.detail.value) {
        vm.currentWaterSelected = e.detail.value;

        if (vm.currentTab === VeggaOverlayTab.EDIT) {
          $state.go('agroclimatic.water');
        }
        vm.currentTab = VeggaOverlayTab.DETAIL;

        setTimeout(() => {
          selectSensor(vm.currentWaterSelected);
        }, 10);
      }
    }

    function changingWater($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          //cancelEdition();
          $scope.$broadcast('cancelEditWater');
          nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeWater($event);
          break;
      }
    }

    function openDetail(sensor) {
      vm.currentWaterSelected = sensor;
      vm.currentTab = VeggaOverlayTab.DETAIL;
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
    }

    function changeTab(tab, $event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          vm.currentTab = tab;
          if (tab === VeggaOverlayTab.EDIT) {
            $state.go('agroclimatic.water.config', {
              units: vm.units,
              sensor: vm.currentWaterSelected,
              list: vm.awList,
            });
          }

          if (tab === VeggaOverlayTab.DETAIL) {
            $scope.$broadcast('cancelEditWater');
            setTimeout(() => {
              selectSensor(vm.currentWaterSelected);
            }, 10);
            $state.go('agroclimatic.water');
          }
        });
      } else {
        vm.currentTab = tab;
        if (tab === VeggaOverlayTab.EDIT) {
          $state.go('agroclimatic.water.config', { units: vm.units, sensor: vm.currentWaterSelected, list: vm.awList });
        }

        if (tab === VeggaOverlayTab.DETAIL) {
          $scope.$broadcast('cancelEditWater');
          setTimeout(() => {
            selectSensor(vm.currentWaterSelected);
          }, 10);
          $state.go('agroclimatic.water');
        }
      }
    }

    function addNewSensor() {
      calculatedsFactory.newWater(UserData.id).then((data) => {
        const newSensor = data
          .plain()
          .filter((water) => !vm.awList.some((secondWater) => water.pk.id === secondWater.pk.id));
        vm.awList = data.plain();
        if (newSensor && newSensor.length === 1) {
          vm.currentWaterSelected = newSensor[0];

          if (vm.currentWaterSelected?.cicles?.length > 0) {
            vm.currentWaterSelected.cicles[0].line1 = 0;
            vm.currentWaterSelected.cicles[0].line2 = 0;
            vm.currentTab = VeggaOverlayTab.NEW;
          }

          const veggaOverlay = document.querySelector('vegga-overlay');
          veggaOverlay.show();
          $state.go('agroclimatic.water.config', { units: vm.units, sensor: vm.currentWaterSelected, list: vm.awList });
        }
      });
    }

    function deleteWater() {
      const selected = vm.currentWaterSelected;
      if (vm.form) {
        vm.form.$setPristine();
      }
      calculatedsFactory.deleteWater(UserData.id, vm.currentWaterSelected.pk.id).then((data) => {
        const veggaOverlay = document.querySelector('vegga-overlay');
        veggaOverlay.dismiss();
        var page = _.findIndex(vm.awList, (o) => {
          return o.pk.id === selected.pk.id;
        });
        vm.awList.splice(page, 1);
        vm.currentTab = null;
        vm.currentWaterSelected = null;
        $state.go('agroclimatic.water');
      });
    }

    function cancel($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          if (vm.currentTab === VeggaOverlayTab.EDIT) {
            $scope.$broadcast('cancelEditWater');
            vm.currentTab = VeggaOverlayTab.DETAIL;
            $state.go('agroclimatic.water');
            if ($event) {
              const veggaOverlay = document.querySelector('vegga-overlay');
              veggaOverlay.dismiss();
              vm.currentTab = null;
              vm.currentWaterSelected = null;
            }
          }

          if (vm.currentTab === VeggaOverlayTab.NEW) {
            const veggaOverlay = document.querySelector('vegga-overlay');
            veggaOverlay.dismiss();
            $state.go('agroclimatic.water');
            vm.currentTab = null;
            vm.currentWaterSelected = null;
          }
        });
      } else {
        if (vm.currentTab === VeggaOverlayTab.NEW) {
          deleteWater();
        }

        if (vm.currentTab === VeggaOverlayTab.EDIT) {
          $scope.$broadcast('cancelEditWater');
          vm.currentTab = VeggaOverlayTab.DETAIL;
          $state.go('agroclimatic.water');
        }

        if ($event) {
          const veggaOverlay = document.querySelector('vegga-overlay');
          veggaOverlay.dismiss();
          vm.currentTab = null;
          vm.currentWaterSelected = null;
        }
      }
    }

    function saveWater() {
      $scope.$broadcast('saveWater', { creating: vm.currentTab === VeggaOverlayTab.NEW });
    }

    $scope.$on('formWaterUpdated', (e, args) => {
      vm.form = args;
    });

    $scope.$on('updateWaterList', (e, args) => {
      if (vm.currentWaterSelected) {
        const index = vm.awList.findIndex((water) => water.pk.id === args.pk.id);
        if (index) {
          vm.awList[index] = args;
          vm.awList = JSON.parse(JSON.stringify(vm.awList));
          vm.currentWaterSelected = vm.awList[index];
        }
      }
    });
    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
    $scope.$on('refresh', function (event, args) {});
  }
})();
