import moment from 'moment/moment';
import Highcharts from 'highcharts/highcharts';
import formatcoords from 'formatcoords/index';
import ParseDMS from 'parse-dms/index';
import { VeggaOverlayTab } from '../../utils/vegga-overlay-tab.enum';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { getContainer } from '@vegga/front-store';
import { navigateToUrl } from 'single-spa';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('etoController', etoController);

  etoController.$inject = [
    '$scope',
    '$state',
    'UserData',
    'calculatedsFactory',
    'sensorsFactory',
    '$timeout',
    '$confirm',
    '$filter',
    '$q',
  ];

  function etoController(
    $scope,
    $state,
    UserData,
    calculatedsFactory,
    sensorsFactory,
    $timeout,
    $confirm,
    $filter,
    $q
  ) {
    var vm = this;
    var backups;
    vm.VeggaOverlayTab = VeggaOverlayTab;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.canConfirmModal = false;
    vm.isDeleting = false;

    activate();

    function activate() {
      vm.devicesFacade.loadICMDevices(UserData.id, true);
      vm.devicesFacade.devicesICM$.pipe(takeUntil(vm.destroy$)).subscribe((units) => {
        vm.units = units;
      });

      loadSensors();

      vm.unitName = unitName;
      vm.loadUnitSensors = loadUnitSensors;
      vm.newSensor = newSensor;
      vm.deleteSensor = deleteSensor;
      vm.updateSensor = updateSensor;
      vm.sensorEdition = sensorEdition;
      vm.cancelEdition = cancelEdition;
      vm.sensorHistory = sensorHistory;
      vm.convertDMStoDS = convertDMStoDS;
      vm.changeETO = changeETO;
      vm.changingETO = changingETO;
      vm.nextOrPreviousItem = nextOrPreviousItem;
      vm.cancel = cancel;
      vm.changeTab = changeTab;
      vm.navigateToControlPoints = navigateToControlPoints;
      vm.modalConfirmTextInput = modalConfirmTextInput;
      vm.save = save;
      vm.doDelete = doDelete;
      vm.acceptConfirmModal = acceptConfirmModal;
      vm.dismissConfirmModal = dismissConfirmModal;

      vm.actionLabel = $filter('translate')('calcs.action');
      vm.confirmLabel = $filter('translate')('AUTOGENERATED.ACTIONS.ACT0016_CONFIRM').toUpperCase();
      vm.modal = document.querySelector('vegga-modal');
      vm.modal.addEventListener('veggaOnModalDismiss', () => dismissConfirmModal());

      backups = {};
      $scope.$emit('stateChanged');

      vm.agroclimaticIndicesFacade = getContainer().resolve('agroclimaticIndicesFacade');
      vm.isAgrIndexUsedByControlPoint = false;
    }

    function unitName(sensor) {
      if (sensor.deviceId === null) {
        return null;
      } else {
        const unit = vm.units.find((unit) => {
          return unit.id === sensor.deviceId;
        });
        return unit.name;
      }
    }

    function loadSensors() {
      calculatedsFactory.getETO(UserData.id).then((result) => {
        if (result.plain()) {
          vm.etoList = result.plain();
        }
      });
    }

    function newSensor() {
      vm.currentETOSelected = null;
      vm.currentTab = VeggaOverlayTab.NEW;
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
    }

    function deleteSensor() {
      calculatedsFactory.deleteETO(UserData.id, vm.currentETOSelected.pk.id).then(() => {
        const veggaOverlay = document.querySelector('vegga-overlay');
        veggaOverlay.dismiss();
        vm.currentTab = null;
        vm.etoList = vm.etoList.filter((dpv) => dpv.pk.id !== vm.currentETOSelected.pk.id);
        vm.currentETOSelected = null;
      });
    }

    function sensorEdition() {
      var sensor_backup = {};
      angular.copy(vm.currentETOSelected, sensor_backup);
      backups[vm.currentETOSelected.pk.id] = sensor_backup;
      if (
        vm.currentETOSelected.Templist === undefined ||
        vm.currentETOSelected.Hrlist === undefined ||
        vm.currentETOSelected.Radlist === undefined ||
        vm.currentETOSelected.Windlist === undefined
      ) {
        loadUnitSensors(vm.currentETOSelected, true);
      }
      prepareLatitudeLongitude(vm.currentETOSelected);
    }

    function loadUnitSensors(sensor, isFirstLoad, type) {
      if (!isFirstLoad) {
        if (type === 'temp') {
          vm.currentETOSelected.analogIdTemp = 0;
        }
        if (type === 'hr') {
          vm.currentETOSelected.analogIdHr = 0;
        }
        if (type === 'rad') {
          vm.currentETOSelected.analogIdRad = 0;
        }
        if (type === 'wind') {
          vm.currentETOSelected.analogIdWind = 0;
        }
      }
      if (isFirstLoad) {
        vm.isContentLoaded = false;
      }
      let params = {
        limit: 100,
        page: 1,
      };
      let binding = sensor;

      let promise1 = Promise.resolve();
      let promise2 = Promise.resolve();
      let promise3 = Promise.resolve();
      let promise4 = Promise.resolve();

      if ((sensor.deviceIdTemp && type === 'temp') || (isFirstLoad && sensor.deviceIdTemp)) {
        binding.Templist = [];
        promise1 = sensorsFactory.analogsPage(sensor.deviceIdTemp, params);
      }

      if ((sensor.deviceIdHr && type === 'hr') || (isFirstLoad && sensor.deviceIdHr)) {
        binding.Hrlist = [];
        promise2 = sensorsFactory.analogsPage(sensor.deviceIdHr, params);
      }

      if ((sensor.deviceIdRad && type === 'rad') || (isFirstLoad && sensor.deviceIdRad)) {
        binding.Radlist = [];
        promise3 = sensorsFactory.analogsPage(sensor.deviceIdRad, params);
      }

      if ((sensor.deviceIdWind && type === 'wind') || (isFirstLoad && sensor.deviceIdWind)) {
        binding.Windlist = [];
        promise4 = sensorsFactory.analogsPage(sensor.deviceIdWind, params);
      }

      $q.all([promise1, promise2, promise3, promise4]).then(([rs1, rs2, rs3, rs4]) => {
        if (rs1?.content) {
          binding.Templist = rs1.content;
        }
        if (rs2?.content) {
          binding.Hrlist = rs2.content;
        }
        if (rs3?.content) {
          binding.Radlist = rs3.content;
        }
        if (rs4?.content) {
          binding.Windlist = rs4.content;
        }
        vm.isContentLoaded = true;
      });
    }

    function cancelEdition() {
      if (vm.currentETOSelected && vm.currentETOSelected.pk) {
        angular.copy(backups[vm.currentETOSelected.pk.id], vm.currentETOSelected);
      }
      vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
    }

    function modalConfirmTextInput(ev) {
      vm.canConfirmModal = ev.target.value === vm.confirmLabel;
    }

    function acceptConfirmModal() {
      if (vm.isDeleting) {
        deleteSensor();
      } else {
        updateSensor();
      }

      dismissConfirmModal();
    }

    function doDelete() {
      if (!vm.isAgrIndexUsedByControlPoint) {
        deleteSensor();
        return;
      }

      vm.isDeleting = true;
      vm.modal.show();
    }

    function dismissConfirmModal() {
      vm.modal.hide();
      vm.canConfirmModal = false;
      document.querySelector('#modalConfirmText').value = '';
      vm.isDeleting = false;
    }

    function navigateToControlPoints() {
      navigateToUrl('/agronomic-monitoring/control-points');
    }

    function save() {
      if (!vm.isAgrIndexUsedByControlPoint) {
        updateSensor();
        return;
      }

      if (vm.currentTab !== vm.VeggaOverlayTab.NEW) {
        vm.canConfirmModal = false;
        vm.modal.show();
      } else {
        updateSensor();
      }
    }

    function updateSensor() {
      var tmp = vm.currentETOSelected;
      convertDMStoDS(vm.currentETOSelected);
      calculatedsFactory.updateETO(UserData.id, vm.currentETOSelected).then((result) => {
        vm.currentETOSelected = result.plain();
        tmp.pk = vm.currentETOSelected.pk;
        if (vm.currentTab === VeggaOverlayTab.NEW) {
          vm.etoList.push(vm.currentETOSelected);
          const veggaOverlay = document.querySelector('vegga-overlay');
          veggaOverlay.dismiss();
          vm.currentTab = null;
          vm.currentETOSelected = null;
        } else {
          dismissConfirmModal();
        }
        vm.form.$setPristine();
      });
    }

    function sensorHistory(sensor, reload) {
      vm.currentTab = VeggaOverlayTab.DETAIL;
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      vm.currentETOSelected = sensor;

      let chart;
      var date_to;
      let datos_grafica = [];

      var today = new moment()._d;
      var date_ago = new moment().subtract(7, 'days')._d;

      if (sensor.fecha_desde === undefined) sensor.fecha_desde = date_ago;
      if (sensor.fecha_hasta === undefined) sensor.fecha_hasta = today;

      if (reload) {
        if (moment(sensor.fecha_hasta).isAfter(moment(today))) date_to = moment(today).format('DD-MM-YYYY');
        else date_to = moment(sensor.fecha_hasta).add(1, 'days').format('DD-MM-YYYY');
      } else date_to = moment(sensor.fecha_hasta).format('DD-MM-YYYY');

      let params = {
        to: date_to,
        from: moment(sensor.fecha_desde).format('DD-MM-YYYY'),
      };
      calculatedsFactory
        .historyETO(sensor.pk.userId, sensor.pk.id, params)
        .then((result) => {
          var mdata = [];
          mdata = result;
          mdata.forEach(function (valor, indice) {
            var date_timestamp = moment(mdata[indice].date, 'DD-MM-YYYY').format('x');

            date_timestamp = parseInt(date_timestamp);

            datos_grafica.push([date_timestamp, mdata[indice].value]);
          });
          vm.agroclimaticIndicesFacade.agroclimaticVars$.pipe(take(1)).subscribe((agroclimaticVars) => {
            const DPVList = agroclimaticVars.find((agVar) => agVar.type === 'ETO') || { items: [] };
            const usedIndexes = DPVList.items.some((agVar) => agVar.id === sensor.pk.id);
            vm.isAgrIndexUsedByControlPoint = !!usedIndexes;
          });
          $timeout(() => {
            chart = loadGraficas(datos_grafica, sensor.pk.id);
            chart.setTitle({ text: sensor.name });
          }, 100);
        })
        .catch(function () {});
    }

    $scope.$on('refresh', function () {});

    function loadGraficas(datos, index) {
      let id = 'graficaSensor' + index;

      let chart = new Highcharts.Chart({
        time: {
          useUTC: false,
        },
        tooltip: {
          formatter: function () {
            return (
              '<b>' + this.series.name + ':</b> ' + this.y.toFixed(2) + '<br/>' + moment(this.x).format('DD-MM-YYYY')
            );
          },
        },
        chart: {
          renderTo: id,
          type: 'column',
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },

        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%d-%m-%Y}',
            rotation: -65,
          },
        },
        yAxis: {
          title: '',
        },

        series: [
          {
            name: 'ETO',
            data: datos,
          },
        ],
      });
      return chart;
    }

    function convertDMStoDS(sensor) {
      var formated_lat, formated_lng;
      formated_lat =
        sensor.latdegrees + '° ' + sensor.latminutes + "' " + sensor.latseconds / 100 + '" ' + sensor.latcord;
      formated_lng =
        sensor.lngdegrees + '° ' + sensor.lngminutes + "' " + sensor.lngseconds / 100 + '" ' + sensor.lngcord;
      var tmp = ParseDMS(formated_lat + ' ' + formated_lng);
      if (tmp !== undefined && tmp.lat && tmp.lon) {
        sensor.lat = tmp.lat;
        sensor.lng = tmp.lon;
      }
    }

    function prepareLatitudeLongitude(sensor) {
      var lat = sensor.lat;
      var lng = sensor.lng;
      if (lat !== null && lng !== null) {
        var coords = formatcoords(lat, lng);
        sensor.latdegrees = coords.latValues.degreesInt;
        sensor.latminutes = coords.latValues.minutesInt;
        sensor.latseconds = Number(coords.latValues.seconds.toFixed(2)) * 100;
        sensor.lngdegrees = coords.lonValues.degreesInt;
        sensor.lngminutes = coords.lonValues.minutesInt;
        sensor.lngseconds = Number(coords.lonValues.seconds.toFixed(2)) * 100;
        sensor.latcord = coords.north ? 'N' : 'S';
        sensor.lngcord = coords.east ? 'E' : 'W';
      }
    }

    function changeETO(e) {
      if (e.detail.value) {
        vm.currentETOSelected = e.detail.value;
        vm.currentTab = VeggaOverlayTab.DETAIL;
        sensorHistory(vm.currentETOSelected);
      }
    }

    function changingETO($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();
          nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeETO($event);
          break;
      }
    }

    function cancel($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();
          if (vm.currentTab === VeggaOverlayTab.NEW || $event) {
            const veggaOverlay = document.querySelector('vegga-overlay');
            veggaOverlay.dismiss();
            vm.currentTab = null;
            vm.currentETOSelected = null;
          }
        });
      } else {
        if ($event) {
          const veggaOverlay = document.querySelector('vegga-overlay');
          veggaOverlay.dismiss();
          vm.currentTab = null;
          vm.currentETOSelected = null;
        }

        if (vm.currentTab === VeggaOverlayTab.NEW) {
          vm.currentTab = null;
          vm.currentETOSelected = null;
        }
      }
    }

    function changeTab(tab, $event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();

          vm.currentTab = tab;
          if (tab === VeggaOverlayTab.EDIT) {
            sensorEdition();
          }

          if (tab === VeggaOverlayTab.DETAIL) {
            vm.sensorHistory(vm.currentETOSelected);
          }
        });
      } else {
        vm.currentTab = tab;
        if (tab === VeggaOverlayTab.EDIT) {
          sensorEdition();
        }

        if (tab === VeggaOverlayTab.DETAIL) {
          sensorHistory(vm.currentETOSelected);
        }
      }
    }
  }
})();
