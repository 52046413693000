export class GraphicsFilter {
  static irrigationParent = 'graphics';
  static agroclimaticParent = 'agroclimaticGraphics';
  static irrigationControlGraphic = [
    { id: 1, name: 'graphics.g1' },
    { id: 2, name: 'graphics.g2' },
    { id: 3, name: 'graphics.g3' },
    { id: 4, name: 'graphics.g4' },
    { id: 7, name: 'graphics.g7' },
    { id: 10, name: 'general.integration' },
  ];

  static climaPlantaSolGraphic = [
    { id: 5, name: 'graphics.g5' },
    { id: 6, name: 'graphics.g6' },
    { id: 8, name: 'graphics.g8' },
    { id: 9, name: 'graphics.g9' },
  ];

  static climaPlantaSolFilter = [
    { id: 5, name: 'graphics.g5' },
    { id: 6, name: 'graphics.g6' },
    { id: 8, name: 'graphics.g8' },
    { id: 9, name: 'graphics.g9' },
  ];
}
