(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('graphicsFactory', graphicsFactory);

  graphicsFactory.$inject = ['Restangular', '$q', '$window'];

  function graphicsFactory(Restangular, $q, $window) {
    var op = {
      getGraphics: getGraphics,
      update: update,
      deleteGraphic: deleteGraphic,
      getAnalog: getAnalog,
      getIntegration: getIntegration,
      getDPV: getDPV,
      getETO: getETO,
      getWaterDisp: getWaterDisp,
      getMeter: getMeter,
      getMeterConsumption: getMeterConsumption,
      getFilterStorage: getFilterStorage,
      setFilterStorage: setFilterStorage,
      getSector: getSector,
      getSectorFert: getSectorFert,
      getSectorETC: getSectorETC,
      getPDR: getPDR,
      clearGraphics: clearGraphics,
      setOrderStorage: setOrderStorage,
      getOrderStorage: getOrderStorage,
    };
    var list = null;

    return op;

    function getGraphics(id) {
      var deferred = $q.defer();

      Restangular.one('users', id)
        .one('graphics')
        .get()
        .then(function (response) {
          list = response.plain();
          deferred.resolve(list);
        });

      return deferred.promise;
    }

    function update(id, params) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('graphics')
        .customPOST(params)
        .then(function (response) {
          list = null;
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function deleteGraphic(id, graphic) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('graphics', graphic)
        .customDELETE()
        .then(function (response) {
          list = null;
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getAnalog(userId, deviceId, sensorId, params) {
      var deferred = $q.defer();
      Restangular.one('users', userId)
        .one('graphics')
        .one('history')
        .one('analog', deviceId)
        .one('', sensorId)
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function getIntegration(userId, deviceId, sensorId, params) {
      var deferred = $q.defer();
      Restangular.one('users', userId)
        .one('graphics')
        .one('history')
        .one('integration', deviceId)
        .one('', sensorId)
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getDPV(userId, sensorId, params) {
      var deferred = $q.defer();
      Restangular.one('users', userId)
        .one('graphics')
        .one('history')
        .one('dpv', sensorId)
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getETO(userId, sensorId, params) {
      var deferred = $q.defer();
      Restangular.one('users', userId)
        .one('graphics')
        .one('history')
        .one('eto', sensorId)
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getWaterDisp(userId, sensorId, i, params) {
      var deferred = $q.defer();
      Restangular.one('users', userId)
        .one('graphics')
        .one('history')
        .one('waterdisp', sensorId)
        .one('', 0)
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getMeter(userId, deviceId, sensorId, params) {
      var deferred = $q.defer();
      Restangular.one('users', userId)
        .one('graphics')
        .one('history')
        .one('meter', deviceId)
        .one('', sensorId)
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getMeterConsumption(userId, deviceId, sensorId, params) {
      var deferred = $q.defer();
      Restangular.one('users', userId)
        .one('graphics')
        .one('history')
        .one('flow', deviceId)
        .one('', sensorId)
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getSector(userId, deviceId, sectorId, params) {
      var deferred = $q.defer();
      Restangular.one('users', userId)
        .one('graphics')
        .one('history')
        .one('sector', deviceId)
        .one('', sectorId)
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getSectorFert(userId, deviceId, sectorId, fertId, params) {
      var deferred = $q.defer();
      Restangular.one('users', userId)
        .one('graphics')
        .one('history')
        .one('sector', deviceId)
        .one('', sectorId)
        .one('fert', fertId)
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function getSectorETC(userId, deviceId, sectorId, params) {
      var deferred = $q.defer();
      Restangular.one('users', userId)
        .one('graphics')
        .one('history')
        .one('etc', deviceId)
        .one('', sectorId)
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function getPDR(userId, sensorId, params) {
      var deferred = $q.defer();
      Restangular.one('users', userId)
        .one('graphics')
        .one('history')
        .one('pdr', sensorId)
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function setFilterStorage(name, val, id) {
      var stored = JSON.stringify(val);
      $window.localStorage && $window.localStorage.setItem('graphic-' + name + id, stored);
    }
    function getFilterStorage(name, id) {
      var stored = $window.localStorage && $window.localStorage.getItem('graphic-' + name + id);
      return JSON.parse(stored);
    }

    function setOrderStorage(iduser, column, direction) {
      var stored = JSON.stringify(column + ',' + direction);
      $window.localStorage && $window.localStorage.setItem('ordergraphic-' + iduser, stored);
    }
    function getOrderStorage(iduser) {
      var stored = $window.localStorage && $window.localStorage.getItem('ordergraphic-' + iduser);
      return JSON.parse(stored);
    }

    function clearGraphics() {
      list = null;
    }
  }
})();
