import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('extractionsEditController', extractionsEditController);

  extractionsEditController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'extractionsFactory',
    'sensorsFactory',
    '$confirm',
  ];

  function extractionsEditController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    UserData,
    extractionsFactory,
    sensorsFactory,
    $confirm
  ) {
    var vm = this;
    var sensor_backup, count_backup, extractions_backup;
    var sensorByUnitAndType = [];
    vm.itsupdate;
    activate();

    function activate() {
      vm.changeState = changeState;
      vm.loadCounters = loadCounters;
      vm.getSensorAnalogs = getSensorAnalogs;
      vm.selectName = selectName;
      vm.save = save;
      vm.cancel = cancel;
      vm.stopSincro = stopSincro;
      vm.dropExtraction = dropExtraction;

      if ($state.params.extractions !== null) {
        //UPDATE
        vm.extractions = $state.params.extractions;
        vm.units = $state.params.units;
        vm.itsUpdate = true;
        if (vm.extractions.meterDeviceId !== null) {
          loadCounters();
        }
        if (vm.extractions.lvlDecideId !== null) {
          getSensorAnalogs();
        }
        vm.registerDate = moment(vm.extractions.registerDate, 'DD-MM-YYYY HH:mm')._d;
        if (!vm.extractions.backup) {
          backup();
        }
      } else {
        //CREATE
        if ($state.params.units !== null) {
          vm.units = $state.params.units;
          vm.itsUpdate = false;
        } else {
          $state.go('units');
        }
      }
    }

    function backupSensorList() {
      sensor_backup = [];
      angular.copy(vm.sensorsByElement, sensor_backup);
    }

    function backupCountList() {
      count_backup = [];
      angular.copy(vm.countersByElement, count_backup);
    }

    function getSensorAnalogs() {
      let deviceId = vm.extractions.lvlDecideId;

      if (deviceId !== null) {
        let device = _.filter(vm.units, (d) => {
          return d.device.id === deviceId;
        });
        if (device[0] !== undefined) {
          vm.extractions.lvlDeviceName = device[0].device.name;
        }
      } else {
        elem.deviceName = null;
      }

      var params = {
        limit: 100,
        page: 1,
      };
      if (sensorByUnitAndType[deviceId + '-1'] !== undefined) {
        vm.sensorsByElement = sensorByUnitAndType[deviceId + '-1'];
      } else {
        sensorsFactory.analogsPage(deviceId, params).then((result) => {
          if (result.plain()) {
            sensorByUnitAndType[deviceId + '-1'] = result.content;
            vm.sensorsByElement = sensorByUnitAndType[deviceId + '-1'];
            backupSensorList();
          }
        });
      }
    }

    function loadCounters() {
      let deviceId = vm.extractions.meterDeviceId;

      if (deviceId !== null) {
        let device = _.filter(vm.units, (d) => {
          return d.device.id === deviceId;
        });
        if (device[0] !== undefined) {
          vm.extractions.meterDeviceName = device[0].device.name;
        }
      } else {
        elem.deviceName = null;
      }

      let unit = _.filter(vm.units, ['device.id', deviceId]);
      if (sensorByUnitAndType[deviceId + '-2'] !== undefined) {
        vm.countersByElement = sensorByUnitAndType[deviceId + '-2'];
      } else {
        if (unit[0].device.type !== 2) {
          sensorsFactory.meters(deviceId).then((data) => {
            sensorByUnitAndType[deviceId + '-2'] = _.filter(data.plain(), (o) => {
              return o.input !== 0;
            });
            vm.countersByElement = sensorByUnitAndType[deviceId + '-2'];
            backupCountList();
          });
        } else {
          sensorsFactory.digitalsactive(deviceId).then((data) => {
            sensorByUnitAndType[deviceId + '-2'] = _.filter(data.plain(), (o) => {
              return o.input !== 0 && o.pk.id >= 11 && o.pk.id <= 26;
            });
            vm.countersByElement = sensorByUnitAndType[deviceId + '-2'];
            backupCountList();
          });
        }
      }
    }

    function selectName(o) {
      if (o === 'meter') {
        let sensorId = vm.extractions.meterId;
        if (sensorId !== null) {
          let sensor = _.filter(vm.countersByElement, (d) => {
            return d.pk.id === sensorId;
          });
          if (sensor[0] !== undefined) {
            vm.extractions.meterSensorName = sensor[0].name;
          }
        } else {
          vm.extractions.meterSensorName = null;
        }
      }
      if (o === 'lvl') {
        let sensorId = vm.extractions.lvlId;
        if (sensorId !== null) {
          let sensor = _.filter(vm.sensorsByElement, (d) => {
            return d.pk.id === sensorId;
          });
          if (sensor[0] !== undefined) {
            vm.extractions.lvlSensorName = sensor[0].name;
          }
        } else {
          vm.extractions.lvlSensorName = null;
        }
      }
    }

    function save() {
      if (vm.form.code.$valid && vm.form.date.$valid) {
        vm.form.$setPristine();
        if (vm.itsUpdate === true) {
          vm.extractions.registerDate = moment(vm.registerDate).format('DD-MM-YYYY hh:mm');
          extractionsFactory.update(vm.extractions).then((response) => {
            backup();
          });
        } else {
          vm.extractions.registerDate = moment(vm.registerDate).format('DD-MM-YYYY hh:mm');
          extractionsFactory.newExt(vm.extractions).then((response) => {
            backup();
          });
        }
      } else {
        $rootScope.toast({
          type: 'error',
          title: 'Debe rellenar todos los campos obligatorios',
        });
      }
    }

    function backup() {
      extractions_backup = {};
      angular.copy(vm.extractions, extractions_backup);
      vm.extractions.backup = true;
    }

    function cancel() {
      if (vm.itsUpdate) {
        angular.copy(extractions_backup, vm.extractions);
        angular.copy(count_backup, vm.countersByElement);
        angular.copy(sensor_backup, vm.sensorsByElement);
        $state.go('eextra.edit', { extractions: vm.extractions });
      } else {
        $state.go('eextra.list', { units: vm.units });
      }
    }

    function stopSincro() {
      $confirm({
        text: 'Esta seguro que quiere parar esta sincronización?',
        title: 'Extracciones Efectivas',
        ok: 'Si',
        cancel: 'No',
      }).then(function () {
        if (vm.extractions.cancelDate === null && !vm.extractions.canceled) {
          vm.extractions.canceled = 1;
        } else {
          vm.extractions.canceled = null;
        }
        extractionsFactory.update(vm.extractions).then((response) => {
          backup();
        });
      });
    }

    function dropExtraction() {
      $confirm({
        text: 'Esta seguro que quiere darse de baja de esta extraccion?',
        title: 'Extracciones Efectivas',
        ok: 'Si',
        cancel: 'No',
      }).then(function () {
        if (vm.extractions.cancelDate === null || !vm.extractions.canceled) {
          vm.extractions.cancelDate = moment().format('DD-MM-YYYY hh:mm');
          vm.extractions.canceled = 1;
        } else {
          vm.extractions.cancelDate = null;
          vm.extractions.canceled = null;
        }
        extractionsFactory.update(vm.extractions).then((response) => {
          backup();
        });
      });
    }

    function changeState(state, e) {
      $state.go('eextra.' + state, { extractions: e });
    }
  }
})();
