import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UNITS } from '../utils/units.enum';
import { getContainer } from '@vegga/front-store';
(function () {
  'use strict';

  angular.module('agronicwebApp').controller('fertilizersOverlayController', fertilizersOverlayController);

  fertilizersOverlayController.$inject = ['$state', '$rootScope', '$scope', '$filter', 'veggaModalConfirmService'];
  function fertilizersOverlayController($state, $rootScope, $scope, $filter, veggaModalConfirmService) {
    var vm = this;
    vm.UNITS = UNITS;
    vm.tabState = 'general';
    vm.overlayLoader$ = new Subject();
    vm.dismissOverlay = dismissOverlay;
    vm.saveFormChanges = saveFormChanges;
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.formFertilizer = {};
      vm.destroy$ = new Subject();

      // cached response, used when user is navigating from /fertilizers
      const unitsReq$ = vm.devicesFacade.legacyUnitResponse.value$;

      unitsReq$.pipe(takeUntil(vm.destroy$)).subscribe((unit) => {
        vm.currentUnit = unit;
        showOverlay();
      });

      const [_, state] = $state.current.name.split('.');
      vm.activeTab = state;
    }

    function saveFormChanges() {
      $rootScope.$broadcast('formSaved');
    }

    function dismissOverlay(ev) {
      if (!vm.formFertilizer.$dirty) {
        $state.go('fertilizers');
        return;
      }

      ev && ev.preventDefault();
      openVeggaConfirmModal().then((isConfirmed) => {
        if (isConfirmed) {
          $state.go('fertilizers');
        }
      });
    }

    function showOverlay() {
      const overlay = document.querySelector('#fertilizersOverlay');

      // prevent from showing overlay again while refreshing
      // with overlay shown
      if (overlay.toggle !== 'show') {
        overlay.show();
      }
    }

    function openVeggaConfirmModal() {
      return veggaModalConfirmService.showVeggaModalConfirm({
        header: $filter('translate')('fert.fertconf'),
        content: $filter('translate')('programs.edit.cancelq'),
        cancelText: $filter('translate')('general.cancel'),
        confirmText: $filter('translate')('general.accept'),
      });
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    $rootScope.$on('formUpdated', (_, form) => {
      vm.formFertilizer = form;
    });

    $rootScope.$on('showSkeleton', (ev, data) => {
      vm.showSkeleton = data;
      vm.overlayLoader$.next(!data);
    });
  }
})();
