(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('traysDetailControllerA7', traysDetailControllerA7);

  traysDetailControllerA7.$inject = ['$state', 'trays7Factory', '$confirm', 'manualFactory'];

  function traysDetailControllerA7(
    $state,

    trays7Factory,
    $confirm,
    manualFactory
  ) {
    var vm = this;

    activate();

    function activate() {
      vm.currentUnit = $state.params.unit;
      vm.drainageUnits = $state.params.unit.installer.drainageUnits;
      vm.balanceRain = $state.params.unit.installer.balanceRain;
      vm.toogleManual = toogleManual;
      vm.manualChangeDP = manualChangeDP;
      vm.manualChangeCD = manualChangeCD;
      loadAllTrays();
    }

    function loadAllTrays() {
      vm.trays = [];
      trays7Factory.allTrays($state.params.unit.id).then(function (data) {
        vm.trays = data.plain();

        for (var i = 0, len = vm.trays.length; i < len; i++) {
          if (vm.trays[i].xCorrectionIrrig > 0) {
            vm.trays[i].xCorrectionIrrig = vm.trays[i].xCorrectionIrrig / 10;
          }
          if (vm.trays[i].xDrainPh > 0) {
            vm.trays[i].xDrainPh = vm.trays[i].xDrainPh / 10;
          }
          if (vm.trays[i].xDrainCe > 0) {
            vm.trays[i].xDrainCe = vm.trays[i].xDrainCe / 10;
          }
        }
      });
    }
    function toogleManual(t) {
      if (t.manual === undefined) {
        t.manual = true;
      } else {
        t.manual = !t.manual;
      }
    }

    function manualChangeDP(t) {
      $confirm({ text: 'Enviar orden?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        toogleManual(t);
        var params = {};
        params.deviceId = vm.currentUnit.id;
        params.type = vm.currentUnit.type;
        params.action = 6;
        params.parameter1 = Number(t.id) - 1;
        params.parameter2 = Number(t.dp);
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          loadAllTrays();
        });
      });
    }
    function manualChangeCD(t) {
      $confirm({ text: 'Enviar orden?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        toogleManual(t);
        var params = {};
        params.deviceId = vm.currentUnit.id;
        params.type = vm.currentUnit.type;
        params.action = 7;
        params.parameter1 = Number(t.pk.id) - 1;
        params.parameter2 = Number(t.cd);
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          loadAllTrays();
        });
      });
    }
  }
})();
