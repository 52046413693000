(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('pivotsEditController', pivotsEditController);

  pivotsEditController.$inject = ['$scope', '$rootScope', '$state', 'pivotsFactory', 'manualFactory', '$filter'];

  function pivotsEditController($scope, $rootScope, $state, pivotsFactory, manualFactory, $filter) {
    var vm = this;
    var backup_pivot;
    vm.cancel;
    vm.save;
    vm.gridColumnsDef = [
      { headerName: $filter('translate')(''), field: '' },
      { headerName: $filter('translate')('pivot.precip'), field: '' },
      { headerName: $filter('translate')('pivot.velo'), field: '' },
      { headerName: $filter('translate')('pivot.irriga'), field: '' },
      { headerName: $filter('translate')('pivot.ferti'), field: '' },
    ];

    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null && $state.params.pivot !== null) {
        vm.pivot = $state.params.pivot;
        vm.currentUnit = $state.params.unit;
        vm.cancel = cancel_edition;
        vm.save = save;
        vm.direction = direction;
        vm.updatePrecipitations = updatePrecipitations;
        vm.precipitation = precipitation;
        vm.deletePivot = deletePivot;

        backup();

        vm.totalAreas = _.range(1, vm.pivot.areasNumber + 1);
        _.forEach(vm.pivot.areas, (area) => precipitation(area));
      } else {
        $state.go('units');
      }
    }

    function deletePivot() {
      var action;
      var parameters = null;
      parameters = Number(vm.pivot.pk.id);
      action = 28;
      var modalInstance = manualFactory.showLoadingBar();
      manualFactory.stopMalfunction(vm.currentUnit, action, parameters).then((response) => {
        //TODO
      });
      modalInstance.result.then((result) => {
        $rootScope.$broadcast('updateEdit', { message: 'refreshing', info: true });
      });
    }

    function direction(d) {
      vm.pivot.direction = d;
      vm.form.$setDirty();
    }

    function updatePrecipitations() {
      _.forEach(vm.pivot.areas, (area) => precipitation(area));
    }

    function precipitation(area) {
      var real_long = vm.pivot.totalLength - vm.pivot.aleroLength - vm.pivot.pistolLength;
      if (real_long < 0) {
        area.precipitation = '-';
      } else {
        var total_long = 2 * Math.PI * real_long;
        var total_sup = Math.PI * (vm.pivot.totalLength * vm.pivot.totalLength);
        var initial_pos = 0,
          area_time = 0;
        var angle = area.finalPosition - initial_pos;
        initial_pos = area.finalPosition;
        var long = (total_long * angle) / 3600;
        var sup = (total_sup * angle) / 3600 / 100;
        var time = (vm.pivot.speed * area.velocity) / 100;
        if (time != 0) {
          area_time = (long * 10) / time;
        } else {
          area_time = 0;
        }
        if (!vm.pivot.turnsOrTime) {
          if (vm.pivot.returnNoIrrig === 0) {
            area_time = area_time * vm.pivot.turns;
          } else {
            var tmp = vm.pivot.turns / 2;
            if (vm.pivot.turns % 2) {
              tmp++;
            }
            area_time = area_time * tmp;
          }
        }
        var volume = (vm.pivot.flow * area_time) / 60;
        volume = volume / 1000;
        var precipitation = volume / sup / 10;
        if (!_.isNaN(precipitation)) {
          area.precipitation = (precipitation * 100000).toFixed(2) + ' mm';
        } else {
          area.precipitation = '-';
        }
      }
    }

    function cancel_edition() {
      angular.copy(backup_pivot, vm.pivot);
    }

    function backup() {
      backup_pivot = {};
      angular.copy(vm.pivot, backup_pivot);
      vm.pivot.backup = true;
    }

    function save() {
      vm.form.$setPristine();
      pivotsFactory.update(vm.currentUnit.id, vm.pivot).then((response) => {
        vm.pivot = response.plain();
        vm.pivot.areas = _.orderBy(vm.pivot.areas, 'id');
        vm.pivot.externalAreas = _.orderBy(vm.pivot.externalAreas, 'id');
        backup();
      });
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
