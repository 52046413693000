import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('graphicsDetailController', graphicsDetailController);

  graphicsDetailController.$inject = ['$scope', '$state', 'graphicsFactory', 'UserData', '$q', '$filter'];

  function graphicsDetailController($scope, $state, graphicsFactory, UserData, $q, $filter) {
    const vm = this;
    let arrayUnits = [],
      dataSeries = [];
    let chart,
      positionY,
      params_date,
      params_sector,
      ngroup,
      nperiod,
      nfrom,
      nto,
      numTotal,
      timeS,
      // timeI,
      dateToF,
      vis;
    vm.graphic;
    vm.filter;
    vm.dateValidation;

    vm.updateRangeDates = updateRangeDates;

    activate();

    function activate() {
      if (!$state.params.graphic) {
        $state.go('^');
        return;
      }

      vm.graphic = $state.params.graphic;

      ngroup = getFilterG('group_', vm.graphic.pk.userId + '-' + vm.graphic.pk.id);
      if (ngroup === null) ngroup = '2';

      nperiod = getFilterG('period_', vm.graphic.pk.userId + '-' + vm.graphic.pk.id);
      if (nperiod === null || nperiod === 0) nperiod = 6;

      if (nperiod == 99) {
        nfrom = getFilterG('from_', vm.graphic.pk.userId + '-' + vm.graphic.pk.id);
        nto = getFilterG('to_', vm.graphic.pk.userId + '-' + vm.graphic.pk.id);
      }

      if (nfrom === undefined || nto === undefined) {
        if (!UserData.profesional) {
          nperiod = 6;
        }
        vm.graphic.to = new moment()._d;
        vm.graphic.from = new moment().subtract(nperiod, 'days')._d;
      } else {
        let daydiff = new moment(nto).add(1, 'days').diff(new moment(nfrom), 'days');
        if (daydiff > 7 && !UserData.profesional) {
          nperiod = 6;
          ngroup = '2';
          vm.graphic.to = new moment()._d;
          vm.graphic.from = new moment().subtract(7, 'days')._d;
        } else {
          vm.graphic.to = new moment(nto)._d;
          vm.graphic.from = new moment(nfrom)._d;
        }
      }

      if (!UserData.profesional) {
        vm.minDate = new moment().subtract(7, 'day')._d;
      } else {
        vm.minDate = null;
      }

      vm.groupBy = [
        { text: '10 min', value: '0' },
        { text: $filter('translate')('history.hour'), value: '1' },
        { text: $filter('translate')('history.day'), value: '2' },
        { text: $filter('translate')('history.week'), value: '3' },
        { text: $filter('translate')('history.month'), value: '4' },
      ];

      vm.period = [
        { text: $filter('translate')('graphics.1days'), value: '0', profesional: false },
        { text: $filter('translate')('graphics.7days'), value: '6', profesional: false },
        { text: $filter('translate')('graphics.15days'), value: '14', profesional: true },
        { text: $filter('translate')('graphics.30days'), value: '29', profesional: true },
        { text: $filter('translate')('graphics.betweenDays'), value: '99', profesional: false },
      ];

      vm.filter = ngroup;
      vm.graphic.period = nperiod;
      vm.loadGraficas = loadGraficas;
      vm.changeDates = changeDates;
      vm.changeFrom = changeFrom;
      vm.changeTo = changeTo;
      vm.loadDataSensors = loadDataSensors;
      vm.backState = backState;
      vm.exportChartData = exportChartData;
      vm.dateValidation = {
        m: true,
        h: true,
        d: true,
        w: true,
        mo: true,
      };
      vm.showGroup = true;
      vm._7days = true;
      vm._15days = true;
      vm._30days = true;
      vm.betweenDays = true;

      validateElements();

      groupValidation(vm.graphic.period);

      loadDataSensors();
    }

    function validateElements() {
      let grouping = _.filter(vm.graphic.graphicElements, 'group');
      if (grouping.length > 0) {
        _.forEach(grouping, (g) => {
          if (g.configuration === 0) g.groupName = '(10min)';
          else if (g.configuration === 1) g.groupName = '(Hora)';
          else if (g.configuration === 2) g.groupName = '(Dia)';
          else if (g.configuration === 3) g.groupName = '(Semana)';
          else if (g.configuration === 4) g.groupName = '(Mes)';
        });
        if (grouping.length === vm.graphic.graphicElements.length) {
          //TOTS
          vm.showGroup = false;
        }
        let groupValues = _.map(grouping, 'configuration');
        let min = _.min(groupValues);
        switch (min) {
          case 0:
            vm.graphic.period = 0;
            changeTo();
            vm._7days = false;
            vm._15days = false;
            vm._30days = false;
            vm.betweenDays = false;
            break;
          case 1: {
            let value = Number(vm.graphic.period);
            vm._30days = false;
            vm.betweenDays = false;
            if (value === 29) {
              vm.graphic.period = 14;
              changeTo();
            }
            break;
          }
        }

        vm.filter = min + '';
      }
    }

    function getFilterG(name, id) {
      const filterGraphic = graphicsFactory.getFilterStorage(name, id);
      return filterGraphic;
    }

    function setFilterG(name, val, id) {
      graphicsFactory.setFilterStorage(name, val, id);
    }

    function changeDates() {
      if (vm.graphic.period !== 99) {
        const date_ago = new moment(vm.graphic.to).subtract(vm.graphic.period, 'days')._d;
        vm.graphic.from = date_ago;
      }
      groupValidation(vm.graphic.period);
    }

    function changeFrom() {
      if (vm.graphic.period !== 99) {
        const date_ago = new moment(vm.graphic.from).add(vm.graphic.period, 'days')._d;
        vm.graphic.to = date_ago;
        /*} else if(vm.filter === '1'){
            let days = moment(vm.graphic.to).diff(moment(vm.graphic.from),'days');
            if( days > 15) {
                //vm.graphic.period = 15;
                var date_ago = moment(vm.graphic.from).add(15, 'days')._d; 
                vm.graphic.to = date_ago;
            } */
      } else if (vm.filter === '0') {
        let days = new moment(vm.graphic.to).diff(new moment(vm.graphic.from), 'days');
        if (days > 1) {
          const date_ago = new moment(vm.graphic.from).add(1, 'days')._d;
          vm.graphic.to = date_ago;
        }
      }
    }

    function changeTo() {
      if (vm.graphic.period !== 99) {
        const date_ago = new moment(vm.graphic.to).subtract(vm.graphic.period, 'days')._d;
        vm.graphic.from = date_ago;
        /* } else if(vm.filter === '1'){
            let days = moment(vm.graphic.to).diff(moment(vm.graphic.from),'days');
            if( days > 15) {
               // vm.graphic.period = 15;
                var date_ago = moment(vm.graphic.to).subtract(15, 'days')._d; 
                vm.graphic.from = date_ago;
            }  */
      } else if (vm.filter === '0') {
        let days = new moment(vm.graphic.to).diff(new moment(vm.graphic.from), 'days');
        if (days > 1) {
          const date_ago = new moment(vm.graphic.to).subtract(1, 'days')._d;
          vm.graphic.from = date_ago;
        }
      }
    }

    function getAnalog(deviceId, sensorId) {
      return graphicsFactory.getAnalog(UserData.id, deviceId, sensorId, params_date).then((result) => {
        if (result.plain()) {
          return result.plain();
        }
      });
    }
    function getIntegration(deviceId, sensorId) {
      return graphicsFactory.getIntegration(UserData.id, deviceId, sensorId, params_date).then((result) => {
        if (result.plain()) {
          //Codi per veure com generar una columna d'area
          /*let data0 = JSON.parse(JSON.stringify(result.plain()));

                let data1 = _.map(result.plain(), d => {
                    //d.date = moment(d.date,"DD-MM-YYYY HH:mm").add(59,'minutes').format("DD-MM-YYYY HH:mm");
                    //d.date = moment(d.date,"DD-MM-YYYY HH:mm").format("DD-MM-YYYY 23:59");
                    return d;
                });
                return _.union(data0,data1);*/
          return result.plain();
        }
      });
    }

    function getMeter(deviceId, sensorId) {
      return graphicsFactory.getMeter(UserData.id, deviceId, sensorId, params_date).then((result) => {
        if (result.plain()) {
          return result.plain();
        }
      });
    }

    function getDPV(userId, sensorId) {
      return graphicsFactory.getDPV(userId, sensorId, params_date).then((result) => {
        if (result.plain()) {
          return result.plain();
        }
      });
    }

    function getETO(userId, sensorId) {
      return graphicsFactory.getETO(userId, sensorId, params_date).then((result) => {
        if (result.plain()) {
          return result.plain();
        }
      });
    }

    function getWaterDisp(userId, sensorId, num) {
      return graphicsFactory.getWaterDisp(userId, sensorId, num, params_date).then((result) => {
        if (result.plain()) {
          return result.plain();
        }
      });
    }

    function getSector(deviceId, sectorId) {
      return graphicsFactory.getSector(UserData.id, deviceId, sectorId, params_sector).then((result) => {
        if (result.plain()) {
          return result.plain();
        }
      });
    }

    function getFlowSector(deviceId, sectorId) {
      return graphicsFactory.getFlowSector(UserData.id, deviceId, sectorId, params_sector).then((result) => {
        if (result.plain()) {
          return result.plain();
        }
      });
    }

    function getSectorFert(deviceId, sectorId, fert) {
      return graphicsFactory.getSectorFert(UserData.id, deviceId, sectorId, fert, params_sector).then((result) => {
        if (result.plain()) {
          return result.plain();
        }
      });
    }

    function getSectorETC(deviceId, sensorId) {
      return graphicsFactory.getSectorETC(UserData.id, deviceId, sensorId, params_date).then((result) => {
        if (result.plain()) {
          return result.plain();
        }
      });
    }

    function getPDR(userId, sensorId) {
      return graphicsFactory.getPDR(userId, sensorId, params_date).then((result) => {
        if (result.plain()) {
          return result.plain();
        }
      });
    }

    function getAttributes(i) {
      dateToF = new moment(vm.graphic.from);
      dateToF.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      if (vm.graphic.graphicElements[i].group && vm.graphic.graphicElements[i].configuration != undefined) {
        switch (vm.graphic.graphicElements[i].configuration) {
          case 0:
            timeS = 'minutes';
            // timeI = 10;
            numTotal = getDifferenceIn10Minutes();
            break;
          case 1:
            timeS = 'hours';
            // timeI = 1;
            numTotal = getDifferenceInHours();
            break;
          case 2:
            timeS = 'days';
            // timeI = 1;
            numTotal = getDifferenceInDays();
            break;
          case 3:
            timeS = 'weeks';
            // timeI = 1;
            numTotal = getDifferenceInWeeks();
            break;
          case 4:
            timeS = 'months';
            // timeI = 1;
            numTotal = getDifferenceInMonths();
            break;
        }
      } else {
        switch (vm.filter) {
          case '0':
            timeS = 'minutes';
            // timeI = 10;
            numTotal = getDifferenceIn10Minutes();
            break;
          case '1':
            timeS = 'hours';
            // timeI = 1;
            numTotal = getDifferenceInHours();
            break;
          case '2':
            timeS = 'days';
            // timeI = 1;
            numTotal = getDifferenceInDays();
            break;
          case '3':
            timeS = 'weeks';
            // timeI = 1;
            numTotal = getDifferenceInWeeks();
            break;
          case '4':
            timeS = 'months';
            // timeI = 1;
            numTotal = getDifferenceInMonths();
            break;
        }
      }
    }

    function updateRangeDates($event) {
      const range = $event.detail;
      [vm.graphic.from, vm.graphic.to] = range;
      loadDataSensors(true);
    }

    function loadDataSensors(origen) {
      vm.isChartDataLoaded = false;

      vm.chartData = {
        time: {
          useUTC: false,
        },
        title: {
          text: '',
        },
        tooltip: {
          shared: false,
          style: {
            fontSize: 'var(--vegga-text-md-font-size)',
          },
        },
        chart: {
          alignTicks: false,
          zoomType: 'x',
          type: 'spline',
        },
        credits: {
          enabled: false,
        },
        legend: {
          itemStyle: {
            fontSize: '13px',
          },
        },
        series: [],
        plotOptions: {
          column: {
            pointPlacement: 'between',
          },
          series: {
            states: {
              hover: {
                enabled: true,
                lineWidth: 2,
              },
            },
          },
        },
        xAxis: {
          type: 'datetime',
          labels: {
            style: {
              fontSize: '13px',
            },
          },
        },
        yAxis: [
          {
            visible: true,
            title: {
              text: '',
            },
            labels: {
              style: {
                fontSize: '13px',
                fontWeight: 'normal',
              },
            },
          },
        ],
        exporting: {
          filename: vm.graphic.name,
          enabled: true,
          buttons: {
            contextButton: {
              menuItems: ['viewFullscreen', 'separator', 'downloadCSV', 'downloadXLS', 'downloadJPEG'],
            },
          },
        },
      };

      let querys = [],
        options = [],
        arrayBetDates;
      if (origen) {
        if (chart) {
          dataSeries = [];
          _.forEach(chart.series, (o) => {
            dataSeries.push([o.index, o.visible]);
          });
        }
        loadGraficas();
        let from = new moment(vm.graphic.from).format('YYYY-MM-DD');
        let to = new moment(vm.graphic.to).format('YYYY-MM-DD');
        setFilterG('group_', vm.filter, vm.graphic.pk.userId + '-' + vm.graphic.pk.id);
        setFilterG('period_', vm.graphic.period, vm.graphic.pk.userId + '-' + vm.graphic.pk.id);

        if (vm.graphic.period == 99) {
          setFilterG('from_', from, vm.graphic.pk.userId + '-' + vm.graphic.pk.id);
          setFilterG('to_', to, vm.graphic.pk.userId + '-' + vm.graphic.pk.id);
        }
      }

      for (let i = 0; i < vm.graphic.graphicElements.length; i++) {
        let num = i;
        if (vm.graphic.graphicElements[i].sensor === null) continue;

        params_date = {
          from: new moment(vm.graphic.from).format('DD-MM-YYYY'),
          to: new moment(vm.graphic.to).add(1, 'days').format('DD-MM-YYYY'),
          group:
            vm.graphic.graphicElements[i].group === true && vm.graphic.graphicElements[i].configuration !== null
              ? vm.graphic.graphicElements[i].configuration
              : vm.filter,
        };

        switch (vm.graphic.graphicElements[i].type) {
          case 1:
            //Sensor Analogico
            querys.push(getAnalog(vm.graphic.graphicElements[i].device, vm.graphic.graphicElements[i].sensor));
            options.push(false);
            break;
          case 2:
            //Sensor Contador Caudal
            querys.push(getMeter(vm.graphic.graphicElements[i].device, vm.graphic.graphicElements[i].sensor));
            options.push(false);
            break;
          case 3:
            //Sensor Contador Consumo
            querys.push(
              graphicsFactory.getMeterConsumption(
                UserData.id,
                vm.graphic.graphicElements[i].device,
                vm.graphic.graphicElements[i].sensor,
                params_date
              )
            );
            options.push(true);
            break;
          case 4:
            //Sector Consumo
            params_sector = {
              from: new moment(vm.graphic.from).format('DD-MM-YYYY'),
              to: new moment(vm.graphic.to).add(1, 'days').format('DD-MM-YYYY'),
              group:
                vm.graphic.graphicElements[i].group === true && vm.graphic.graphicElements[i].configuration !== null
                  ? vm.graphic.graphicElements[i].configuration
                  : vm.filter,
              type: vm.graphic.graphicElements[i].unitGraphic,
            };
            if (vm.graphic.graphicElements[i].fertilizer !== 0 && vm.graphic.graphicElements[i].numFertilizer > 0) {
              querys.push(
                getSectorFert(
                  vm.graphic.graphicElements[i].device,
                  vm.graphic.graphicElements[i].sensor,
                  vm.graphic.graphicElements[i].numFertilizer
                )
              );
            } else {
              querys.push(getSector(vm.graphic.graphicElements[i].device, vm.graphic.graphicElements[i].sensor));
            }
            options.push(true);
            break;

          case 5:
            //Sensor PDR
            querys.push(getPDR(vm.graphic.graphicElements[i].userSensor, vm.graphic.graphicElements[i].sensor));
            break;

          case 6:
            //Sensor ETO
            querys.push(getETO(vm.graphic.graphicElements[i].userSensor, vm.graphic.graphicElements[i].sensor));
            options.push(true);
            break;
          case 7:
            //Sensor ETC
            querys.push(getSectorETC(vm.graphic.graphicElements[i].device, vm.graphic.graphicElements[i].sensor));
            options.push(true);
            break;
          case 8:
            //Sensor DPV
            querys.push(getDPV(vm.graphic.graphicElements[i].userSensor, vm.graphic.graphicElements[i].sensor));
            options.push(false);
            break;
          case 9:
            //Sensor Agua disponible
            querys.push(
              getWaterDisp(vm.graphic.graphicElements[i].userSensor, vm.graphic.graphicElements[i].sensor, num)
            );
            options.push(false);
            break;
          case 10:
            //Sensor Analogico - Integrado
            querys.push(getIntegration(vm.graphic.graphicElements[i].device, vm.graphic.graphicElements[i].sensor));
            options.push(true);
            break;
          case 11:
            //Sector Caudales
            params_sector = {
              from: new moment(vm.graphic.from).format('DD-MM-YYYY'),
              to: new moment(vm.graphic.to).add(1, 'days').format('DD-MM-YYYY'),
              group:
                vm.graphic.graphicElements[i].group === true && vm.graphic.graphicElements[i].configuration !== null
                  ? vm.graphic.graphicElements[i].configuration
                  : vm.filter,
              type: vm.graphic.graphicElements[i].unitGraphic,
            };
            querys.push(getFlowSector(vm.graphic.graphicElements[i].device, vm.graphic.graphicElements[i].sensor));
            options.push(true);
            break;

          // case 12:
          //     //Sector Lamina de Riego
          //     getAnalog(vm.graphic.graphicElements[i].device, vm.graphic.graphicElements[i].sensor).then(result => {
          //         processData(result, "line", origen, num);
          //     })
          // break;
        }
      }

      if (querys.length > 0) {
        $q.all(querys).then(function (result) {
          for (let i = 0; i < result.length; i++) {
            let typeUnit = _.filter($state.params.unitList, function (o) {
              return o.device.id == vm.graphic.graphicElements[i].device && o.device.type == 2;
            });
            if (
              vm.graphic.graphicElements[i].graphicType == 'line' ||
              vm.graphic.graphicElements[i].graphicType == 'areaspline'
            ) {
              if (vm.graphic.graphicElements[i].type == 4 && typeUnit) {
                timeS = 'days';
                // timeI = 1;
                numTotal = getDifferenceInDays();
              } else {
                getAttributes(i);
              }
              if (numTotal != result[i].length && result[i].length > 0) {
                arrayBetDates = getArrayDates(timeS, result[i][0].unit);
                arrayBetDates.map(
                  (bet) => (bet.date = new moment(bet.date, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'))
                );
                let data_mix = result[i].concat(arrayBetDates);
                data_mix = _.uniqBy(data_mix, 'date');
                /** Fem merge del array creat en blanc comparant
                 * que la data del 'row' no existeix al array original  */
                result[i] = _.unionBy(result[i], arrayBetDates, (o) => {
                  return new moment(o.date, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm');
                });
                result[i] = _.sortBy(data_mix, function (value) {
                  return new moment(value.date, 'DD-MM-YYYY HH:mm').unix();
                });
              }
            }
            processData(result[i], i, options[i]);
          }
        });
      }
      arrayUnits = [];
    }

    function getDifferenceInMonths() {
      return new moment(vm.graphic.to).add(1, 'days').diff(new moment(dateToF), 'months');
    }

    function getDifferenceInWeeks() {
      return new moment(vm.graphic.to).add(1, 'days').diff(new moment(dateToF), 'weeks');
    }

    function getDifferenceInDays() {
      return new moment(vm.graphic.to).add(1, 'days').diff(new moment(dateToF), 'days');
    }

    function getDifferenceInHours() {
      return new moment(vm.graphic.to).add(1, 'days').diff(new moment(dateToF), 'hours');
    }

    function getDifferenceIn10Minutes() {
      let min = new moment(vm.graphic.to).diff(new moment(dateToF), 'minutes');
      return parseInt((min / 10).toFixed(0));
    }
    /** Funció que retorna un dataset amb N valors */
    function getArrayDates(timeS, unit) {
      let dateArray = [];
      _.times(numTotal, (x) => {
        dateArray.push({
          date: new moment(vm.graphic.from).add(x, timeS).format('DD-MM-YYYY HH'),
          value: null,
          unit: unit,
        });
      });
      return dateArray;
    }

    function visibleSerie(serieName) {
      if (dataSeries.length > 0) {
        vis = dataSeries[serieName];
        return (vis = vis[1]);
      } else {
        return (vis = true);
      }
    }

    function processData(data, i, agregate) {
      const veggaOverlayContent = document.getElementsByTagName('vegga-overlay-content');
      if (veggaOverlayContent && veggaOverlayContent.length > 0) {
        const contentSize = veggaOverlayContent[0].clientHeight;
        const overlayPaddingTop = 28;
        const filtersHeight = 134;
        const totalHeight = contentSize - overlayPaddingTop - filtersHeight;
        const graphicsDiv = document.getElementById('graficas');
        if (graphicsDiv) {
          graphicsDiv.style.height = `${totalHeight}px`;
        }
      }

      let sum = 0,
        idem;
      if (data.length > 0) {
        let arrayData = [];
        data.forEach(function (valor, indice) {
          let date_timestamp;

          //el format podria variar depenen si selecciones min/hora/dia/setmana...
          if (
            vm.graphic.graphicElements[i].graphicType === 'column' ||
            vm.graphic.graphicElements[i].graphicType === 'scatter'
          ) {
            let g = vm.filter;
            if (vm.graphic.graphicElements[i].group && vm.graphic.graphicElements[i].configuration !== null) {
              g = vm.graphic.graphicElements[i].configuration + '';
            }
            switch (g) {
              case '0':
              case '1':
                date_timestamp = new moment(data[indice].date, 'DD-MM-YYYY HH:mm').format('x');
                break;
              case '2':
              case '3':
                date_timestamp = new moment(data[indice].date, 'DD-MM-YYYY').format('x');
                break;
              case '4':
                date_timestamp = new moment(data[indice].date, 'DD-MM-YYYY HH:mm').format('x');
                break;
            }
          } else {
            date_timestamp = new moment(data[indice].date, 'DD-MM-YYYY HH:mm').format('x');
          }

          date_timestamp = parseInt(date_timestamp);
          arrayData.push([date_timestamp, data[indice].value]);
          if (agregate) {
            sum = sum + data[indice].value;
          }
        });

        if (agregate) {
          if (
            (vm.graphic.graphicElements[i].group === true &&
              vm.graphic.graphicElements[i].configuration !== 1 &&
              vm.graphic.graphicElements[i].configuration !== 0) ||
            (vm.showGroup === true && parseInt(vm.filter) !== 0 && parseInt(vm.filter) !== 1)
          ) {
            vm.graphic.showAcumulate = true;
            vm.graphic.graphicElements[i].acumulate = _.round(sum, 2);
          }
        }

        vm.graphic.graphicElements[i].unitList = data[0].unit;

        if (vm.graphic.graphicElements[i].length > 4 && !(vm.graphic.graphicElements[i].pk.id % 2)) positionY = true;
        else positionY = false;

        let nameLabel;
        if (vm.graphic.graphicElements[i].deviceName !== null) {
          nameLabel =
            vm.graphic.graphicElements[i].deviceName +
            ' ' +
            vm.graphic.graphicElements[i].sensorName +
            ' ' +
            (vm.graphic.graphicElements[i].groupName || '');
        } else
          nameLabel = vm.graphic.graphicElements[i].sensorName + ' ' + (vm.graphic.graphicElements[i].groupName || '');

        if (vm.graphic.graphicElements[i].minAxis === vm.graphic.graphicElements[i].maxAxis) {
          vm.graphic.graphicElements[i].minAxis = null;
          vm.graphic.graphicElements[i].maxAxis = null;
        }
        if (vm.graphic.graphicElements[i].maxAxis === 0) vm.graphic.graphicElements[i].maxAxis = null;
        if (vm.graphic.graphicElements[i].minAxis === 0) vm.graphic.graphicElements[i].minAxis = null;

        idem = _.find(arrayUnits, {
          1: data[0].unit,
          2: vm.graphic.graphicElements[i].minAxis,
          3: vm.graphic.graphicElements[i].maxAxis,
        });

        if (dataSeries.length === 0) vis = true;
        else vis = visibleSerie(i);

        if (!idem) {
          arrayUnits.push([
            vm.graphic.graphicElements[i].pk.id,
            data[0].unit,
            vm.graphic.graphicElements[i].minAxis,
            vm.graphic.graphicElements[i].maxAxis,
          ]);

          vm.chartData.yAxis.push({
            // Secondary yAxis
            id: vm.graphic.graphicElements[i].pk.id,
            title: {
              text: data[0].unit,
              style: {
                fontSize: '13px',
              },
            },
            labels: {
              style: {
                fontSize: '13px',
              },
            },
            showEmpty: false,
            lineWidth: 1,
            max: vm.graphic.graphicElements[i].maxAxis,
            min: vm.graphic.graphicElements[i].minAxis,
            //lineColor: vm.graphic.graphicElements[i].colour,//default #ccd6eb
            opposite: positionY,
          });

          if (!vm.graphic.graphicElements[i].showAxisY) {
            vm.chartData.yAxis.forEach((item) => {
              item.update({
                labels: {
                  enabled: false,
                },
                title: {
                  text: null,
                },
              });
            });
          }

          if (vm.graphic.graphicElements[i].graphicType === 'scatter') {
            vm.chartData.series.push({
              name: nameLabel,
              index: vm.graphic.graphicElements[i].pk.id,
              legendIndex: vm.graphic.graphicElements[i].pk.id,
              type: vm.graphic.graphicElements[i].graphicType,
              yAxis: vm.graphic.graphicElements[i].pk.id,
              data: arrayData,
              visible: vis,
              tooltip: {
                valueSuffix: ' ' + data[0].unit,
              },
              color: vm.graphic.graphicElements[i].colour,
              lineWidth: 0,
              marker: {
                enabled: true,
                states: {
                  hover: {
                    enabled: true,
                  },
                },
                radius: 5,
              },
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
            });
          } else {
            vm.chartData.series.push({
              name: nameLabel,
              index: vm.graphic.graphicElements[i].pk.id,
              legendIndex: vm.graphic.graphicElements[i].pk.id,
              type:
                vm.graphic.graphicElements[i].graphicType === 'line'
                  ? 'spline'
                  : vm.graphic.graphicElements[i].graphicType,
              yAxis: vm.graphic.graphicElements[i].pk.id,
              data: arrayData,
              visible: vis,
              tooltip: {
                valueSuffix: ' ' + data[0].unit,
              },
              color: vm.graphic.graphicElements[i].colour,
            });
          }
        } else {
          if (vm.graphic.graphicElements[i].graphicType === 'scatter') {
            vm.chartData.series.push({
              name: nameLabel,
              index: idem[0],
              legendIndex: idem[0],
              type: vm.graphic.graphicElements[i].graphicType,
              yAxis: idem[0],
              data: arrayData,
              visible: vis,
              tooltip: {
                valueSuffix: ' ' + data[0].unit,
              },
              color: vm.graphic.graphicElements[i].colour,
              lineWidth: 0,
              marker: {
                enabled: true,
                states: {
                  hover: {
                    enabled: true,
                  },
                },
                radius: 5,
              },
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
            });
          } else {
            vm.chartData.series.push({
              name: nameLabel,
              index: idem[0],
              legendIndex: idem[0],
              type:
                vm.graphic.graphicElements[i].graphicType === 'line'
                  ? 'spline'
                  : vm.graphic.graphicElements[i].graphicType,
              yAxis: idem[0],
              data: arrayData,
              visible: vis,
              tooltip: {
                valueSuffix: ' ' + data[0].unit,
              },
              color: vm.graphic.graphicElements[i].colour,
            });
          }
        }
      } else {
        let nameLabel;
        if (vm.graphic.graphicElements[i].deviceName !== null) {
          nameLabel =
            vm.graphic.graphicElements[i].deviceName +
            ' ' +
            vm.graphic.graphicElements[i].sensorName +
            ' ' +
            (vm.graphic.graphicElements[i].groupName || '');
        } else {
          nameLabel = vm.graphic.graphicElements[i].sensorName + ' ' + (vm.graphic.graphicElements[i].groupName || '');
          vm.chartData.series.push({
            name: nameLabel,
          });
        }
      }
      getChartHeight();
      vm.isChartDataLoaded = true;

      vm.chartData = {
        ...vm.chartData,
        series: vm.chartData.series.map((serie) => ({ ...serie, data: serie.data ?? [] })),
      };
    }

    function getChartHeight() {
      const graphFiltersHeight = document.querySelector('.graph-filters');
      const overlayContentContainer = document.querySelector('vegga-overlay-content');
      const graphHeight =
        overlayContentContainer.offsetHeight - graphFiltersHeight.offsetHeight - graphFiltersHeight.offsetHeight;
      vm.graphHeight = graphHeight > 400 ? graphHeight : 400;
    }

    function loadGraficas() {
      // let id = 'graficas';
    }

    function backState() {
      $state.go('graphics.list', { graphic: null });
    }

    function groupValidation(period) {
      vm.dateValidation.m =
        vm.dateValidation.h =
        vm.dateValidation.d =
        vm.dateValidation.w =
        vm.dateValidation.mo =
          true;
      switch (period) {
        case 0:
          break;
        case 6:
        case 14:
        case 29:
        case 99:
          if (vm.filter === '0') vm.filter = '1';
          vm.dateValidation.m = false;
          break;
      }
    }

    $scope.$watch('vm.filter', () => {
      if (vm.filter === '1' && vm.graphic.period === 99) changeTo();
      switch (vm.filter) {
        case '1':
          vm._7days = true;
          vm._15days = true;
          break;
        case '2':
        case '3':
        case '4':
          vm._7days = true;
          vm._15days = true;
          vm._30days = true;
          vm.betweenDays = true;
          break;
      }
    });

    function exportChartData(type) {
      chart.exportChartLocal({ type: type });
    }
  }
})();
