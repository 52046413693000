import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';
import { exportToDocument } from '@vegga/front-utils';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    //TODO - check moment
    .constant('moment', moment)

    .controller('historyLogicsController', historyLogicsController);

  historyLogicsController.$inject = [
    '$scope',
    '$state',
    'UserData',
    'historyFactory',
    '$filter',
    'unitFactory',
    'historyUtilsFactory',
    '$rootScope',
  ];

  function historyLogicsController(
    $scope,
    $state,
    UserData,
    historyFactory,
    $filter,
    unitFactory,
    historyUtilsFactory,
    $rootScope
  ) {
    var vm = this;
    vm.filter = {};
    var loaded;

    vm.UNITS = UNITS;
    vm.destroy$ = new Subject();

    vm.currentPage = 1;
    vm.itemsPerPage = 15;
    vm.totalData;

    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.currentState = $state.includes;

      vm.from = historyUtilsFactory.from;
      vm.to = historyUtilsFactory.to;
      vm.minDateValue = historyUtilsFactory.getMinDate(UserData.profesional);
      vm.maxDateValue = historyUtilsFactory.maxDateValue;
      vm.checkDates10min = checkDates10min;

      vm.updateRangeDates = updateRangeDates;

      vm.loadHistory = loadHistory;
      vm.getHistory = getHistory;

      vm.pageChangeHandler = pageChangeHandler;
      vm.pageSizeChangeHandler = pageSizeChangeHandler;

      vm.exportXLS = exportXLS;
      vm.hstySLogicsData = [];

      vm.groupBy = historyUtilsFactory.getGroupBy();
      vm.sensorListDTO = [{ text: $filter('translate')('sensors.all'), value: '0' }];

      vm.filter.groupBy = '2';
      vm.filter.sensor = '0';

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.currentUnit = currentUnit;
      getColumnDefinition(vm.currentUnit.type);
      loadHistory();
    }

    function getColumnDefinition(type) {
      switch (type) {
        case UNITS.A_4500:
          return (vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            { field: 'sensor' },
            {
              field: 'sensorName',
              width: 150,
            },
            { field: 'avg' },
            { field: 'max' },
            { field: 'min' },
            { field: 'sensorError' },
          ]));
        default:
          return [];
      }
    }

    function checkDates(i) {
      if (i.from === undefined) {
        return i[2] + ' - ' + i[3];
      } else {
        return i.from + ' - ' + i.to;
      }
    }
    function checkDates10min() {
      if (vm.filter.groupBy === '0') {
        if (vm.filter.sensor === 0) {
          vm.filter.sensor = 1;
        }
        vm.maxDate = moment(vm.from).add(1, 'day')._d;
        vm.to = moment(vm.from).add(1, 'day')._d;
      } else {
        vm.maxDate = null;
      }
    }

    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
      loadHistory();
    }

    function loadHistory(exporting) {
      var params = {
        //TODO: We have to add 1 day maybe becaue in the backend the check is "lower than".
        from: vm.from,
        to: moment(vm.to, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY'),
        type: vm.currentUnit.type,
        filter: vm.filter,
        page: vm.currentPage,
        limit: vm.itemsPerPage,
      };

      if (exporting) {
        $rootScope.$emit('preventSkeleton', true);
        params = { ...params, limit: vm.totalData ? vm.totalData : 1 };
      }

      return historyFactory.logics(vm.currentUnit.id, params).then((data) => {
        var logic = data;
        vm.totalData = data.total;

        if (logic.content !== undefined && logic.content.length > 0) {
          vm.hstySLogicsData = logic.content.map((i) => {
            return {
              from: checkDates(i),
              sensor: i[4],
              sensorName: i[5],
              avg: i[6],
              max: i[7],
              min: i[8],
              sensorError: i[10],
            };
          });
          if (exporting) {
            exportToDocument('xls', vm.gridColumnsDef, vm.hstySLogicsData, {
              fileName: $filter('translate')('history.logicSensorHistory'),
            });
          }
        } else {
          vm.hstySLogicsData = [];
          if (exporting) {
            exportToDocument('xls', vm.gridColumnsDef, vm.hstySLogicsData, {
              fileName: $filter('translate')('history.logicSensorHistory'),
            });
          }
        }
      });
    }

    function pageChangeHandler(event) {
      vm.currentPage = event.detail;
      loadHistory();
    }

    function pageSizeChangeHandler(event) {
      vm.itemsPerPage = event.detail;
      vm.currentPage = 1;
      loadHistory();
    }

    function exportXLS() {
      loadHistory(true);
    }

    function getHistory() {
      historyFactory.getHistory(vm.currentUnit.type, vm.currentUnit.id);
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
