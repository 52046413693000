(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('traysConfigControllerA7', traysConfigControllerA7);

  traysConfigControllerA7.$inject = ['$scope', '$state', 'trays7Factory', '$confirm', '$filter'];

  function traysConfigControllerA7(
    $scope,
    $state,

    trays7Factory,
    $confirm,
    $filter
  ) {
    var vm = this;
    vm.trays;
    activate();
    vm.sectorRange = _.range(1, 201);

    function activate() {
      vm.currentUnit = $state.params.unit;

      vm.selectTray = loadtrays;
      vm.lastTray = lastTray;
      vm.nextTray = nextTray;
      vm.cancel = cancel_edition;
      vm.save = save;
      vm.changeTray = changeTray;
      vm.changingTray = changingTray;
      vm.nextOrPreviousItem = nextOrPreviousItem;
      loadtrays(1);
    }

    function loadtrays(n) {
      var params = { page: n, limit: 1 };
      vm.trays = [];
      vm.loading = true;
      vm.tray = {};
      return trays7Factory.getTrays($state.params.unit.id, params).then(function (data) {
        vm.paginator = data;
        vm.trays = vm.paginator.content;
        angular.copy(vm.paginator.content[0], vm.tray);

        vm.totals = _.range(1, vm.paginator.totalPages + 1);
        vm.page = vm.paginator.number + 1;
        selectFormat();
        vm.loading = false;

        backup();
      });
    }

    function selectFormat() {
      if (vm.tray.formatId > 0) {
        vm.format = vm.formats[vm.sensor.formatId - 1];
      } else {
        vm.format = { decimals: 0, integers: 0, units: '-' };
      }
    }

    function nextTray() {
      ++vm.page;
      if (vm.page > vm.paginator.totalPages - 1) {
        vm.page = 1;
      }
      loadtrays(vm.page);
    }

    function lastTray() {
      --vm.page;
      if (vm.page === 0) {
        vm.page = vm.paginator.totalPages - 1;
      }
      loadtrays(vm.page);
    }

    function backup() {
      vm.tray_backup = {};
      angular.copy(vm.tray, vm.tray_backup);
      vm.tray.backup = true;
    }

    function cancel_edition() {
      angular.copy(vm.tray_backup, vm.tray);
    }

    function save() {
      trays7Factory.update(vm.tray).then(
        (response) => {
          vm.tray = response;

          angular.copy(vm.tray, vm.tray_backup);
          vm.tray.backup = true;
          vm.form.$setPristine();
        },
        (error) => {}
      );
    }

    function changeTray(e) {
      if (e.detail.value) {
        vm.page = e.detail.value;
        loadtrays(vm.page);
      }
    }

    function changingTray($event) {
      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeSector($event);
          break;
      }
    }

    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
