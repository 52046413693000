import { getContainer } from '@vegga/front-store';
import { Subject, takeUntil } from 'rxjs';
import { A2500_DEFAULT_CONFIG, ABIT_DEFAULT_CONFIG } from '../../utils/device-config';
import { UNITS } from '../../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('programsEditControllerA25', programsEditController);

  programsEditController.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    'progFactory',
    'configFactory',
    'veggaSelectFactory',
  ];

  function programsEditController($scope, $rootScope, $state, progFactory, configFactory, veggaSelectFactory) {
    var vm = this;

    vm.activeList;
    vm.form;
    vm.cancel;
    vm.save;
    vm.checkdirty;
    vm.deleteProgram;
    vm.prepareSectorsToUpdate;
    vm.resetProgramType;
    vm.deleteSubprogram;
    vm.addSubprogram;
    vm.removeSelectedSector;
    vm.fertNum;
    vm.checkEditConditioner;
    vm.checkAllConditioners;
    vm.getSensorFormat;
    vm.fixUnit;
    vm.formProgram;
    vm.autofocusElement;

    vm.showIrrigationUnits;
    vm.hideIrrigationUnits;
    vm.toggleActivePeriod = toggleActivePeriod;
    vm.checkdirty = checkdirty;
    vm.deleteProgram = deleteProgram;
    vm.cancel = cancel_edition;
    vm.save = save;
    vm.selectPrograms = _.range(1, 35);
    vm.sectors = _.range(1, 31);
    vm.programsList = _.range(0, 51);
    vm.updateRangeDates = updateRangeDates;
    vm.updateActivationTime = updateActivationTime;
    vm.UNITS = UNITS;

    vm.destroy$ = new Subject();
    vm.programsFacade = getContainer().resolve('programsFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      initSubscriptions();
      vm.currentState = $state.includes;
    }

    function initSubscriptions() {
      vm.programsFacade.currentProgram$.subscribe((prog) => (vm.programSelected = prog));

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
        }

        vm.currentState = $state;
        vm.currentUnit = currentUnit;
      });

      vm.programsFacade.currentProgram$.subscribe((prog) => {
        vm.editProgram = prog;
        backup();
        vm.sectorsDTO = veggaSelectFactory.formatterVeggaSelect(vm.sectors);
        vm.sectorsDTO = veggaSelectFactory.addedDefaultOption(vm.sectorsDTO, 0, '{{programs.nothing}}');

        parseStartEndActivationDay();

        if (vm.currentUnit.installer.decimalsIrrigation == 0) {
          vm.inputInteger = 5;
          vm.inputDecimal = 0;
        } else if (vm.currentUnit.installer.decimalsIrrigation == 1) {
          vm.inputInteger = 4;
          vm.inputDecimal = 1;
        }

        // This branch can never execute. Its condition is a duplicate or covered by previous conditions in the if-else-if chain

        // else if (vm.currentUnit.installer.decimalsIrrigation == 0) {
        //   vm.inputInteger = 3;
        //   vm.inputDecimal = 2;
        // }

        if (vm.currentUnit.inoptions.plus || vm.currentUnit.type === vm.UNITS.A_BIT) {
          vm.unitValues = ABIT_DEFAULT_CONFIG.measurementUnits.irrigation;
        } else {
          vm.unitValues = A2500_DEFAULT_CONFIG.measurementUnits.irrigation;
        }

        vm.irrigationMeasureUnit = vm.unitValues.find(
          (unit) => unit.deviceMeasurementUnitKey === vm.editProgram.unit
        ).measurementUnit.symbol;

        if (vm.currentUnit.type === vm.UNITS.A_2500) {
          vm.unitFertValues = A2500_DEFAULT_CONFIG.measurementUnits.fertilization;
        }

        if (vm.currentUnit.type === vm.UNITS.A_BIT) {
          vm.unitFertValues = ABIT_DEFAULT_CONFIG.measurementUnits.fertilization;
        }

        if (vm.currentUnit.type === vm.UNITS.A_2500) {
          var number_fertilizers = vm.currentUnit.fertilizer.fertNum;
          vm.fertilizerDecimals = vm.currentUnit.fertilizer.fertDecimals;
          vm.fertilizerType = vm.currentUnit.fertilizer.fertType;
          vm.fertilizerUnits = vm.currentUnit.fertilizer.fertUnits;
          vm.fertNum = _.range(0, number_fertilizers);
          vm.maxSectors = vm.currentUnit.installer.programSectors;

          vm.fertilizerMeasureUnit = vm.unitFertValues.find(
            (unit) => unit.deviceMeasurementUnitKey === vm.fertilizerUnits
          ).measurementUnit.symbol;
        }

        if (vm.currentUnit.type === vm.UNITS.A_BIT) {
          vm.currentUnit.installer.decimalsIrrigation = 0;
          var params = {};
          configFactory.get(vm.currentUnit.id, params).then((result) => {
            vm.config = result.plain();

            vm.fertilizerMeasureUnit = vm.unitFertValues.find(
              (unit) => unit.deviceMeasurementUnitKey === vm.config.fertilizerUnits
            ).measurementUnit.symbol;
            vm.maxSectors = vm.config.programSectors;
          });
        }

        vm.activeHour = vm.currentUnit.installer.activeSchedule;

        vm.activePeriod = vm.currentUnit.installer.activePeriod;

        vm.programsListDTO = veggaSelectFactory.formatterVeggaSelect(vm.programsList);

        parseLiters();
        parseFertilizersToLiters();
      });

      vm.programsFacade.isLoading$.pipe(takeUntil(vm.destroy$)).subscribe((isLoading) => {
        if (!isLoading) {
          vm.showSkeleton = isLoading;
        }
      });
    }

    function parseStartEndActivationDay() {
      const startDay = vm.editProgram.startActivationDay;
      const startMonth = vm.editProgram.startActivationMonth;
      const endDay = vm.editProgram.endActivationDay;
      const endMonth = vm.editProgram.endActivationMonth;

      [vm.from, vm.to] = [`${startDay}-${startMonth}`, `${endDay}-${endMonth}`];
      vm.isActivePeriodEnabled = startDay && startMonth && endDay && endMonth;
    }

    function toggleActivePeriod($event) {
      vm.isActivePeriodEnabled = $event.detail.checked;
      vm.formProgram.$setDirty();
    }

    function updateRangeDates($event) {
      const range = $event.detail;
      [vm.from, vm.to] = range;
      vm.editProgram.startActivationDay = +range[0].split('-')[0];
      vm.editProgram.startActivationMonth = +range[0].split('-')[1];

      vm.editProgram.endActivationDay = +range[1].split('-')[0];
      vm.editProgram.endActivationMonth = +range[1].split('-')[1];

      vm.formProgram.$setDirty();
    }

    function updateActivationTime($event, key) {
      const time = $event.detail;

      if (key === 'start') {
        vm.editProgram.startActivationHour = time;
      }

      if (key === 'end') {
        vm.editProgram.endActivationHour = time;
      }

      vm.formProgram.$setDirty();
    }

    function parseLiters() {
      vm.irrigValue = vm.editProgram.value;
      /*Converting l to m3*/
      /*if(vm.editProgram.unit === 1 || vm.editProgram.unit === 2 ){
            vm.irrigValue = vm.irrigValue/1000;
        }*/
    }

    function formatLiters() {
      vm.editProgram.value = vm.irrigValue;
      /*Converting m3 to l*/
      /*if(vm.editProgram.unit === 1 || vm.editProgram.unit === 2 ){
            vm.editProgram.value = vm.editProgram.value*1000;
        }*/
    }

    function parseFertilizersToLiters() {
      /*Converting cl to l*/
      if (vm.fertilizerUnits !== 0 && vm.fertilizerUnits !== 3 && vm.fertNum !== undefined) {
        vm.fertNum[0] = vm.editProgram.fertilizer1 / 100;
        vm.fertNum[1] = vm.editProgram.fertilizer2 / 100;
        vm.fertNum[2] = vm.editProgram.fertilizer3 / 100;
        vm.fertNum[3] = vm.editProgram.fertilizer4 / 100;
      }
    }

    function parseFertilizersToCentiliters() {
      /*Converting cl to l*/
      if (vm.fertNum !== undefined) {
        vm.editProgram.fertilizer1 = vm.fertNum[0] * 100;
        vm.editProgram.fertilizer2 = vm.fertNum[1] * 100;
        vm.editProgram.fertilizer3 = vm.fertNum[2] * 100;
        vm.editProgram.fertilizer4 = vm.fertNum[3] * 100;
      }
    }

    function checkdirty(form) {
      if (vm.editProgram.backup) {
        form.$setDirty();
      }
    }

    function backup() {
      vm.program_backup = {};
      angular.copy(vm.editProgram, vm.program_backup);
      vm.editProgram.backup = true;
    }

    function parseActivePeriod() {
      if (vm.isActivePeriodEnabled) return;
      vm.editProgram.startActivationDay = 0;
      vm.editProgram.startActivationMonth = 0;
      vm.editProgram.endActivationDay = 0;
      vm.editProgram.endActivationMonth = 0;
    }

    function save() {
      parseActivePeriod();
      //if(form && form.$valid){
      vm.formProgram.$setPristine();
      vm.editProgram.progtype = vm.currentUnit.type;

      if (vm.fertilizerUnits !== 0 && vm.fertilizerUnits !== 3) {
        parseFertilizersToCentiliters();
      }
      formatLiters();
      progFactory.update(vm.editProgram).then(() => {
        vm.programsFacade.currentProgram = vm.editProgram;
        // $rootScope.$broadcast('updateEdit25', { message: 'refreshing' });
        //$state.go('programs.detail',{unit:vm.currentUnit,program:response.plain()},{reload:true});

        /*$rootScope.toast({
                     type:"success",
                     title:"Guardado correcto"
                 })*/
      });
      //}
    }

    function cancel_edition() {
      angular.copy(vm.program_backup, vm.editProgram);
    }

    function deleteProgram() {
      var model;

      progFactory.getModel(vm.currentUnit.type).then((item) => {
        model = item;
        prepareModel(model);
        progFactory.update(model).then(
          () => {
            $rootScope.$broadcast('updateEdit', { message: 'refreshing' });
            $state.go('a25programs', { unit: vm.currentUnit, program: null });
          },
          () => {
            //TODO ERROR ALERT
          }
        );
      });
    }

    function prepareModel(model) {
      var id = vm.editProgram.pk.id;
      model.pk.id = id;
      model.pk.deviceId = vm.currentUnit.id;
      model.name = 'Programa ' + id;
      _.forEach(model.subprograms, (item) => {
        item.pk.programId = id;
        item.pk.deviceId = vm.currentUnit.id;
      });
      _.forEach(model.conditioners, (item) => {
        item.pk.programId = id;
        item.pk.deviceId = vm.currentUnit.id;
      });
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.formProgram,
      () => {
        $scope.$emit('formUpdated', vm.formProgram);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e) => {
      save(e, vm.formProgram);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
