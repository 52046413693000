import Highcharts from 'highcharts/highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import Draggable from 'highcharts/modules/draggable-points.js';
const colors = require('../../../../resources/vegga.tokens.colors.json');

import moment from 'moment/moment';
HighchartsMore(Highcharts);
Draggable(Highcharts);

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('expertIrrideskChannelController', expertIrrideskChannelController);

  expertIrrideskChannelController.$inject = [
    '$state',
    'irrideskFactory',
    'UserData',
    '$scope',
    'progFactory',
    '$confirm',
    '$filter',
    '$q',
  ];

  function expertIrrideskChannelController(
    $state,
    irrideskFactory,
    UserData,
    $scope,
    progFactory,
    $confirm,
    $filter,
    $q
  ) {
    var vm = this;
    var channel, sensors, subId, prog_wrapper, channels;
    vm.formFactory;
    vm.form;
    activate();

    function activate() {
      if (!$state.params.channel) {
        $state.go('irrideskChannels');
        return;
      }
      vm.currentState = $state.includes;
      vm.channelBackup;
      vm.sensorsBackup;
      vm.wrapperListBackup;
      vm.channelInfo = $state.params.channel;
      vm.menu = [
        { name: 'irridesk.irr21', state: 'supervise' },
        { name: 'irridesk.irr8', state: 'campaign' },
        { name: 'irridesk.irr3', state: 'configuration' },
        //{"name":"irridesk.irr13","state":"units"}
      ];

      /*if(vm.channelInfo.type == 1){
        vm.menu.push({"name":"irridesk.irr2","state":"sensors"})
      }*/

      vm.currentConfigPage = vm.menu[0];
      vm.currentNavigationIndex = 0;

      vm.saveChannel = saveChannel;
      vm.save = save;
      vm.saveUnits = saveUnits;
      vm.loadSeasonalCharts = loadSeasonalCharts;
      vm.cancel = cancel;
      vm.acceptPlan = acceptPlan;
      vm.addWrapper = addWrapper;
      vm.removeWrapper = removeWrapper;
      vm.loadProgToWrapper = loadProgToWrapper;
      vm.loadSensorToWrapper = loadSensorToWrapper;
      vm.addSensorWrapper = addSensorWrapper;
      vm.resetChannel = resetChannel;
      vm.getDeviceName = getDeviceName;
      vm.changeConfigPage = changeConfigPage;
      vm.editChannel = editChannel;
      vm.changeConfigSubpage = changeConfigSubpage;
      vm.deleteChannel = deleteChannel;
      vm.recalcSeasonalPlan = recalcSeasonalPlan;
      vm.generateSeasonalPlan = generateSeasonalPlan;

      // supervisar view
      vm.loadPrescriptionChart = loadPrescriptionChart;
      vm.loadRealIrrigChart = loadRealIrrigChart;
      vm.loadHistory = loadHistory;
      vm.from = moment().subtract(15, 'day')._d;
      vm.to = moment()._d;
      vm.loadAccumulatedChart = loadAccumulatedChart;

      vm.wrapperList = [];
      vm.sensorWrapperList = [];

      subId = $state.params.idSubscription;
      channel = $state.params.channel;
      vm.sensors = $state.params.sensors;
      vm.units = $state.params.units;

      prog_wrapper = {
        automata_id: null,
        idInDevice: '',
        name: '',
        active: true,
        multiplier_mm: 1.0,
        min: 0.0,
        max: 120.0,
      };
      vm.showSeasonalPlan = false;

      checkChannelStatus();

      vm.deviceName = getDevice('name');
      vm.deviceType = getDevice('type');
    }

    function loadProgToWrapper(wrapper) {
      if (wrapper.idInDevice !== null) {
        let id = Number(wrapper.idInDevice);
        if (id > 0) {
          let prog = vm.programs[id - 1];
          wrapper.name = prog.name;
          setWrapperUnits(wrapper, prog);
        }
      }
    }

    function loadSensorToWrapper(wrapper) {
      let sensor = wrapper.sensor;
      wrapper['sensor_name'] = s.sensor_name;
      wrapper['id_in_platform'];
      wrapper['name_in_device'];
      wrapper['sensor_type'] = s.sensor_type;
      wrapper['measurement_type'] = s.measurement_type;
      wrapper['measurement_units'] = s.measurement_units;
    }

    function setWrapperUnits(wrapper, prog) {
      var units;
      switch (vm.deviceType) {
        case 2:
          units = {
            0: 'min', //"hh:mm",
            1: 'sec', //"mm' ss\"",
            2: 'L', //"m3",
            4: 'L',
            16: 'L', //m3/Ha"
          };

          break;
        case 3:
        case 4:
        case 6:
          units = {
            0: 'min', //:"hh:mm",
            1: 'L', //:"m3",
            2: 'L', //:"m3/Ha",
            3: 'sec', //:"mm' ss\"",
            4: 'L', //:"hh:mm/Ha",
          };
          break;
        case 5:
          units = {
            0: 'min', //:"hh:mm",
            1: 'sec', //:"mm' ss\"",
            2: 'L', //:"m3",
            3: 'L', //:"L"
          };
          break;
      }
      wrapper.wpUnit = units[prog.unit];
    }

    function addWrapper() {
      vm.wrapperList.push({
        automata_id: channel.deviceIdIrridesk,
        idInDevice: '',
        name: '',
        active: true,
        multiplier_mm: 1.0,
        min: 0.0,
        max: 120.0,
      });
      vm.form.$setDirty();
    }

    function removeWrapper(index) {
      const wrapper = vm.wrapperList[index];

      if (wrapper?.id != null) {
        $confirm({ text: $filter('translate')('irridesk.delete_label') }).then(() => {
          irrideskFactory.deleteWrapper(channel.irrideskId, wrapper.id).then(() => {
            vm.wrapperList = vm.wrapperList.filter((wrapperTemp) => wrapperTemp.id !== wrapper.id);
            backup();
          });
        });
      } else {
        vm.wrapperList.splice(index, 1);
      }
    }

    function addSensorWrapper() {
      vm.sensorWrapperList.push({
        sensor_name: null,
        id_in_platform: null,
        name_in_device: null,
        sensor_type: null,
        measurement_type: null,
        measurement_units: null,
      });
    }

    function prepareSensors() {}

    function getDevice(attr) {
      let result = _.filter(vm.units, (o) => {
        return o.device.id === channel.deviceId;
      });
      if (result.length > 0) {
        return result[0].device[attr];
      } else {
        ('-');
      }
    }

    function prepareDeviceData() {
      progFactory.programs(channel.deviceId).then((result) => {
        vm.programs = result.plain();

        irrideskFactory.getChannelWrappers(channel.irrideskId).then((result) => {
          if (result && result.program_wrappers) {
            loadWrappersToForm(result.program_wrappers);
            backup();
          }
        });
      });
    }

    function checkChannelStatus() {
      vm.status = 1;
      irrideskFactory.getChannel(channel.irrideskId).then((result) => {
        if (result && result.channels) {
          vm.channel = result.channels[0];
          checkSeasonalPlan();
          backup();
        }
      });
      prepareDeviceData();
      prepareSensors();
    }

    function loadWrappersToForm(w) {
      _.forEach(_.sortBy(w, 'idInDevice'), (prog) => {
        loadProgToWrapper(prog);

        vm.wrapperList.push({
          active: prog.active,
          automata: prog.automata,
          channel: prog.channel,
          automata_id: channel.deviceIdIrridesk,
          id: prog.id,
          //program id
          idInDevice: prog.idInDevice,
          max: prog.max,
          min: prog.min,
          minIrrigationPulse: prog.minIrrigationPulse,
          multiplier_mm: prog.multiplier_mm,
          name: prog.name,
          pending: prog.pending,
          url: prog.url,
        });

        //vm.wrapperList.push(prog);
      });
    }

    function saveChannel(acceptSeasonalPlan = false) {
      if ((vm.form && vm.form.$dirty) || acceptSeasonalPlan) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          save(false, acceptSeasonalPlan);
        });
      }
    }

    function generateSeasonalPlan() {
      if (vm.currentSubConfigPage === 'units') {
        saveUnits(true);
      } else {
        saveChannel(true);
      }
    }

    function save(activateChannel = false, acceptSeasonalPlan = false) {
      if (activateChannel) {
        vm.channel.active.current_value = !vm.channel.active.current_value;
      }
      let active = vm.channel.active.current_value;
      let crop_id = vm.channel.crop_id.current_value;
      let irrsys_id = vm.channel.irrsys_id.current_value;
      let name = vm.channel.name.current_value;
      let seasonalConfig_id = vm.channel.seasonalConfig_id.current_value;
      let seasonalPlan_id = vm.channel.seasonalPlan_id.current_value;
      let soil_id = vm.channel.soil_id.current_value;
      let channel_to_send = {
        channels: [
          {
            active: active,
            crop_id: crop_id,
            irrsys_id: irrsys_id,
            name: name,
            seasonalConfig_id: seasonalConfig_id,
            seasonalPlan_id: seasonalPlan_id,
            soil_id: soil_id,
            sensors: [],
            id: vm.channel.id,
          },
        ],
      };

      vm.form.$setPristine();
      vm.channelInfo.name = name;
      irrideskFactory.saveChannel(subId, vm.channel.id, channel_to_send).then((result) => {
        backup();
        if (acceptSeasonalPlan) {
          recalcSeasonalPlan(true);
        }
      });
    }

    function saveUnits(recalcSeasonal = false) {
      if (vm.form && vm.form.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          vm.form.$setPristine();
          irrideskFactory.saveWrappers(subId, vm.channel.id, vm.wrapperList).then((result) => {
            backup();
            if (recalcSeasonal) {
              recalcSeasonalPlan(recalcSeasonal);
            }
          });
        });
      }
    }

    function resetChannel() {
      $confirm({
        text: $filter('translate')('irridesk.resetChannel'),
        title: $filter('translate')('irridesk.irr1'),
      }).then(() => {
        irrideskFactory.resetChannel(channel.pk.subscriptionId, channel.pk.id).then((result) => {
          $state.go('irrideskChannels', { units: vm.units }, { reload: 'irrideskChannels' });
        });
      });
    }

    function deleteChannel() {
      $confirm({
        text: $filter('translate')('irridesk.deleteChannel'),
        title: $filter('translate')('irridesk.irr1'),
      }).then(() => {
        irrideskFactory.deleteChannel(channel.pk.subscriptionId, channel.id).then((result) => {
          $state.go('irrideskChannels', { units: vm.units }, { reload: 'irrideskChannels' });
        });
      });
    }

    function checkSeasonalPlan() {
      if (vm.channel.seasonalPlan_id && vm.channel.seasonalPlan_id.current_value !== null) {
        vm.showSeasonalPlan = true;
      }
    }

    function loadSeasonalCharts() {
      irrideskFactory.getCampaignPlans(channel.irrideskId).then((result) => {
        const irrigationMinDTO =
          result?.editable_plan?.editable_curves?.range_irrigation?.map((irr) => {
            return [parseInt(moment(irr.day, 'YYYY-MM-DD').format('x')), irr.min];
          }) || [];

        const irrigationMaxDTO =
          result?.editable_plan?.editable_curves?.range_irrigation?.map((irr) => {
            return [parseInt(moment(irr.day, 'YYYY-MM-DD').format('x')), irr.max];
          }) || [];

        const irrigationSeries = [
          {
            type: 'area',
            name: $filter('translate')('irridesk.max_acceptable'),
            data: irrigationMaxDTO,
          },
          {
            type: 'area',
            name: $filter('translate')('irridesk.min_acceptable'),
            data: irrigationMinDTO,
          },
        ];

        createGraphic(
          irrigationSeries,
          'irrigation',
          irrigationMaxDTO,
          'mm',
          $filter('translate')('irridesk.irrigation_graphic_title')
        );

        const comfortMinDTO =
          result?.editable_plan?.editable_curves?.range_comfort?.map((conf) => {
            return [parseInt(moment(conf.day, 'YYYY-MM-DD').format('x')), conf.min];
          }) || [];

        const comfortMaxDTO =
          result?.editable_plan?.editable_curves?.range_comfort?.map((conf) => {
            return [parseInt(moment(conf.day, 'YYYY-MM-DD').format('x')), conf.max];
          }) || [];

        const comfortSeries = [
          {
            type: 'area',
            name: $filter('translate')('irridesk.wet'),
            data: comfortMaxDTO,
          },
          {
            type: 'area',
            name: $filter('translate')('irridesk.dry'),
            data: comfortMinDTO,
          },
        ];

        createGraphic(
          comfortSeries,
          'comfort',
          comfortMaxDTO,
          '',
          $filter('translate')('irridesk.comfort_graphic_title')
        );

        const multiplierDTO =
          result?.editable_plan?.editable_curves?.curve_multiplier?.map((multi) => {
            return [parseInt(moment(multi.day, 'YYYY-MM-DD').format('x')), multi.value];
          }) || [];

        const multiplierSerie = [
          {
            type: 'line',
            name: $filter('translate')('irridesk.irr12'),
            data: multiplierDTO,
          },
        ];

        createGraphic(
          multiplierSerie,
          'multiplier',
          multiplierDTO,
          '',
          $filter('translate')('irridesk.multiplier_graphic_title')
        );

        const vigorMinDTO =
          result?.editable_plan?.editable_curves?.curves_vigor?.map((multi) => {
            return [parseInt(moment(multi.day, 'YYYY-MM-DD').format('x')), multi.min];
          }) || [];

        const vigorMaxDTO =
          result?.editable_plan?.editable_curves?.curves_vigor?.map((multi) => {
            return [parseInt(moment(multi.day, 'YYYY-MM-DD').format('x')), multi.max];
          }) || [];

        const vigorSerie = [
          {
            type: 'line',
            name: $filter('translate')('irridesk.crop_vigor'),
            data: vigorMaxDTO,
          },
          {
            type: 'line',
            name: $filter('translate')('irridesk.grass_vigor'),
            data: vigorMinDTO,
          },
        ];

        createGraphic(vigorSerie, 'vigor', vigorMaxDTO, '', $filter('translate')('irridesk.vigor_graphic_title'));
      });
    }

    function createGraphic(serie, renderId, data, yAxisText, title, zoom) {
      var setExtremes = zoom;
      new Highcharts.Chart({
        chart: {
          renderTo: renderId,
          events: {
            load: function () {
              if (zoom?.length > 0) {
                this.xAxis[0].setExtremes(setExtremes[0], setExtremes[1]);
              }
            },
          },
        },
        title: {
          text: title,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
        },
        exporting: {
          enabled: false,
        },
        plotOptions: {
          series: {
            stickyTracking: false,
            dragDrop: {
              draggableY: false,
            },
            point: {
              events: {
                drop: function (e) {
                  //TODO: post to set new value points
                  console.log('UPDATE POINT');
                },
              },
            },
          },
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          min: 0,
          title: {
            text: yAxisText,
          },
        },
        series: serie,
      });
    }

    function acceptPlan() {
      let seasonalPlan_id = vm.channel.seasonalPlan_id.current_value;
      seasonalPlan_id.accepted = true;

      irrideskFactory
        .acceptPlan(subId, seasonalPlan_id.id, { seasonalPlan_id: [seasonalPlan_id] })
        .then((result) => {});
    }

    function getDeviceName(id) {
      let result = _.filter(vm.units, (o) => {
        return o.device.id === id;
      });
      if (result.length > 0) {
        return result[0].device.name;
      } else {
        return '-';
      }
    }

    function changeConfigPage($event) {
      vm.currentSubConfigPage = null;
      vm.from = moment().subtract(15, 'day')._d;
      vm.to = moment()._d;

      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('irridesk.irr1'),
        }).then(() => {
          if ($event && $event.detail) {
            const item = vm.menu[$event.detail.index];
            if (item && item.state !== vm.currentConfigPage.state) {
              vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
              restoreBackup();
              vm.currentConfigPage = item;
              vm.currentNavigationIndex = $event.detail.index;
              if (vm.currentConfigPage.state === 'configuration') {
                vm.currentSubConfigPage = 'campaign';
              }
            }
          }
        });
      } else {
        if ($event && $event.detail) {
          const item = vm.menu[$event.detail.index];
          vm.currentNavigationIndex = $event.detail.index;
          if (item) {
            vm.currentConfigPage = item;
            if (vm.currentConfigPage.state === 'configuration') {
              vm.currentSubConfigPage = 'campaign';
            }
          }
        }
      }
    }

    function changeConfigSubpage(page) {
      if (vm.form && vm.form.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('irridesk.irr1'),
        }).then(() => {
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          restoreBackup();
          vm.currentSubConfigPage = page;
        });
      } else {
        vm.currentSubConfigPage = page;
      }
    }

    function cancel() {
      //$scope.$parent.vm.showDash = true;
      //$state.go("irrideskChannels",{units:vm.units},{reload:"irrideskChannels"});
      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('irridesk.irr1'),
        }).then(() => {
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          restoreBackup();
        });
      } else {
        restoreBackup();
        vm.form.$setPristine();
      }
    }

    function backup() {
      vm.channelBackup = {};
      vm.sensorsBackup = [];
      vm.wrapperListBackup = [];

      angular.copy(vm.channel, vm.channelBackup);
      angular.copy(vm.sensors, vm.sensorsBackup);
      angular.copy(vm.wrapperList, vm.wrapperListBackup);
    }

    function restoreBackup() {
      angular.copy(vm.channelBackup, vm.channel);
      angular.copy(vm.sensorsBackup, vm.sensors);
      angular.copy(vm.wrapperListBackup, vm.wrapperList);
    }

    // supervisar view
    function loadHistory() {
      //loadRealIrrigChart();
      //loadPrescriptionChart();
      loadAccumulatedChart();
    }

    function loadRealIrrigChart() {
      var params = {
        from: moment(vm.from).format('DD-MM-YYYY'),
        to: moment(vm.to).add(1, 'd').format('DD-MM-YYYY'),
      };
      irrideskFactory.getChannelWrappers(channel.irrideskId).then((result) => {
        if (result && result.program_wrappers) {
          let calls = [];
          let series = [];
          let wrappers = _.filter(result.program_wrappers, 'active');
          _.forEach(wrappers, (p) => {
            calls.push(irrideskFactory.getHistoryProgramMM(channel.deviceId, p.idInDevice, params));
          });
          $q.all(calls).then((result) => {
            _.forEach(result, (raw_data, i) => {
              let prescriptions = _.map(raw_data, (el) => {
                return [parseInt(moment(el[2], 'DD-MM-YYYY 00:00').format('x')), el[0]];
              });
              let mm_acc = _.map(raw_data, (el) => {
                return [parseInt(moment(el[2], 'DD-MM-YYYY 00:00').format('x')), el[1]];
              });
              series.push(
                {
                  name: wrappers[i].name,
                  data: prescriptions,
                },
                {
                  name: 'acc. ' + wrappers[i].name,
                  data: mm_acc,
                  type: 'line',
                }
              );
            });
            prepareRealIrrigChart(series);
          });
        }
      });
    }

    function loadAccumulatedChart() {
      vm.emptyAccumulated = false;

      var params = {
        ts: moment(vm.from).format('YYYY-MM-DD'),
        te: moment(vm.to).add(1, 'd').format('YYYY-MM-DD'),
        //ts: '2023-03-01',
        //te: '2023-03-24',
      };
      irrideskFactory.getChannelAccumulated(channel.irrideskId, params).then((result) => {
        const seriePrescribed = [];
        const serieMeasured = [];
        const accumIrrigation = [];
        if (result && result.channels_daily?.length > 0) {
          result.channels_daily.forEach((data) => {
            seriePrescribed.push([
              parseInt(moment(data.day, 'YYYY-MM-DD').format('x')),
              data.mm_prescribed !== null ? Number(data.mm_prescribed.toFixed(2)) : 0,
            ]);
            serieMeasured.push([
              parseInt(moment(data.day, 'YYYY-MM-DD').format('x')),
              data.mm_measured !== null ? Number(data.mm_measured.toFixed(2)) : 0,
            ]);

            accumIrrigation.push([
              parseInt(moment(data.day, 'YYYY-MM-DD').format('x')),
              data.accumIrrigation !== null ? Number(data.accumIrrigation.toFixed(2)) : 0,
            ]);
          });

          const series = [
            {
              type: 'column',
              name: $filter('translate')('irridesk.prescribed'),
              data: seriePrescribed,
            },
            {
              type: 'column',
              name: $filter('translate')('irridesk.measured'),
              data: serieMeasured,
            },
          ];

          prepareIrrigation(series);
          loadIrrigData(accumIrrigation);
        } else {
          vm.emptyAccumulated = true;
        }
      });
    }

    function loadPrescriptionChart() {
      var params = {
        from: moment(vm.from).format('YYYY-MM-DD'),
        to: moment(vm.to).add(1, 'd').format('YYYY-MM-DD'),
      };
      irrideskFactory.getChannelPrescription(channel.irrideskId, params).then((result) => {
        let series = [];
        if (result && result.channels_prescriptions) {
          if (result.channels_prescriptions[0].length > 0) {
            let data = _.orderBy(result.channels_prescriptions[0], 'day');
            let program_ids = _.uniq(_.map(data, 'program_id'));
            _.forEach(program_ids, (id) => {
              let raw_data = _.filter(data, { program_id: id });
              let prescriptions = _.map(raw_data, (el) => {
                return [
                  parseInt(moment(el.day, 'YYYY-MM-DD 00:00').format('x')),
                  el.mm !== null ? Number(el.mm.toFixed(2)) : 0,
                ];
              });
              let mm_acc = _.map(raw_data, (el) => {
                return [
                  parseInt(moment(el.day, 'YYYY-MM-DD 00:00').format('x')),
                  el.mm_acc !== null ? Number(el.mm_acc.toFixed(2)) : 0,
                ];
              });
              series.push(
                {
                  name: raw_data[0].program_name,
                  data: prescriptions,
                },
                {
                  name: 'acc. ' + raw_data[0].program_name,
                  data: mm_acc,
                  type: 'line',
                }
              );
            });
          }
        }

        preparePrescriptionChart(series);
      });
    }

    function preparePrescriptionChart(series) {
      new Highcharts.Chart({
        chart: {
          renderTo: 'prescriptionchart',
          type: 'column',
          reflow: false,
          style: {
            overflow: 'visible',
          },
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
        },
        exporting: {
          enabled: false,
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Prescription mm',
          },
          stackLabels: {
            enabled: false,
            style: {
              fontWeight: 'bold',
              color: 'gray',
            },
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: series,
      });
    }

    function prepareRealIrrigChart(series) {
      new Highcharts.Chart({
        chart: {
          renderTo: 'realirrigchart',
          type: 'column',
          reflow: false,
          style: {
            overflow: 'visible',
          },
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
        },
        exporting: {
          enabled: false,
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Executed mm',
          },
          stackLabels: {
            enabled: false,
            style: {
              fontWeight: 'bold',
              color: 'gray',
            },
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: series,
      });
    }

    function prepareIrrigation(series) {
      new Highcharts.Chart({
        chart: {
          renderTo: 'irrigationSupervise',
          type: 'column',
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
        },
        exporting: {
          enabled: false,
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          min: 0,
          title: {
            text: 'mm',
          },
          stackLabels: {
            enabled: false,
            style: {
              fontWeight: 'bold',
              color: 'gray',
            },
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: series,
      });
    }

    function editChannel() {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
    }

    function recalcSeasonalPlan(reload = false) {
      irrideskFactory.recalcSeasonalPlan(channel.id).then((result) => {
        if (reload) {
          checkChannelStatus();
        }
      });
    }

    function loadIrrigData(data) {
      if (vm.irrigationDataChart) {
        loadIrrigationChart(data);
      } else {
        irrideskFactory.getCampaignPlans(channel.irrideskId).then((result) => {
          vm.irrigationDataChart = result;
          loadIrrigationChart(data);
        });
      }
    }

    function loadIrrigationChart(data) {
      const irrigationMinDTO = [];
      const irrigationMaxDTO = [];

      vm.irrigationDataChart?.editable_plan?.editable_curves?.range_irrigation?.forEach((irrData) => {
        irrigationMinDTO.push([parseInt(moment(irrData.day, 'YYYY-MM-DD').format('x')), irrData.min]);
        irrigationMaxDTO.push([parseInt(moment(irrData.day, 'YYYY-MM-DD').format('x')), irrData.max]);
      });

      const irrigationSeries = [
        {
          type: 'area',
          name: $filter('translate')('irridesk.max_acceptable'),
          data: irrigationMaxDTO,
        },
        {
          type: 'area',
          name: $filter('translate')('irridesk.min_acceptable'),
          data: irrigationMinDTO,
        },
        {
          type: 'line',
          name: $filter('translate')('irridesk.accumulated_irrigation'),
          color: colors['vegga-color-red-teaberry-default'],
          data: data,
        },
      ];

      createGraphic(irrigationSeries, 'irrigation', irrigationMaxDTO, 'mm', '', [data[0][0], data[data.length - 1][0]]);
    }
  }
})();
