import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getContainer } from '@vegga/front-store';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('expertIrrideskChannelViewController', expertIrrideskChannelViewController);

  expertIrrideskChannelViewController.$inject = [
    '$state',
    'irrideskFactory',
    '$scope',
    '$rootScope',
    '$confirm',
    '$filter',
    'UserData',
  ];

  function expertIrrideskChannelViewController(
    $state,
    irrideskFactory,
    $scope,
    $rootScope,
    $confirm,
    $filter,
    UserData
  ) {
    var vm = this;
    var subscription;
    vm.formFactory;
    vm.channelsActive = [];
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.devicesFacade.devicesICMResponse.clear();

      vm.devicesFacade.loadICMDevices(UserData.id, true);
      vm.devicesFacade.devicesICMResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((units) => {
        vm.units = units.map((unit) => ({ ...unit, device: unit }));
      });
      vm.openChannel = openChannel;
      vm.viewChannel = viewChannel;
      vm.getDeviceName = getDeviceName;
      vm.newChannel = addNewChannel;
      vm.closeOverlay = closeOverlay;
      //vm.getProgramName = getProgramName;
      vm.showDash = true;
      vm.save = save;
      vm.cancel = cancel;

      /* if($state.params.unit !== null){
            vm.currentUnit = $state.params.unit; 
             if($state.params.config){
                 $state.go('fertilizers.config',{unit:vm.currentUnit})
             }
         }else{
             $state.go('units');
         } */

      irrideskFactory.getSubscription().then((result) => {
        if (result) {
          subscription = result.plain();
          vm.channels = subscription.channels;
          vm.channelsActive = vm.channels.filter((channel) => channel.status === 1);
          vm.subscription = subscription;
          prepareChannelData();
        }
      });
    }

    vm.test = function () {
      $scope.msnry.reloadItems();
      $scope.msnry.layout();
    };

    function addNewChannel() {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      $state.go('irrideskChannels.channel', {
        channels: vm.channels,
        idSubscription: subscription.id,
        sensors: subscription.sensors,
        unitsList: vm.units,
      });
    }

    function closeOverlay() {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.dismiss();
      $state.go('irrideskChannels', { units: vm.units });
    }

    function prepareChannelData() {
      if (subscription && subscription.irrideskInfo) {
        let farm = subscription.irrideskInfo.farms[0];
        if (farm) {
          let channels_irridesk = farm.channels;
          let channels_plan = farm.channel_seasonalplan;
          _.forEach(vm.channels, (channel) => {
            let ch_ir = _.find(channels_irridesk, ['id', channel.irrideskId]);
            let ch_plan = channels_plan[channel.irrideskId];
            if (ch_ir) {
              channel = _.merge(channel, ch_ir);
            }
            if (ch_plan) {
              channel.plan_status = ch_plan[0];
            }
            if (channel.seasonalPlan_id && channel.seasonalPlan_id.accepted) {
              channel.plan_status = 'Acepado'; //Sobreescribim estatus ja que està acceptat
            }
            if (channel.irrideskId != null || channel.irrideskId != undefined) {
              irrideskFactory.getChannelWrappers(channel.irrideskId).then((res) => {
                if (res.plain() != null || res.plain() != undefined) {
                  if (res.plain().program_wrappers != null && res.plain().program_wrappers.length > 0) {
                    channel.program = res.plain().program_wrappers[0].name;
                  }
                }
              });
            }
          });
        }
      }
    }

    function openChannel(ch) {
      vm.showDash = false;
      $state.go('irrideskchannel', {
        units: vm.units,
        channel: ch,
        idSubscription: subscription.id,
        sensors: subscription.sensors,
      });
    }

    function viewChannel(ch) {
      vm.showDash = false;
      $state.go('irridesk.view', {
        units: vm.units,
        channel: ch,
        idSubscription: subscription.id,
        sensors: subscription.sensors,
      });
    }

    function getDeviceName(id) {
      let result = _.filter(vm.units, (o) => {
        return o.device.id === id;
      });
      if (result.length > 0) {
        return result[0].device.name;
      } else {
        return '-';
      }
    }

    function save() {
      if (vm.form && vm.form.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('formSubmit');
        });
      }
    }

    function cancel($event) {
      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('irridesk.irr1'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          if (typeof $event !== 'undefined') {
            document.querySelector('vegga-overlay').dismiss();
            $state.go('irrideskChannels', {
              units: vm.units,
            });
          } else {
            document.querySelector('vegga-overlay').dismiss();
            $state.go('irrideskChannels', {
              units: vm.units,
            });
          }
        });
      } else {
        document.querySelector('vegga-overlay').dismiss();
        $state.go('irrideskChannels', {
          units: vm.units,
        });
      }
    }

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.form = args;
    });
    /**
     * Event listener per gestionar el save del form
     */
    $scope.$on('formSaved', () => {
      document.querySelector('vegga-overlay').dismiss();
      $state.go(
        'irrideskChannels',
        {
          units: vm.units,
        },
        {
          reload: 'irrideskChannels',
        }
      );
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
