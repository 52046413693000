import { VeggaOverlayTab } from '../../utils/vegga-overlay-tab.enum';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { UNITS } from '../../utils/units.enum';
import { getContainer } from '@vegga/front-store';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('rainController', rainController);

  rainController.$inject = [
    '$scope',
    '$state',
    'UserData',
    'calculatedsFactory',
    'sensorsFactory',
    '$timeout',
    '$confirm',
    '$filter',
    'unitFactory',
  ];

  function rainController(
    $scope,
    $state,
    UserData,
    calculatedsFactory,
    sensorsFactory,
    $timeout,
    $confirm,
    $filter,
    unitFactory
  ) {
    var vm = this;
    var backups;
    vm.effectiveRain = [];
    vm.VeggaOverlayTab = VeggaOverlayTab;
    vm.unitName = unitName;
    vm.newSensor = newSensor;
    vm.deleteSensor = deleteSensor;
    vm.updateSensor = updateSensor;
    vm.sensorEdition = sensorEdition;
    vm.cancelEdition = cancelEdition;
    vm.loadPluviometers = loadPluviometers;
    vm.changeRain = changeRain;
    vm.changingRain = changingRain;
    vm.nextOrPreviousItem = nextOrPreviousItem;
    vm.cancel = cancel;
    backups = {};

    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.devicesFacade.loadICMDevices(UserData.id, true);
    activate();

    function activate() {
      loadSensors();
      vm.devicesFacade.devicesICM.pipe(takeUntil(vm.destroy$)).subscribe((units) => {
        vm.units = units;
      });

      $scope.$emit('stateChanged');
    }

    function unitName(sensor) {
      if (sensor.deviceId === null) {
        return null;
      } else {
        const unit = vm.units.find((unit) => {
          return unit.id === sensor.deviceId;
        });
        return unit.name;
      }
    }

    function loadSensors() {
      calculatedsFactory.effectiveRain(UserData.id).then((result) => {
        vm.effectiveRain = result.plain();
      });
    }

    function newSensor() {
      vm.currentRainSelected = null;
      vm.currentTab = VeggaOverlayTab.NEW;
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();

      /*calculatedsFactory.newEffectiveRain(UserData.id).then( result => {
            var ef = result.plain();
            ef.hide = true;
            vm.effectiveRain.push(ef);
        });*/
    }

    function deleteSensor() {
      calculatedsFactory.deleteEffectiveRain(UserData.id, vm.currentRainSelected.pk.id).then((result) => {
        const veggaOverlay = document.querySelector('vegga-overlay');
        veggaOverlay.dismiss();
        vm.currentTab = null;
        vm.effectiveRain = vm.effectiveRain.filter(
          (effectiveRain) => effectiveRain.pk.id !== vm.currentRainSelected.pk.id
        );
        vm.currentRainSelected = null;
      });
    }

    function sensorEdition(sensor) {
      if (sensor !== undefined && sensor.sensors === undefined) {
        loadPluviometers(sensor, true);
      }

      var sensor_backup = {};
      angular.copy(sensor, sensor_backup);
      backups[sensor.pk.id] = sensor_backup;

      vm.currentRainSelected = sensor;
      vm.currentTab = VeggaOverlayTab.EDIT;
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
    }

    function cancelEdition() {
      if (vm.currentRainSelected && vm.currentRainSelected.pk) {
        angular.copy(backups[vm.currentRainSelected.pk.id], vm.currentRainSelected);
      }
      vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
    }

    function updateSensor() {
      vm.form.$setPristine();
      if (vm.currentTab === VeggaOverlayTab.NEW) {
        calculatedsFactory.newEffectiveRain(UserData.id).then((result) => {
          const ef = result.plain();
          vm.currentRainSelected.pk = ef.pk;
          calculatedsFactory.updateEffectiveRain(UserData.id, vm.currentRainSelected).then((result) => {
            vm.currentRainSelected = result.plain();
            if (vm.currentTab === VeggaOverlayTab.NEW) {
              vm.effectiveRain.push(vm.currentRainSelected);
              const veggaOverlay = document.querySelector('vegga-overlay');
              veggaOverlay.dismiss();
              vm.currentTab = null;
              vm.currentRainSelected = null;
            }
          });
        });
      } else {
        calculatedsFactory.updateEffectiveRain(UserData.id, vm.currentRainSelected).then((result) => {
          vm.currentRainSelected = result.plain();
          if (vm.currentTab === VeggaOverlayTab.NEW) {
            vm.effectiveRain.push(vm.currentRainSelected);
            const veggaOverlay = document.querySelector('vegga-overlay');
            veggaOverlay.dismiss();
            vm.currentTab = null;
            vm.currentRainSelected = null;
          }
        });
      }
    }

    function loadPluviometers(sensor, isFirstLoad) {
      if (!isFirstLoad) {
        vm.currentRainSelected.sensorId = 0;
      }
      vm.areSensorsLoaded = false;
      if (sensor.deviceId !== null) {
        var unit = vm.units.find((unit) => {
          return unit.id === sensor.deviceId;
        });

        if (!unit) return;
        sensor.deviceType = unit.type;
        switch (unit.type) {
          case UNITS.A_4000:
            sensor.sensors = [{ pk: { id: 35 } }];
            vm.areSensorsLoaded = true;
            break;
          case UNITS.A_2500:
          case UNITS.A_BIT:
          case UNITS.A_4500:
            sensorsFactory.meters(sensor.deviceId).then((result) => {
              sensor.sensors = result.plain();
              vm.areSensorsLoaded = true;
            });
            break;
        }
      }
    }

    function changeRain(e) {
      if (e.detail.value) {
        vm.currentRainSelected = null;
        vm.currentRainSelected = e.detail.value;
        vm.currentTab = VeggaOverlayTab.EDIT;
        sensorEdition(vm.currentRainSelected);
      }
    }

    function changingRain($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();
          nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeRain($event);
          break;
      }
    }

    function cancel($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();
          if (vm.currentTab === VeggaOverlayTab.NEW || $event) {
            const veggaOverlay = document.querySelector('vegga-overlay');
            veggaOverlay.dismiss();
            vm.currentTab = null;
            vm.currentRainSelected = null;
          }
        });
      } else {
        cancelEdition();
        if ($event) {
          const veggaOverlay = document.querySelector('vegga-overlay');
          veggaOverlay.dismiss();
          vm.currentTab = null;
          vm.currentRainSelected = null;
        }

        if (vm.currentTab === VeggaOverlayTab.NEW) {
          vm.currentTab = null;
          vm.currentRainSelected = null;
        }
      }
    }

    $scope.$on('refresh', function (event, args) {});

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
