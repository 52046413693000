import { UNITS } from '../utils/units.enum';
import { showLoader, hideLoader } from '@vegga/front-utils';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('sensorsConfigDSController', sensorsConfigDSController);

  sensorsConfigDSController.$inject = ['$scope', '$state', 'sensorsFactory', '$confirm', '$filter'];

  function sensorsConfigDSController(
    $scope,
    $state,

    sensorsFactory,
    $confirm,
    $filter
  ) {
    var vm = this;
    vm.activeList;
    vm.lastSensor;
    vm.nextSensor;
    vm.selectSensor;
    vm.cancel;
    vm.save;
    activate();

    function activate() {
      vm.overlayEl = document.querySelector('#sensor-config-overlay');
      vm.overlayEl.show();
      vm.currentState = $state.includes;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.forms = [];
        vm.page = 1;
        vm.UNITS = UNITS;
        loadDigitals(vm.page);
        vm.selectSensor = loadDigitals;
        vm.nextSensor = nextSensor;
        vm.lastSensor = lastSensor;
        vm.cancel = cancel_edition;
        vm.save = save;
        vm.setColor = setColor;
        vm.captionRange = _.range(0, 5);
        vm.changeState = changeState;
        vm.changeSensor = changeSensor;
        vm.changingSensor = changingSensor;
        vm.nextOrPreviousItem = nextOrPreviousItem;
      } else {
        $state.go('units');
      }
    }

    function loadDigitals(n) {
      showLoader();
      vm.digitals = [];
      var params = { page: 1, limit: 1000 };
      vm.loading = true;

      if (vm.currentUnit.type !== UNITS.A_4500) {
        return sensorsFactory.digitalsPage(vm.currentUnit.id, params).then(function (data) {
          vm.paginator = data;
          vm.paginatorOptions = data.content;
          vm.sensor = vm.paginatorOptions.find((ds) => +ds.pk.id === n) || vm.paginatorOptions[0];

          vm.total_number = _.range(1, vm.paginatorOptions.length + 1);
          // vm.page = vm.paginator.number + 1;
          if (vm.currentUnit.type === vm.UNITS.A_4000 || vm.currentUnit.type === vm.UNITS.A_7000) {
            vm.function = checkFunctionType(vm.sensor.pk.id);
            vm.loadCaption = Number(vm.sensor.pk.id) >= 11 && Number(vm.sensor.pk.id) <= 35;
          } else {
            vm.loadCaption = false;
          }

          vm.loading = false;
          backup();
          hideLoader();
        });
      } else {
        return sensorsFactory
          .digitalsAll(vm.currentUnit.id)
          .then((data) => {
            vm.digitals = data;
            changeState(vm.page);
            /* vm.digitals.number = 0;
            angular.copy(vm.digitals[0],vm.sensor);
            vm.total_number = _.range(1,vm.digitals.length + 1);
            vm.page = vm.digitals.number + 1; */
            backup();
            hideLoader();
          })
          .catch((e) => {
            throw e;
          });
      }
    }

    function changeState(params) {
      vm.active = null;
      vm.sensor = vm.digitals[params - 1];

      //$state.go('sensors.config.ds', vm.sensor);
    }

    function setColor() {
      vm.pickerActive = false;
      switch (vm.sensor.caption.numLvl) {
        case 3:
          vm.sensor.caption.color1 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 60);
          vm.sensor.caption.color2 = vm.sensor.color.hex;
          vm.sensor.caption.color3 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -60);
          break;
        case 5:
          vm.sensor.caption.color1 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 60);
          vm.sensor.caption.color2 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 30);
          vm.sensor.caption.color3 = vm.sensor.color.hex;
          vm.sensor.caption.color4 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -30);
          vm.sensor.caption.color5 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -60);
          break;
      }
    }

    function checkFunctionType(key) {
      vm.typeStop = null;
      var id = Number(key);
      if (vm.currentUnit.type !== UNITS.A_7000) {
        switch (true) {
          case id >= 1 && id <= 5:
          case id === 10:
          case id >= 44 && id <= 48: {
            return 0;
          }
          case id >= 6 && id <= 9: {
            vm.typeStop = [
              { id: 1, text: 'temporal' },
              { id: 2, text: 'conditional' },
              { id: 3, text: 'definitive' },
            ];
            return 1;
          }
          case id >= 11 && id <= 14: {
            return 2;
          }
          case id >= 15 && id <= 22: {
            return 3;
          }
          case id >= 23 && id <= 26: {
            return 4;
          }
          case id >= 27 && id <= 34: {
            return 5;
          }
          case id === 35: {
            return 6;
          }
          case id >= 36 && id <= 43: {
            return 7;
          }
        }
      } else {
        switch (true) {
          case id >= 1 && id <= 6: {
            return 0;
          }
          case id >= 7 && id <= 14: {
            vm.typeStop = [
              { id: 1, text: 'temporal' },
              { id: 2, text: 'conditional' },
              { id: 3, text: 'definitive' },
            ];
            return 1;
          }
          case id >= 15 && id <= 17:
          case id >= 20 && id <= 22:
          case id == 38: {
            return 2;
          }
          case id == 18: {
            return 3;
          }
          case id == 19: {
            return 4;
          }
          case id >= 23 && id <= 28: {
            vm.typeStop = [
              { id: 1, text: 'temporal' },
              { id: 2, text: 'conditional' },
              { id: 3, text: 'definitive' },
            ];
            return 5;
          }
          case id >= 29 && id <= 37: {
            return 6;
          }
          case id >= 39 && id <= 47: {
            return 7;
          }
        }
      }
    }

    function lastSensor() {
      --vm.page;
      if (vm.page === 0) {
        vm.page = vm.paginator.totalPages;
      }
      loadDigitals(vm.page);
    }

    function nextSensor() {
      ++vm.page;
      if (vm.page > vm.paginator.totalPages) {
        vm.page = 1;
      }
      loadDigitals(vm.page);
    }

    function changeSensor(e) {
      if (e.detail.value) {
        vm.page = e.detail.value;
        loadDigitals(vm.page);
      }
    }

    function changingSensor($event) {
      if (vm.form_d && vm.form_d.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form_d.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeSensor($event);
          break;
      }
    }

    function save() {
      vm.loading = true;
      vm.sensor.unittype = vm.currentUnit.type;
      vm.form_d.$setPristine();
      sensorsFactory.updateDigitals(vm.currentUnit.id, vm.sensor).then((response) => {
        vm.sensor = response.plain();
        if (vm.currentUnit.progtype === vm.UNITS.A_4000.toString()) {
          vm.function = checkFunctionType(vm.sensor.pk.id);
        }
        $scope.$emit('refresh-sensors');
        vm.loading = false;
      });
    }

    function backup() {
      vm.sensors_backup = {};
      angular.copy(vm.sensor, vm.sensors_backup);
      vm.sensor.backup = true;
    }

    function cancel_edition() {
      //backup
      angular.copy(vm.sensors_backup, vm.sensor);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form_d,
      () => {
        $scope.$emit('formUpdated', vm.form_d);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e) => {
      save(e, vm.form_d);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
