import { getContainer } from '@vegga/front-store';
import { Subject, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('sectorsGroupController', sectorsGroupController);

  sectorsGroupController.$inject = ['$rootScope', '$scope', '$state', 'sectorFactory', '$confirm', 'unitFactory'];

  function sectorsGroupController($rootScope, $scope, $state, sectorFactory, $confirm, unitFactory) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.currentHeader;
    vm.sectorgroupSelected;
    vm.alert = false;
    vm.sectorGroupHasErrors = false;
    vm.save = save;
    vm.cancel = cancel_edition;
    vm.changeSectorGroup = changeSectorGroup;
    vm.checkSectors = checkSectors;
    vm.changeHeaderSelected = changeHeaderSelected;

    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;
        loadSectorsGroup();
      });
    }

    function loadSectorsGroup() {
      sectorFactory.getGroupSectors(vm.currentUnit.id).then((response) => {
        vm.sectorsGroup = response.plain();
        vm.sectorgroupSelected = vm.sectorsGroup[0];
        backup = angular.copy(vm.sectorgroupSelected);
        sectorFactory.allSectors(vm.currentUnit.id).then((response) => {
          vm.allSectors = response.plain();
          checkSectors();
          checkErrors();
        });
      });
    }

    function changeSectorGroup(e) {
      if (e.detail.value) {
        vm.sectorgroupSelected = e.detail.value;

        angular.copy(vm.sectorgroupSelected, backup);
        checkSectors();
        checkErrors();
      }
    }

    function loadAllSectors() {
      sectorFactory.allSectors(vm.currentUnit.id).then((response) => {
        vm.allSectors = response.plain();
      });
    }

    function save() {
      sectorFactory.updateGroupSectors(vm.currentUnit.id, vm.sectorgroupSelected).then((result) => {
        vm.config = result;
        vm.form.$setPristine();
        backup = angular.copy(vm.sectorsGroup);
      });
    }

    function cancel_edition() {
      vm.form.$setPristine();
      vm.config = angular.copy(backup);
    }

    function checkSectors() {
      _.forEach(vm.allSectors, (s) => {
        let exist = _.find(vm.sectorgroupSelected.elements, (gs) => {
          return gs.sector == parseInt(s.pk.id);
        });
        if (exist) {
          s.enabled = true;
        } else s.enabled = false;
      });
    }

    function checkErrors() {
      const sectorsHeaders = Array.from(
        new Set(vm.sectorsGroup[vm.sectorgroupSelected.id - 1].elements.map((element) => element.header))
      ).filter((header) => header !== null);

      vm.sectorGroupHasErrors = sectorsHeaders.length > 1;
    }

    function changeHeaderSelected(e, i) {
      vm.form.$setDirty();
      //set new header in sector group selected
      let sect = _.find(vm.allSectors, (sec) => {
        return sec.pk.id == parseInt(e.detail.value);
      });
      if (sect) {
        vm.sectorsGroup[vm.sectorgroupSelected.id - 1].elements[i].header = sect.header;
        vm.sectorsGroup[vm.sectorgroupSelected.id - 1].elements[i].sector = sect.pk.id;
      } else {
        vm.sectorsGroup[vm.sectorgroupSelected.id - 1].elements[i].header = null;
        vm.sectorsGroup[vm.sectorgroupSelected.id - 1].elements[i].sector = 0;
      }
      checkErrors();
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
