(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('LoginController', LoginController);

  LoginController.$inject = ['$state', 'AuthenticationService', '$rootScope', '$http', '$timeout', '$scope', '$window'];

  function LoginController($state, AuthenticationService, $rootScope, $http, $timeout, $scope, $window) {
    var vm = this;
    vm.credentials;
    vm.login;

    activate();

    function activate() {
      vm.hasError = '';
      vm.login = login;
      /*         if($state.params.reload){
           $window.location.reload();
        } */
    }

    function login() {
      var data = 'username=' + vm.credentials.username + '&password=' + vm.credentials.password;

      $http({
        method: 'POST',
        method: 'POST',
        url: 'login',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        timeout: 20000,
        transformRequest: function (obj) {
          var str = [];
          for (var p in obj) str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
          return str.join('&');
        },
        data: { username: vm.credentials.username, password: vm.credentials.password },
      }).then(
        function (data) {
          $rootScope.user = {};
          $rootScope.user.authenticated = true;
          $rootScope.user.userId = data.userid;
          $rootScope.user.role = data.role;
          $rootScope.user.name = data.name;
          $rootScope.user.pro = data.profesional;

          vm.hasError = '';
          ga('set', 'userId', data.nif);

          /*            var domain = $window.location.hostname;
            domain = 'quadern.'+domain;
            $cookies.put('JSESSIONID',data.headers().session,{'domain':domain});
            console.log($cookies.get('JSESSIONID'))*/
          $state.go('home');
        },
        function () {
          vm.hasError = 'animated shake';
          $timeout(function () {
            vm.hasError = '';
            $scope.$apply(vm.hasError);
          }, 1000);
        }
      );
    }
  }
})();
