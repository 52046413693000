import '../components/maps/maps-controller';
import mapsTemplate from '../components/maps/maps.html';
import '../components/maps/maps-factory';
import '../components/maps/new-maps-menu.controller';
import '../components/maps/sector.map-controller';
import '../components/calc_sensors/calcs-factory';
import '../components/maps/equipo.map-controller';
import '../components/maps/sensor.map-controller';
import '../components/sensors/sensors-factory';
import '../components/maps/module.maps-controller';
import '../components/communications/communications-factory';
import '../components/maps/label.map-controller';
import '../components/directives/shareModal/shareModal-directive';

import '../components/maps/views/sector.layer-controller';
import sectorLayerTemplate from '../components/maps/views/sector-layer.html';

import '../components/maps/views/equipo.layer-controller';
import equipoLayerTemplate from '../components/maps/views/equipo-layer.html';

import '../components/maps/views/sensor.layer-controller';
import sensorLayerTemplate from '../components/maps/views/sensor-layer.html';

import '../components/maps/views/module.layer-controller';
import moduleLayerTemplate from '../components/maps/views/module-layer.html';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('maps', {
            parent: 'main',
            url: `${process.env.BASE_HREF}/maps`,
            params: {
              units: null,
            },
            template: mapsTemplate,
            controller: 'mapsController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - check state name maps.sector
          //   .state('maps.sector', {
          .state('maps.sector', {
            url: '/sector',
            params: {
              unit: null,
              sectorId: null,
              map: null,
              layerId: null,
              elementsInMap: [],
              cropId: null,
            },
            template: sectorLayerTemplate,
            controller: 'sectorLayerController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('maps.unit', {
            url: '/unit',
            params: {
              units: null,
              unit: null,
              map: null,
            },
            template: equipoLayerTemplate,
            controller: 'equipoLayerController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('maps.sensor', {
            url: '/sensor',
            params: {
              units: [],
              unit: null,
              sensor: null,
              map: null,
              type: null,
              fetchedSensors: [],
            },
            template: sensorLayerTemplate,
            controller: 'sensorLayerController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('maps.module', {
            url: '/module',
            params: {
              unit: null,
              moduleId: null,
              moduleType: null,
              linkboxId: null,
              type: null,
            },
            template: moduleLayerTemplate,
            controller: 'moduleLayerController',
            authenticate: true,
            controllerAs: 'vm',
          });
      },
    ]);
})();
