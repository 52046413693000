import { UNITS } from '../utils/units.enum';
import { getContainer } from '@vegga/front-store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MapModule } from '../utils/enums/maps/maps-module.enum';
import { MapModuleType } from '../utils/enums/maps/maps-module-type.enum';
import { MapModuleStatus } from '../utils/enums/maps/maps-module-status.enum';
import { UNITS_V2 } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('moduleMapController', moduleMapController);

  moduleMapController.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'mapsFactory',
    'commFactory',
    '$timeout',
    '$anchorScroll',
  ];

  function moduleMapController($rootScope, $scope, $state, mapsFactory, commFactory, $timeout, $anchorScroll) {
    var vm = $scope.$parent.vm;
    let currentMarker;
    vm.module;
    vm.destroy$ = new Subject();

    const types = {
      1: { type: 'Monocable', name: 'AM120', cod: 'EAM', mod: 'MAM' },
      2: { type: 'Radio', name: 'AR868-16', cod: 'EAR', mod: 'MAR' },
      3: { type: 'Radio', name: 'AR24', cod: 'EAR', mod: 'MAR' },
      4: { type: 'Radio', name: 'AR433', cod: 'EAR', mod: 'MAR' },
      5: { type: 'Radio', name: 'MI', cod: 'MI' },
      6: { type: 'Radio', name: 'ME', cod: 'ME' },
      7: { type: 'Radio', name: 'R868', cod: 'EAR', mod: 'MAR' },
      8: { type: 'Radio', name: 'AgroBee', cod: 'Coordinador', mod: 'Módulo' },
      9: { type: 'Radio', name: 'AgroBeeL', cod: 'Coordinador', mod: 'Módulo' },
      10: { type: 'Radio', name: 'SDI12', cod: '', mod: 'Dispositivo' },
    };

    vm.agroBeeTypes = {};

    activate();

    /*******************/

    function activate() {
      vm.mapsFacade = getContainer().resolve('mapsLegacyFacade');
      vm.linkBoxFacade = getContainer().resolve('linkBoxFacade');
      vm.actionView = 0; //Default view INFO, 1 = Manual, 2 = Edition area
      vm.selectEquipo = false;
      vm.equipoInfo = false;
      vm.UNITS = UNITS;
      vm.addMarkerM = addMarker;
      vm.loadLinkBoxes = loadLinkBoxes;
      vm.clearSelection = clearSelection;
      vm.loadModules = loadModules;
      vm.areModulesLoading = false;
      initSubscriptions();
    }

    function initSubscriptions() {
      vm.mapsFacade.mapModules$.pipe(takeUntil(vm.destroy$)).subscribe((modules) => {
        loadMarkersToLayer(modules);
      });

      vm.linkBoxFacade.linkBox$.pipe(takeUntil(vm.destroy$)).subscribe((linkBoxes) => {
        vm.linkBoxes = linkBoxes;
        vm.areModulesLoading = false;
      });
    }

    function loadMarkersToLayer(markers) {
      if (!markers) return;
      vm.modulesInMap = markers;

      vm.modulesLayer.clearLayers();

      markers.forEach((moduleMarker) => {
        const marker = L.marker(new L.LatLng(moduleMarker.latitude, moduleMarker.longitude));
        const moduleType = MapModule[moduleMarker.moduleType];
        const className = getNewModuleStateClass(moduleMarker);

        marker.setIcon(createEquipoIcon(className));
        marker.properties = {};
        marker.properties.markerId = moduleMarker.id;
        marker.properties.id = moduleMarker.id;
        marker.properties.deviceId = moduleMarker.deviceId;
        marker.properties.type = MapModuleType[moduleMarker.type];
        marker.properties.moduleType = moduleType;
        marker.properties.moduleId = moduleMarker.moduleId;
        marker.properties.status = moduleMarker.status;
        marker.properties.linkboxId = moduleMarker.linkboxId;

        const module = types[moduleType] || {};
        marker.properties.moduleName = `${module.cod} ${moduleMarker.linkboxId ? moduleMarker.linkboxId : ''} ${
          module.type
        } ${module.name}`;
        vm.modulesLayer.addLayer(marker);
      });

      vm.modulesLayer.on({ click: showModuleInfo });
      vm.map.addLayer(vm.modulesLayer);

      //clear connectionLines layer
      vm.conectionLines.clearLayers();

      vm.modulesLayer.getLayers().forEach((e) => {
        if (e.properties.linkboxId) {
          conectToModules(e);
        }
      });
    }

    function getNewModuleStateClass(module) {
      let type = '';

      switch (MapModule[module.moduleType]) {
        case 1: //Monocable
          type = module.type === MapModuleType.COORDINATOR || module.linkboxId === null ? 'eam' : 'mam';
          break;
        case 2: //Radio
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          type = module.type === MapModuleType.COORDINATOR || module.linkboxId === null ? 'ear' : 'mar';
          break;
        case 8: //AgroBee
          type =
            module.type === MapModuleType.COORDINATOR || module.linkboxId === null
              ? 'agrocoor'
              : module.linkboxType === 1
              ? 'agrorep'
              : 'agro';
          break;
        case 9: //AgroBeeL
          type =
            module.type === MapModuleType.COORDINATOR || module.linkboxId === null
              ? 'agrocoor'
              : module.linkboxType === 1
              ? 'agrorep'
              : 'agro';
          break;
      }

      type = type + ' ';

      if (module.status === MapModuleStatus.ERROR) {
        type = type + 'averia';
      } else if (module.status === MapModuleStatus.CONNECTED) {
        type = type + 'con';
      } else {
        type = type + 'nocon';
      }

      return type;
    }

    function showModuleInfo(e) {
      currentMarker = e.layer;
      $timeout(() => {
        vm.map.invalidateSize();
        const overlay = document.getElementById('mapToolsOverlay');
        const overlayWidth = overlay.shadowRoot.querySelector('.vegga-overlay__container').offsetWidth / 2;

        const offset = [-Math.abs(overlayWidth), 0]; // Desplazamiento en píxeles
        const targetPoint = vm.map.project(e.latlng, vm.map.getZoom()).subtract(offset);
        const targetLatLng = vm.map.unproject(targetPoint, vm.map.getZoom());
        vm.map.setView(targetLatLng, vm.map.getZoom());
      }, 200);

      $scope.$emit('selectedMapModule', {
        moduleName: e.layer.properties.moduleName,
        markerId: e.layer.properties.markerId,
        moduleType: e.layer.properties.type,
      });
      vm.overlay.show();
      $state.go(
        'maps.module',
        {
          unit: vm.units.find((unit) => unit.id === e.layer.properties.deviceId),
          moduleId: e.layer.properties.moduleId,
          moduleType: e.layer.properties.moduleType,
          linkboxId: e.layer.properties.linkboxId,
          type: e.layer.properties.type,
        },
        { reload: 'maps.module' }
      );
    }

    function conectToModules(marker) {
      const parentId = marker.properties.linkboxId;
      const parentType = marker.properties.moduleType;
      const parentDeviceId = marker.properties.deviceId;
      const parentCoords = [marker._latlng.lat, marker._latlng.lng];
      let draw = false;

      let color;
      if (module.status === MapModuleStatus.ERROR) {
        color = 'var(--vegga-color-error)';
      } else if (module.status === MapModuleStatus.CONNECTED) {
        color = '#00A99D';
      } else {
        color = '#ffffff';
      }

      vm.modulesLayer.getLayers().forEach((m) => {
        if (
          m.properties.deviceId === parentDeviceId &&
          m.properties.moduleType === parentType &&
          m.properties.moduleId === parentId
        ) {
          draw = true;
          const line = L.polyline([parentCoords, [m._latlng.lat, m._latlng.lng]], {
            color: color,
            dashArray: '5 8',
            smoothFactor: 0.5,
          });
          vm.conectionLines.addLayer(line);
        }
      });

      if (draw) {
        vm.conectionLines.addTo(vm.map);
      }
    }

    function saveMarker(latLng) {
      let params = {};
      params.lat = latLng.lat;
      params.lng = latLng.lng;
      params.mapId = vm.map.id;
      params.deviceId = vm.unit.id;
      params.userId = $rootScope.user.id;
      params.type = 1;
      params.prop1 = vm.moduleType;
      params.prop2 = vm.moduleType === 0 ? vm.LINKBOX[vm.linkboxToAdd.type] : vm.moduleAdding.pk.type;
      params.prop3 = vm.moduleType === 0 ? vm.linkboxToAdd.id : vm.moduleAdding.pk.id;
      params.prop4 = vm.moduleType === 1 ? vm.moduleAdding.pk.idkCajaEnlace : null;

      return mapsFactory.saveMarker(vm.map.id, params);
    }

    function addMarker() {
      const { layer, latlng } = vm.props;

      saveMarker(latlng).then(() => {
        $scope.$emit('completedSave');
        vm.mapsFacade.loadMapModules(vm.map.id);
        vm.map.removeLayer(layer);
        vm.map.off('editable:drawing:commit');
      });
    }

    function initAddMarker() {
      vm.filter.comp.module = true;
      vm.filter.comp.active = true;
    }

    var createEquipoIcon = function (labelClass) {
      const content = '<div class="map-module-marker ' + labelClass + '"></div>';
      return L.divIcon({
        className: 'custom-marker',
        html: content,
      });
    };

    function closeModuleInfo() {
      $anchorScroll();
      $timeout(() => {
        vm.map.invalidateSize();
      }, 200);
    }

    function deleteModuleMarker() {
      mapsFactory.deleteMarker(vm.map.id, currentMarker.properties.id).then(() => {
        vm.modulesLayer.removeLayer(currentMarker);
        vm.map.removeLayer(vm.conectionLines);
      });
    }

    function loadLinkBoxes() {
      vm.moduleType = null;
      vm.linkBoxes = null;
      vm.areModulesLoading = true;
      vm.modules = null;
      clearSelection();
      vm.linkBoxFacade.loadLinkBox(UNITS_V2[vm.unit.type], vm.unit.id);
    }

    function clearSelection() {
      vm.moduleAdding = null;
      vm.modules = null;
      vm.linkbox = null;
      vm.linkboxToAdd = null;
    }

    function loadModules() {
      vm.moduleAdding = null;
      vm.areModulesLoading = true;
      if (vm.moduleType === 1 || vm.moduleType === 0) {
        commFactory.linkBoxEM(vm.unit.id, vm.LINKBOX[vm.linkboxToAdd.type]).then((response) => {
          if (vm.unit.type === UNITS.A_4500) {
            if (response.plain()) {
              const module = response.plain().find((mod) => mod.pk.id === vm.linkboxToAdd.id);

              vm.modules =
                module.externalModulesLora ||
                module.externalModulesMAM120 ||
                module.externalModulesMAR433 ||
                module.externalModules;

              vm.areModulesLoading = false;
            }
          } else {
            if (response[0]) vm.modules = response[0].externalModules;
            vm.areModulesLoading = false;
          }
        });
      }
    }

    $scope.$on('loadModulesLayer', () => {
      if (vm.filter.comp.active && vm.filter.comp.modules) {
        if (vm?.map?.id) {
          vm.mapsFacade.loadMapModules(vm.map.id);
        }
      }
    });

    $scope.$on('deleteModule', () => {
      deleteModuleMarker();
      closeModuleInfo();
    });

    $scope.$on('closeModule', () => {
      closeModuleInfo();
    });

    $scope.$on('reloadMap', () => {});

    $scope.$on('addModuleMarker', (e, args) => {
      vm.props = args;
      initAddMarker(args);
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
      vm.mapsFacade.clearMapModules();
    });
  }
})();
