import { BehaviorSubject, Subject, interval, Subscription } from 'rxjs';
import { filter, takeUntil, tap, pairwise, map, skip } from 'rxjs/operators';

(function () {
  'use strict';

  angular.module('agronicwebApp').factory('refreshFactory', refreshFactory);

  refreshFactory.$inject = ['$rootScope'];

  function refreshFactory($rootScope) {
    /**
     * Behavior Subject that holds and emits skeleton display status
     */
    const skeletonSubject = new BehaviorSubject(true);
    /**
     * Behavior Subject that holds and emits current pending requests
     */
    const pendingRequestsSubject = new BehaviorSubject([]);
    /**
     * Subject that restarts http requests interval when emits
     */
    const cancelIntervalSubject = new Subject();
    /**
     * Subject that emits when new refresh is required
     */
    const reloadSubject = new Subject();
    /**
     * Flag that indicates when refreshing is being performed
     */
    let isRefreshing = false;
    let interval$;
    let intervalSubscription = new Subscription();

    return {
      isRefreshing: () => isRefreshing,
      getSkeletonStatus$: () =>
        skeletonSubject.asObservable().pipe(
          pairwise(),
          // emit only when new emission is different (true or false)
          filter(([prev, curr]) => prev !== curr),
          map(([_, isVisible]) => {
            // don't show skeleton when navigating throught overlay
            // TODO: remove when overlay-skeleton is implemented
            const overlay = document.querySelector('vegga-overlay');
            return overlay && overlay.toggle === 'show' ? false : isVisible;
          })
        ),
      setSkeletonStatus: (isVisible) => skeletonSubject.next(isVisible),
      getSkeletonStatus: () => skeletonSubject.getValue(),
      /**
       * Finishes current refresh sequence if there are no pending
       * requests after interval
       * @param force forces ending of refresh sequence
       */
      completeRefresh: (force) => {
        if (force) {
          intervalSubscription.unsubscribe();
          isRefreshing = false;
          skeletonSubject.next(!!pendingRequestsSubject.getValue().length);
          return;
        }

        if (!interval$) {
          return;
        }

        intervalSubscription.unsubscribe();
        intervalSubscription = interval$.subscribe((hasPending) => {
          if (!hasPending) {
            // emit true to reloadSubject to notify about refreshing completed
            reloadSubject.next(true);
          }
        });
      },
      /**
       * Returns refresh observable, fired on every new refresh
       */
      getRefresh$: () => {
        return reloadSubject.asObservable().pipe(
          // when reloadSubject emits value, is canceling refresh effect (no skeleton or loader shown)
          tap((isRefreshCompleted) => {
            // $rootScope.isRefreshingApp = true;

            if (isRefreshCompleted) {
              return;
            }

            // refresh is still going, keep waiting until
            // it gets completed
            if (pendingRequestsSubject.getValue().length) {
              return;
            }

            isRefreshing = true;
          }),
          // true emission means refresh is completed, discard it
          filter((isRefreshCompleted) => !isRefreshCompleted)
          // tap(() => $rootScope.isRefreshingApp = false)
        );
      },

      /**
       * Restarts refresh observable
       */
      restartRefreshCountdown: () => {
        reloadSubject.next();
      },

      /**
       * Restarts pending requests as observable
       */
      getPendingRequests$: () => pendingRequestsSubject.asObservable().pipe(skip(1)),

      setPendingRequests: (pendingRequests) => {
        pendingRequestsSubject.next(pendingRequests);
      },

      /**
       * Cancels previous interval and starts new one by creating a rxjs
       * interval which resets on every HTTP request made in 2 seconds.
       * If no more requests are performed in this interval, refreshing is over, and skeleton and loader can be shown again.
       */
      setRefreshInterval: () => {
        // cancel previous interval to start a new one
        cancelIntervalSubject.next();
        // interval$ will be emitting every two seconds until interval is cancelled
        interval$ = interval(2000).pipe(takeUntil(cancelIntervalSubject.asObservable()));
      },
    };
  }
})();
